import React, { useState } from "react";
import { Modal } from "antd";
import { FormCreateComponent } from "../ComponentPage/CreateComponentTest";
import Button from "../../components/Button/Button";
// import CreateComponent, {
//   FormCreateComponent,
// } from "../ComponentPage/CreateComponent";

export default function CreateComponentParts() {
  const [isModalOpenParts, setIsModalOpenParts] = useState(false);

  const handleCancel = () => {
    setIsModalOpenParts(false);
  };
  const showModal = () => {
    setIsModalOpenParts(true);
  };

  return (
    <>
      <Button
        children={"+ Create Component"}
        onClick={showModal}
        buttonStyle="btn--primary--outline"
        restClassName="w-40 h-10"
      />
      <Modal
        open={isModalOpenParts}
        closable={false}
        footer={null}
        width="80%"
        className="font-Inter "
      >
        <FormCreateComponent handleCancel={handleCancel} />
      </Modal>
    </>
  );
}
