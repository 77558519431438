import React, { useState } from "react";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import WrapperForm from "../../components/WrapperForm";
import { createComponent } from "../../utils/https/Components";
import { createVendor } from "../../utils/https/Vendors";
import { logout } from "../../redux/auth/authSlice";

export default function CreateVendor() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    name: "",
    vendorCode: "",
    addressLine: "",
    city: "",
    country: "",
    postalCode: "",
    currency: "SGD",
    vendorWebsite: "",
  });

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const paymentOptions = [
    {
      id: 1,
      value: "Payment in Advance",
    },
    {
      id: 2,
      value: "Cash in Advance",
    },
    {
      id: 3,
      value: "Cash on Delivery",
    },
    {
      id: 4,
      value: "Net d Days",
    },
    {
      id: 5,
      value: "Net end of Month",
    },
  ];

  const handleCreate = () => {
    if (input.name === "") return message.error("Vendor's name is required!");
    // if (input.vendorCode === "")
    //   return message.error("Vendor Code is required!");
    if (input.addressLine === "")
      return message.error("Address Line is required!");
    if (input.city === "") return message.error("City is required!");
    if (input.country === "") return message.error("Country is required!");
    if (input.postalCode === "")
      return message.error("Postal Code is required!");
    if (input.currency === "") return message.error("Currency is required!");
    if (input.paymentTerms === "")
      return message.error("Payment Terms is required!");

    setLoading(true);
    createVendor(authState?.token, input)
      .then(({ data }) => {
        setLoading(false);
        message.success(data.message);
        setInput({
          name: "",
          vendorCode: "",
          addressLine: "",
          city: "",
          country: "",
          postalCode: "",
          currency: "",
          paymentTerms: "",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  return (
    <WrapperForm title={"Create New Vendor"}>
      {contextHolder}
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"name"}
            name={"name"}
            value={input.name}
            children={"Vendor's name"}
            placeholder="Enter Vendor's name "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"vendorCode"}
            name={"vendorCode"}
            value={input.vendorCode}
            children={"Vendor code"}
            placeholder="Vendor code"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4 flex flex-col">
          <label htmlFor="paymentTerms" className="font-semibold ">
            Payment Terms
          </label>
          <select
            id="paymentTerms"
            defaultValue="0"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) =>
              setInput({ ...input, paymentTerms: e.target.value })
            }
          >
            <option value="0" defaultValue={true} key="ieiwesdj">
              Select payment terms
            </option>
            {paymentOptions.map((item, idx) => (
              <option value={item.value} key={idx}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
        <div className="w-6/12 pt-8 px-4 flex flex-col">
          <label htmlFor="currency" className="font-semibold ">
            Currency
          </label>
          <select
            id="currency"
            defaultValue="SGD"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, currency: e.target.value })}
          >
            <option value="SGD" defaultValue={true}>
              SGD
            </option>
            <option value="USD">USD</option>
          </select>
        </div>
      </div>
      <div className="w-6/12 pt-8 px-4">
        <InputBox
          id={"vendorWebsite"}
          name={"vendorWebsite"}
          value={input.vendorWebsite}
          children={"Vendor website (optional)"}
          placeholder="Vendor website"
          className="border-2 border-gray-200 rounded p-3 mt-2"
          onChange={handleOnChange}
        />
      </div>
      <h5 className="font-medium text-lg px-4 pt-8">Vendor Address</h5>
      <div className="flex items-center px-4 space-x-2">
        <div className="w-3/12 ">
          <InputBox
            id={"addressLine"}
            name={"addressLine"}
            value={input.addressLine}
            children={"Country of Origin"}
            placeholder="Country of Origin"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 ">
          <InputBox
            id={"city"}
            name={"city"}
            value={input.city}
            children={"City"}
            placeholder="City"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 ">
          <InputBox
            id={"country"}
            name={"country"}
            value={input.country}
            children={"Country"}
            placeholder="Country"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 ">
          <InputBox
            id={"postalCode"}
            name={"postalCode"}
            value={input.postalCode}
            children={"Postal Code"}
            placeholder="Postal Code"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="flex justify-end my-7 mx-4">
        <Button buttonStyle={"btn--secondary"} restClassName="w-16 mr-3">
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          loading={loading}
          restClassName="w-16"
          spinStyle={{ marginTop: 10 }}
        >
          Create
        </Button>
      </div>
    </WrapperForm>
  );
}
