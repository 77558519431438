import React from "react";

export default function WrapperForm({ title, children, optionStyle }) {
  return (
    <div className={`flex items-center my-6 mx-3 ${optionStyle}`}>
      <div className="h-auto border-2 border-gray-100 w-full rounded-lg  ">
        <div className="w-full bg-gray-100 p-3 rounded-t-lg font-semibold text-lg">
          {title}
        </div>
        {children}
      </div>
    </div>
  );
}
