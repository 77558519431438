import { TrashIcon } from "@heroicons/react/24/outline";
import { message, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddSubComponent from "../../../components/AddSubComponent";
import Button from "../../../components/Button/Button";
import InputBox from "../../../components/Input/Input";
import {
  clearStateUpdate,
  updateData,
} from "../../../redux/updateData/updateDataSlice";
import {
  getComponents,
  getSubComponents,
} from "../../../utils/https/Components";
import { createPurchase } from "../../../utils/https/Purchasing";
import { logout } from "../../../redux/auth/authSlice";

export default function CreateNewPurchase() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataComponent, setDataComponent] = useState([]);
  const [dataSub, setDataSub] = useState([]);
  const [purchaseNotes, setPurchaseNotes] = useState("");
  const [dataDisplay, setDataDisplay] = useState([]);
  const [dataDisplayParam, setDataDisplayParam] = useState([]);

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  useEffect(() => {
    const body = {
      pageNo: 1,
      totalElement: 10000,
    };

    getComponents(authState?.token, body)
      .then(({ data }) => {
        setDataComponent(data.dataListingResponse);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  }, [authState?.token]);

  useEffect(() => {
    getSubComponents(authState?.token, dataDisplayParam.id)
      .then(({ data }) => {
        setDataDisplay(data.dataListingResponse);
      })
      .catch((err) => console.log(err));
  }, [authState?.token, dataDisplayParam]);

  const getSubData = (name, manufacturerPartNumber) => {
    const body = {
      pageNo: 1,
      totalElement: 1,
      name: name,
      manufacturerPartNumber,
    };

    getComponents(authState?.token, body)
      .then(({ data }) => {
        let body = data.dataListingResponse.map((list) => {
          return {
            ...list,
            quantity: "0",
          };
        });
        setDataSub([...dataSub, ...body]);
      })
      .catch((err) => console.log(err));
  };

  const handleChangeQuantity = (e, index) => {
    const tempArr = dataSub.map((item, idx) =>
      idx === index ? { ...item, quantity: e.target.value } : item
    );
    setDataSub([...tempArr]);
  };

  const handleRemoveItemTable = (index) => {
    const list = [...dataSub];
    list.splice(index, 1);
    setDataSub(list);
  };

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "MPN",
      selector: (row) => row.manufacturerPartNumber,
      wrap: true,
    },
    {
      name: "MANUFACTURER",
      selector: (row) => row.manufacturer,
      wrap: true,
    },
    {
      name: "COMPONENT LEVEL",
      selector: (row) => row.componentLevel,
      wrap: true,
    },
    {
      name: "QUANTITY",
      selector: (row, index) => {
        return (
          <InputBox
            value={row.quantity}
            placeholder="Enter quantity"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            type={"number"}
            onChange={(e) => handleChangeQuantity(e, index)}
          />
        );
      },
      wrap: true,
    },
    {
      name: "",
      selector: (row, index) => {
        return (
          <TrashIcon
            className="h-4 w-4 cursor-pointer"
            color="#677AA3"
            onClick={() => handleRemoveItemTable(index)}
          />
        );
      },
      width: "70px",
    },
  ];

  const handleSubmit = () => {
    setLoading(true);
    let purchaseComponentInfo = dataSub.map((list) => {
      return {
        componentId: list.id,
        amount: list.quantity,
      };
    });

    let currentdate = new Date();
    let datetime =
      currentdate.getDate() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getFullYear() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    let body = {
      deliveryDate: null,
      purchaseComponentInfo,
      status: "WAITING_CONFIRMATION",
      purchaseNotes,
    };

    // console.log(body)

    createPurchase(authState?.token, body)
      .then(() => {
        setLoading(false);
        message.success("Create purchase successed.");
        dispatch(updateData());
        setShowModal(false);
        setDataSub([]);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  const ExpandedComponent = ({ data }) => {
    setDataDisplayParam(data);

    return (
      <div>
        {dataDisplay.map((data, idx) => {
          return (
            <div className="grid grid-cols-5 mx-16 py-3" key={idx}>
              <div>{data.componentName}</div>
              <div>{data.manufacturerPartNumber}</div>
              <div>{data.manufacturer}</div>
              <div>{data.componentLevel}</div>
              <div>{data.quantity}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleShowModal = () => {
    setShowModal(true);
    dispatch(clearStateUpdate());
  };

  const isSameData = (dataComponent, dataSUb) =>
    dataComponent.id === dataSUb.id &&
    dataComponent.manufacturerPartNumber === dataSUb.manufacturerPartNumber;
  const onlyInLeft2 = (left, right, compareFunction) =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );

  const onlyInA = onlyInLeft2(dataComponent, dataSub, isSameData);
  const onlyInB = onlyInLeft2(dataSub, dataComponent, isSameData);

  const result = [...onlyInA, ...onlyInB];

  // console.log(purchaseNotes)

  return (
    <>
      {contextHolder}
      <Button
        children={"+ Request new purchase"}
        onClick={() => {
          handleShowModal();
          dispatch(clearStateUpdate());
        }}
        restClassName="w-48 my-6"
      />
      <Modal
        open={showModal}
        footer={
          <div className="flex w- space-x-3 justify-end">
            <Button
              children={"Cancel"}
              restClassName="w-16"
              buttonStyle={"btn--secondary"}
              onClick={() => {
                setShowModal(false);
                setDataSub([]);
              }}
            />
            <Button
              children={"Submit Request"}
              loading={loading}
              restClassName="w-40"
              onClick={handleSubmit}
            />
          </div>
        }
        width="900px"
        className="font-Inter"
        bodyStyle={{ padding: "0px" }}
        closable={false}
      >
        <div className="w-full">
          <div className="bg-[#002E8C] p-4  text-white">Request Purchase</div>
          <div className="bg-[#FFFFFF] p-4">
            Please enter the components you want to add to the purchase request
            list.
            <div className="my-3">
              <AddSubComponent
                result={result}
                dataSub={dataSub}
                getSubData={getSubData}
                columns={columns}
                expandedComponent={ExpandedComponent}
              />
              {dataSub.length > 0 && (
                <InputBox
                  id={"purchaseNotes"}
                  name="purchaseNotes"
                  type={"text"}
                  children={"Purchase notes (Optional)"}
                  className="border border-gray-200 rounded p-1 w-6/12 mb-2"
                  placeholder="Enter your notes..."
                  onChange={(e) => setPurchaseNotes(e.target.value)}
                  value={purchaseNotes}
                />
              )}
            </div>
            After you submit the request, system will notify{" "}
            <span className="font-bold">[ADMIN]</span> to puchase the items.
          </div>
        </div>
      </Modal>
    </>
  );
}
