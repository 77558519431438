import axios from "axios";
import { config } from "../hosts";

export const getUsers = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-users`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const createUser = (token, body) => {
  return axios.post(`${config.url}mrp-test-service/api/auth/register`, body, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });
};

export const editUserByAdmin = (token, userId, body) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/auth/admin-update-user/${userId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const deleteUser = (token, userId) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/delete-user/${userId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
