import React, { useState } from "react";
import { CalendarIcon, PlusIcon } from "@heroicons/react/24/outline";
// import Container from '../../components/Container'
// import Header from '../../components/Header'
import Layouts from "../..//components/Layouts";
import Tab from "../../components/Tab";
import AllComponents from "./AllComponents";
import { useNavigate } from "react-router-dom";

export default function Components() {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  return (
    <Layouts>
      <div className="flex  items-center bg-[#F9F9FB] -mr-16 -ml-5 xl:-mt-8">
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"All Components"}
          iconTab={<CalendarIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 1 && "bg-gray-200"}
          onClick={() => activeTab !== 1 && setActiveTab(1)}
        />
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"Create"}
          iconTab={<PlusIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 2 && "bg-gray-200"}
          onClick={() => {
            activeTab !== 2 && setActiveTab(2);
            navigate("/components/create");
          }}
        />
      </div>
      {activeTab === 1 && <AllComponents />}
      {/* {activeTab === 2 && <Link to={'/c'}/>} */}
    </Layouts>
  );
}
