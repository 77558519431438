import React, { useEffect, useState } from "react";
import {
  MagnifyingGlassIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import {
  deleteComponent,
  getComponents,
  uploadComponentExcel,
} from "../../utils/https/Components";
import InputBox from "../../components/Input/Input";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Upload,
  // Button as ButtonAnt,
} from "antd";
import { useNavigate } from "react-router-dom";
import { getCountComponent } from "../../utils/https/Dashboard";
import { logout } from "../../redux/auth/authSlice";
import Button from "../../components/Button/Button";
import { InboxOutlined } from "@ant-design/icons";
import Sort from "../../components/Sort";
// import NotifExpiredToken from "../../components/NotifExpiredToken";

export default function AllComponents() {
  const [form] = Form.useForm();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [input, setInput] = useState({ search: "" });
  const [updateComponent, setUpdateComponent] = useState(false);
  const [sortField, setSortField] = useState("");

  const sortBy = [
    { title: "Name", value: "name" },
    { title: "Description", value: "description" },
    { title: "Manufacturer", value: "manufacturer" },
    { title: "Component Level", value: "componentLevel" },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#E6E9F0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  // console.log(data);

  const confirm = (id) => {
    deleteComponent(authState?.token, id)
      .then(() => {
        message.success("Component deleted successfully.");
        setUpdateComponent(!updateComponent);
      })
      .catch((err) => console.log(err));
  };
  const cancel = (e) => {
    console.log("no");
  };

  const columns = [
    {
      name: "MPN",
      selector: (row) => row.manufacturerPartNumber,
      wrap: true,
      width: "90px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "DESC",
      selector: (row) => row.description,
      wrap: true,
    },
    {
      name: "CATEGORY",
      selector: (row) => {
        return row.productCategory;
      },
      wrap: true,
    },
    {
      name: "VNDR CODE",
      selector: (row) => {
        return row.vendorCode;
      },
      wrap: true,
    },
    {
      name: "VNDR NAME",
      selector: (row) => {
        return row.vendorName;
      },
      wrap: true,
    },
    {
      name: "SIZE",
      selector: (row) => row.packageSize,
      width: "60px",
      wrap: true,
    },
    {
      name: "UNIT COST",
      selector: (row) => row.unitCost,
      width: "100px",
      wrap: true,
    },
    {
      name: "LEVEL",
      selector: (row) =>
        row.componentLevel === "TOP_LEVEL"
          ? "LEVEL 0"
          : row.componentLevel === "MAIN_COMPONENT"
          ? "LEVEL 1"
          : row.componentLevel === "SUB_ITEM"
          ? "LEVEL 2"
          : row.componentLevel === "RAW_PART"
          ? "LEVEL 3"
          : "",
      width: "80px",
      wrap: true,
    },
    {
      name: "",
      selector: (row) => (
        <div className="flex space-x-1">
          <PencilSquareIcon
            className="h-4 w-4 cursor-pointer"
            color="#677AA3"
            onClick={() => handleDetail(row.id, row)}
          />
          <Popconfirm
            title="Are you sure to delete this component?"
            onConfirm={() => confirm(row.id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <TrashIcon className="h-4 w-4 cursor-pointer" color="#677AA3" />
          </Popconfirm>
        </div>
      ),
      sortable: true,
      width: "70px",
    },
  ];

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const onSearch = (e) => {
    e.preventDefault();
    const body = {
      pageNo: 1,
      totalElement: 10,
      name: input.search,
    };

    getComponents(authState.token, body)
      .then(({ data }) => {
        setData(data.dataListingResponse);
      })
      .catch((err) => console.log(err));
  };

  const fetchComponents = (page) => {
    setLoadingFetch(true);
    const body = {
      pageNo: page,
      totalElement: 10,
      keywords: "",
    };
    const dataFilter =
      sortField !== "" ? Object.assign(body, { sortField: sortField }) : body;
    getComponents(authState?.token, dataFilter)
      .then(({ data }) => {
        setData(data.dataListingResponse);
        setTotalPages(data.totalPage);
        setLoadingFetch(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingFetch(false);
      });
  };

  useEffect(() => {
    if (input.search === "") {
      fetchComponents(currentPage);
    }

    // getCountComponent(authState?.token)
    //   .then(({ data }) => setTotalPages(data.data))
    //   .catch((err) => {
    //     console.log(err);
    //     if (err.response.status === 401) {
    //       expiredToken();
    //     }
    //   });
  }, [authState.token, input.search, currentPage, updateComponent, sortField]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const onChange = (pagination) => {
    setCurrentPage(pagination);
  };

  const handleDetail = (componentId, item) => {
    navigate(`/components/${componentId}`, { state: { item } });
  };

  const handleUpload = () => {
    if (fileUpload !== null && fileUpload !== undefined) {
      setLoading(true);
      const data = new FormData();
      data.append("file", fileUpload);
      uploadComponentExcel(authState?.token, data)
        .then((res) => {
          message.success("Import excel file successfully");
          setUpdateComponent(!updateComponent);
          setLoading(false);
          setFileUpload(null);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      message.error("Please enter an excel file!");
    }
  };

  const normFile = (e) => {
    setFileUpload(e?.fileList[0]?.originFileObj);
  };

  const props = {
    onRemove: () => {
      setFileUpload(null);
    },
  };

  return (
    <>
      {contextHolder}
      <form onSubmit={onSearch}>
        <div className="flex justify-between items-center my-6 mx-3">
          <div className="xl:w-5/12 flex  border-2 border-gray-200  justify-start p-3 rounded-full">
            <button type="submit" cursor={"pointer"}>
              <MagnifyingGlassIcon
                className="h-6 w-6 mr-3 cursor-pointer"
                color="#99A7C1"
              />
            </button>
            <InputBox
              id={"search"}
              value={input.search}
              name="search"
              className="w-full placeholder:text-gray-400 focus:outline-none pr-40"
              placeholder="Search by name"
              type="text"
              onChange={handleOnChange}
            />
          </div>
          <div className="flex space-x-2">
            <Button
              restClassName={"px-4"}
              onClick={() => {
                setIsModalOpen(true);
                // dispatch(clearStateUpdate());
              }}
            >
              Import .xlsx
            </Button>
            <Sort
              dataSort={sortBy}
              handleChange={(e) => setSortField(e.target.value)}
            />
          </div>
        </div>
      </form>
      <div className="items-center ml-3 lg:mr-5">
        <div className="w-full ">
          <DataTable
            columns={columns}
            data={data}
            // defaultSortFieldId={1}
            customStyles={customStyles}
            progressPending={loadingFetch}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <Pagination
          defaultCurrent={1}
          total={totalPages * 10}
          onChange={onChange}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title="Import component with .xlsx"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={
          <div className="flex space-x-3 justify-end">
            <Button
              children={"Cancel"}
              restClassName="w-16"
              buttonStyle={"btn--secondary"}
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              children={"Upload"}
              loading={loading}
              spinStyle={{ marginTop: 10 }}
              restClassName="w-16"
              onClick={handleUpload}
            />
          </div>
        }
        width="1000px"
        className="font-Inter"
      >
        <Form form={form} requiredMark={false}>
          <Form.Item label={null}>
            <Form.Item
              name="dragger"
              valuePropName="file"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger
                onRemove={props.onRemove}
                maxCount={1}
                name="file"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Support for a single upload.</p>
              </Upload.Dragger>
            </Form.Item>
            {/* <ButtonAnt htmlType="reset">reset</ButtonAnt> */}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
