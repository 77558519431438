import React, { useEffect, useRef, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Checkbox, Col, Popover, Row } from "antd";
import Button from "../../../components/Button/Button";
import InputBox from "../../../components/Input/Input";
import { getVendors } from "../../../utils/https/Vendors";
import { useDispatch, useSelector } from "react-redux";
import { getInventory, getManufacturers } from "../../../utils/https/Inventory";
import { getDataByFilter } from "../../../redux/filterInventory/filterInventorySlice";

export default function ManufacturerFilter({ tabSubFilter, setTabSubFilter }) {
  // const inputRef = useRef(null);
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataManufacturers, setDataManufacturers] = useState([]);
  const [checkboxFilter, setCheckboxFilter] = useState([]);
  const [query, setQuery] = useState("");

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const onChange = (checkedValues) => {
    // inputRef.current.focus();
    setCheckboxFilter(checkedValues);
  };

  useEffect(() => {
    const body = {
      pageNo: 1,
      totalElement: 20,
    };
    getVendors(authState?.token, body)
      .then(({ data }) => {
        setDataFilter(data.dataListingResponse);
      })
      .catch((err) => console.log(err));
  }, [authState?.token]);

  useEffect(() => {
    getManufacturers(authState.token)
      .then((res) => setDataManufacturers(res.data))
      .catch((err) => console.log(err));
  }, [authState.token]);

  const filteredManufacturers =
    query === ""
      ? dataManufacturers.slice(0, 5)
      : dataManufacturers.filter((item) =>
          item
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleApply = () => {
    const body = {
      pageNo: 1,
      totalElement: 10,
      manufacturer: checkboxFilter,
    };
    // console.log(body);
    getInventory(authState?.token, body)
      .then(({ data }) => {
        // console.log(data);
        dispatch(getDataByFilter(data));
      })
      .catch((err) => console.log(err));
  };

  // const handleSearch = (value) => {
  //   const body = {
  //     pageNo: 1,
  //     totalElement: 20,
  //     keywords: value,
  //   };

  //   getVendors(authState?.token, body)
  //     .then(({ data }) => {
  //       console.log(data);
  //       setDataFilter(data.dataListingResponse);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleCheckbox = (e) => {
  //   const { name, value, checked } = e.target;
  //   console.log(value);

  //   if (name === "allSelect") {
  //     let tempUser = dataFilter.map((item) => {
  //       return { ...item, isChecked: checked };
  //     });
  //     setDataFilter(tempUser);
  //   } else {
  //     let tempUser = dataFilter.map((item) =>
  //       item.name === name ? { ...item, isChecked: checked } : item
  //     );
  //     setDataFilter(tempUser);
  //   }

  //   // if (checked) {
  //   //   setCheckboxFilter((pre) => [...pre, value]);
  //   // } else {
  //   //   setCheckboxFilter((pre) => {
  //   //     return pre.filter((item) => item !== value);
  //   //   });
  //   // }
  // };

  return (
    <Popover
      placement="bottomLeft"
      // zIndex={1}
      // className="mt-40"
      content={
        <div className=" bg-white  rounded-b-lg w-56">
          <div className="flex border-2  border-gray-100  items-center  rounded-lg p-1 bg-gray-50 mb-3">
            <MagnifyingGlassIcon
              className="h-4 w-4 mr-1 cursor-pointer"
              color="#99A7C1"
            />
            <InputBox
              id={"search"}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              name="search"
              className="w-full placeholder:text-gray-400 placeholder:text-xs focus:outline-none bg-gray-50"
              placeholder="Type manufacturer name here..."
              type="text"
            />
          </div>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={onChange}
            className="space-y-3 "
            // ref={inputRef}
          >
            {filteredManufacturers?.map((item) => {
              // console.log(item);
              return (
                <Row key={item}>
                  <Col>
                    <Checkbox
                      value={item}
                      // onClick={() => setCheckboxFilter([item.name])}
                    >
                      {item}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </Checkbox.Group>
          {/* <div>
            <input
              className="form-check-input  cursor-pointer w-3 h-3  mr-2"
              type="checkbox"
              name={"allSelect"}
              checked={
                dataFilter.filter((item) => item?.isChecked !== true).length < 1
              }
              value={dataFilter.filter((item) => item.name)}
              onChange={handleCheckbox}
            />
            <label
              className="form-check-label inline-block text-gray-800"
              htmlFor="inlineCheckbox2"
            >
              Select all
            </label>
          </div>
          {dataFilter.map((item, idx) => (
            <div className="form-check ml-2" key={idx}>
              <input
                className="form-check-input  cursor-pointer w-3 h-3  mr-2"
                type="checkbox"
                id={item.name}
                name={item.name}
                value={item.name}
                onChange={handleCheckbox}
                checked={item.isChecked}
              />
              <label
                className="form-check-label inline-block text-gray-800"
                htmlFor="inlineCheckbox2"
              >
                {item.name}
              </label>
            </div>
          ))} */}
          <div className="flex justify-end mt-6 space-x-3">
            <Button
              children={"Reset"}
              style={{ width: 65 }}
              buttonStyle={"btn--secondary"}
              onClick={() => {
                window.location.reload(false);
              }}
            />
            <Button
              children={"Apply filter"}
              style={{ width: 96 }}
              onClick={handleApply}
            />
          </div>
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <div
        className="cursor-pointer"
        onClick={() => tabSubFilter !== 1 && setTabSubFilter(1)}
      >
        <div className={`${tabSubFilter !== 1 && "text-secondary-blue"} pl-4`}>
          Manufacturer
        </div>
        <div
          className={`${
            tabSubFilter === 1 && "border-[#002E8C]"
          } border-b-2 mt-2`}
        ></div>
      </div>
    </Popover>
  );
}
