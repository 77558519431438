import React, { useState } from "react";
import Button from "../../components/Button/Button";
import { CalendarDaysIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Popover, message } from "antd";
import InputBox from "../../components/Input/Input";
import { getReports } from "../../utils/https/Reports";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/authSlice";

export default function GenerateReport({ handleSetData }) {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [active, setActive] = useState(0);
  const [reportBy, setReportBy] = useState(null);
  const [createdDateFrom, setCreatedDateFrom] = useState("");
  const [createdDateTo, setCreatedDateTo] = useState("");
  const [loading, setLoading] = useState(false);

  const filterBy = [
    {
      key: 1,
      title: "Today",
      value: "today",
    },
    {
      key: 2,
      title: "Yesterday",
      value: "yesterday",
    },
    {
      key: 3,
      title: "This Month",
      value: "thisMonth",
    },
    {
      key: 4,
      title: "Past Month",
      value: "pastMonth",
    },
    {
      key: 5,
      title: "Past 3 Months",
      value: "past3Months",
    },
  ];

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  function formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const onApply = () => {
    setLoading(true);
    const body = {
      pageNo: 1,
      totalElement: 10000,
      createdDateFrom: createdDateFrom + "T00:00:00.000Z",
      createdDateTo: createdDateTo + "T23:59:59.000Z",
    };

    getReports(authState?.token, body)
      .then((data) => {
        console.log(data);
        handleSetData(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  return (
    <>
      {contextHolder}
      <Popover
        placement="bottomLeft"
        title={"Report By :"}
        content={
          <>
            <div className="flex space-x-3 mb-6">
              <InputBox
                children={"From"}
                type={"date"}
                className="px-3 py-1 border border-[#E6E9F0]"
                value={createdDateFrom}
                onChange={(e) => setCreatedDateFrom(e.target.value)}
              />
              <InputBox
                children={"To"}
                type={"date"}
                className="px-3 py-1 border border-[#E6E9F0]"
                value={createdDateTo}
                onChange={(e) => setCreatedDateTo(e.target.value)}
              />
            </div>
            {filterBy.map((filter, index) => {
              return (
                <div
                  className={`${
                    active === index + 1 && "bg-primary-blue text-white"
                  } p-3 border border-[#E6E9F0]  cursor-pointer rounded my-2`}
                  key={index}
                  onClick={() => {
                    if (filter.value === "today") {
                      let date = new Date();
                      setCreatedDateFrom(formatDate(date));
                      setCreatedDateTo(formatDate(date));
                    } else if (filter.value === "yesterday") {
                      let date = new Date();
                      date.setDate(date.getDate() - 1);
                      setCreatedDateTo(formatDate(date));
                      setCreatedDateFrom(formatDate(date));
                    } else if (filter.value === "thisMonth") {
                      let date = new Date();
                      setCreatedDateTo(formatDate(date));
                      setCreatedDateFrom(formatDate(date.setDate(1)));
                    } else if (filter.value === "pastMonth") {
                      let date = new Date();
                      setCreatedDateTo(formatDate(date));
                      setCreatedDateFrom(
                        formatDate(
                          new Date(
                            date.getFullYear(),
                            date.getMonth() - 1,
                            date.getDate() + 1
                          )
                        )
                      );
                    } else if (filter.value === "past3Months") {
                      let date = new Date();
                      setCreatedDateTo(formatDate(date));
                      setCreatedDateFrom(
                        formatDate(
                          new Date(
                            date.getFullYear(),
                            date.getMonth() - 3,
                            date.getDate() + 1
                          )
                        )
                      );
                    }
                    // filter.value === "today" ? date.getDate() : "";
                    setActive(filter.key);
                    setReportBy(filter);
                  }}
                >
                  {filter.title}
                </div>
              );
            })}
            <div className="flex justify-end space-x-2 mt-4">
              <Button
                children={"Reset"}
                buttonStyle={"btn--secondary"}
                restClassName={"w-16"}
                onClick={() => {
                  setCreatedDateFrom("");
                  setCreatedDateTo("");
                  setActive(0);
                  setReportBy(null);
                  handleSetData([]);
                }}
              />
              <Button
                loading={loading}
                spinStyle={{ marginTop: 8 }}
                children={"Apply"}
                restClassName={"w-16"}
                onClick={onApply}
              />
            </div>
          </>
        }
        trigger="click"
        zIndex={1}
      >
        <div
          className="flex space-x-4 items-center cursor-pointer p-2 border  border-slate-400"
          // onClick={() => tabSubFilter !== 1 && setTabSubFilter(1)}
        >
          <CalendarDaysIcon className="h-4 w-4" color="#677AA3" />{" "}
          <span>{reportBy === null ? "Any date" : reportBy.title}</span>
          <ChevronDownIcon className="h-4 w-4" color="#677AA3" />
        </div>
      </Popover>
      {/* <Button restClassName={"w-48 ml-6"}>Geneate Report</Button> */}
    </>
  );
}
