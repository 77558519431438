import axios from "axios";
import { config } from "../hosts";

export const getCustomers = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-customer`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const createCustomer = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/create-customer`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const updateCustomers = (token, body, customerId) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/update-customer/${customerId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const deleteCustomer = (token, customerId) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/delete-customer/${customerId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
