import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React from "react";
import InputBox from "./Input/Input";

export default function SearchInput({
  id,
  name,
  handleOnChange,
  input,
  placeholder,
}) {
  return (
    <div className="xl:w-5/12 w-full flex  border-2 border-gray-200  p-3 rounded-full">
      <div className="w-[10%]">
        <button type="submit" cursor={"pointer"}>
          <MagnifyingGlassIcon
            className="h-6 w-6 mr-3 cursor-pointer"
            color="#99A7C1"
          />
        </button>
      </div>
      <div className="w-full">
        <InputBox
          id={id}
          value={input}
          name={name}
          className="w-full placeholder:text-gray-400 focus:outline-none "
          placeholder={placeholder}
          type="text"
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
}
