import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import imgProject from "../../assets/images/img-project.png";
import {
  PencilSquareIcon,
  InformationCircleIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import ProjectData from "./ProjectData";
import Tab from "../../components/Tab";
import { Link, useLocation } from "react-router-dom";
import ProjectBOM from "./ProjectBOM";
import { Modal, notification, Select } from "antd";
import InputBox from "../../components/Input/Input";
import { updateProject } from "../../utils/https/Projects";
import { useDispatch, useSelector } from "react-redux";
import DetailInfo from "../../components/DetailInfo";
import { clearStateUpdate } from "../../redux/updateData/updateDataSlice";
import Button from "../../components/Button/Button";
const { Option } = Select;

export default function ProjectDetails() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state } = useLocation();
  // const url = window?.location?.pathname;
  const [dataDisplay, setDataDisplay] = useState({
    name: state.item.name,
    description: state.item.description,
    customerId: "",
    status: state.item.status,
    jobNumber: state.item.jobNumber,
    createdDate: state.item.createdDate,
    modifiedAt: state.item.modifiedAt,
  });
  const [inputProjectInfo, setInputProjectInfo] = useState({
    name: state.item.name,
    description: state.item.description,
    customerId: "",
    status: state.item.status,
    jobNumber: state.item.jobNumber,
  });

  const onSelect = (value) => {
    setInputProjectInfo({ ...inputProjectInfo, status: value });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    updateProject(authState?.token, state.item.id, inputProjectInfo)
      .then(({ data }) => {
        setDataDisplay({
          name: data.data.name,
          description: data.data.description,
          status: data.data.status,
          jobNumber: data.data.jobNumber,
          createdDate: data.data.createdDate.replace(".0", ""),
          modifiedAt: data.data.modifiedAt.replace(".0", ""),
        });
        openNotificationWithIcon();
      })
      .catch((err) => console.log(err));
  };

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Success",
      description: "Project successfully updated.",
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputProjectInfo({ ...inputProjectInfo, [name]: value });
  };

  return (
    <Layouts>
      <div className="flex  w-full">
        <div className="w-8/12 space-x-2">
          <Link to="/projects">
            All Projects <span className="text-primary-black">/</span>
          </Link>
          <a className="cursor-text">
            Project Detail <span className="text-primary-black ">/</span>
          </a>
          <a className="text-primary-black hover:text-primary-black cursor-text">
            {dataDisplay?.name} <span className="text-primary-black">/</span>
          </a>
        </div>
      </div>
      <div className="flex items-center mt-6">
        <div className="w-11/12 flex items-center space-x-2">
          <DetailInfo
            img={imgProject}
            name={dataDisplay.name}
            description={dataDisplay.description}
            // showModal={showModal}
          />
        </div>
        <Button
          children={"Edit project details"}
          restClassName="w-40"
          onClick={() => {
            setIsModalOpen(true);
            dispatch(clearStateUpdate());
          }}
        />
      </div>
      <div className="flex  items-center bg-[#F9F9FB] -mr-16  mt-4">
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"Project Data"}
          iconTab={<InformationCircleIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 1 && "bg-gray-200"}
          onClick={() => activeTab !== 1 && setActiveTab(1)}
        />
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"BOM"}
          iconTab={<NewspaperIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 2 && "bg-gray-200"}
          onClick={() => activeTab !== 2 && setActiveTab(2)}
        />
      </div>
      <Modal
        title="Edit Project"
        open={isModalOpen}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        width="800px"
        className="font-Inter"
      >
        <div className="flex space-x-2">
          <div className="w-6/12">
            <InputBox
              id={"name"}
              name="name"
              type={"text"}
              children={"Name"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={inputProjectInfo.name}
            />
          </div>
          <div className="w-6/12">
            <InputBox
              id={"description"}
              name="description"
              type={"text"}
              children={"Description"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={inputProjectInfo.description}
            />
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="w-6/12 flex flex-col">
            <label htmlFor="status" className="font-semibold mb-2">
              Select Status
            </label>
            <Select
              id="status"
              defaultValue={state.item.status}
              onChange={onSelect}
            >
              <Option value="OPEN" key={"sasds"}>
                OPEN
              </Option>
              <Option value="CLOSE" key={"sadsaw"}>
                CLOSE
              </Option>
            </Select>
          </div>
          <div className="w-6/12">
            <InputBox
              id={"jobNumber"}
              name="jobNumber"
              type={"text"}
              children={"Job number"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={inputProjectInfo.jobNumber}
            />
          </div>
        </div>
      </Modal>
      {activeTab === 1 && <ProjectData item={dataDisplay} />}
      {activeTab === 2 && <ProjectBOM item={state} />}
    </Layouts>
  );
}
