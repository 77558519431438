import React, { useEffect, useState } from "react";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { message, Select } from "antd";
import { createProjects } from "../../utils/https/Projects";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../utils/https/Customers";
import WrapperForm from "../../components/WrapperForm";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { logout } from "../../redux/auth/authSlice";

const { Option } = Select;

export default function CreateProject() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    name: "",
    description: "",
    jobNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectStatus, setSelectStatus] = useState("0");
  const [dataCustomers, setDataCustomers] = useState([]);

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  useEffect(() => {
    const body = {
      pageNo: 1,
      totalElement: 1000000,
      keywords: "",
    };
    getCustomers(authState?.token, body)
      .then(({ data }) => setDataCustomers(data.dataListingResponse))
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  }, [authState?.token]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const onSelect = (value) => {
    setSelectStatus(value);
    setInput({ ...input, status: value });
  };

  const handleCreate = () => {
    if (input.name === "") return message.error("Project's name is required!");
    if (input.description === "")
      return message.error("Description is required!");
    // if (selectStatus === "Choose customer") return message.error("Status is required!");

    setLoading(true);
    createProjects(authState?.token, input)
      .then(({ data }) => {
        message.success(data.message);
        setInput({ name: "", description: "" });
        setSelectStatus("0");
        const item = data.data;
        setLoading(false);
        navigate(`/projects/${data.data.id}`, {
          state: {
            item,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  return (
    <WrapperForm title={"Create New Project"}>
      {contextHolder}
      <div className="flex items-center">
        <div className="w-4/12 pt-8 px-4">
          <InputBox
            id={"jobNumber"}
            name={"jobNumber"}
            type="number"
            value={input.jobNumber}
            children={"Job number"}
            placeholder="Enter Job number"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-4/12 pt-8 px-4">
          <InputBox
            id={"name"}
            name={"name"}
            value={input.name}
            children={"Project's Name"}
            placeholder="Enter project's name "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-4/12 pt-8 px-4">
          <label htmlFor="status" className="font-semibold ">
            Select Customer (Optional)
          </label>
          <Select
            id="status"
            style={{ width: "100%", marginTop: 8 }}
            defaultValue="0"
            onChange={onSelect}
          >
            <Option value="0" key={"zywaw"}>
              Choose customer
            </Option>
            {dataCustomers.map((item, idx) => (
              <Option value={item.id} key={idx}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="pt-8 px-4 space-y-3 w-full">
        <label htmlFor="description" className="font-semibold ">
          Description
        </label>
        <TextArea
          rows={4}
          id={"description"}
          name={"description"}
          value={input.description}
          onChange={handleOnChange}
        />
      </div>
      {/* <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4 flex flex-col">
          <label htmlFor="status" className="font-semibold mb-2">
            Select Customer (Optional)
          </label>
          <Select id="status" defaultValue="0" onChange={onSelect}>
            <Option value="0" key={"zywaw"}>
              Choose customer
            </Option>
            {dataCustomers.map((item, idx) => (
              <Option value={item.id} key={idx}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"jobNumber"}
            name={"jobNumber"}
            type="number"
            value={input.jobNumber}
            children={"Job number"}
            placeholder="Enter Job number"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
      </div> */}
      <div className="flex justify-end my-7 mx-4">
        <Button
          buttonStyle={"btn--secondary"}
          restClassName="w-16 mr-3"
          // onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          loading={loading}
          restClassName="w-16"
          spinStyle={{ marginTop: 10 }}
        >
          Create
        </Button>
      </div>
    </WrapperForm>
  );
}
