import axios from "axios";
import { config } from "../hosts";

export const getInventory = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-inventory`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const getManufacturers = (token) => {
  return axios.get(
    `${config.url}mrp-test-service/api/general/get-manufacturers`,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const updateStock = (token, body) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/update-stock`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
