import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/24/solid";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import InputBox from "../../components/Input/Input";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { deleteProject, getProjects } from "../../utils/https/Projects";
import { message, Pagination, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authSlice";
import { getProjectsCount } from "../../utils/https/Dashboard";
import Sort from "../../components/Sort";
import SearchInput from "../../components/SearchInput";

export default function AllProjectsTest() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortField, setSortField] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const sortBy = [
    { title: "Name", value: "name" },
    { title: "Description", value: "description" },
    { title: "Status", value: "status" },
    { title: "Created At", value: "createdAt" },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#E6E9F0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const onSearch = (e) => {
    e.preventDefault();
    const body = {
      pageNo: 1,
      totalElement: 10,
      keywords: searchKey,
      // order: "descending",
    };

    
    getProjects(authState.token, body)
      .then(({ data }) => {
        setData(data.dataListingResponse);
        // setTotalPages(data.length);
      })
      .catch((err) => console.log(err));
    
  };

  const confirm = (id) => {
    deleteProject(authState?.token, id)
      .then(() => {
        message.success("Project deleted successfully.");
        setDeleteSuccess(!deleteSuccess);
      })
      .catch((err) => console.log(err));
  };
  const cancel = (e) => {
    console.log("no");
  };

  const columns = [
    {
      name: "JOB #",
      selector: (row) => row.jobNumber,
      wrap: true,
    },
    {
      name: "PROJECT",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      wrap: true,
    },
    {
      name: "DATE CREATED",
      selector: (row) => {
        return row.createdDate.replace(".0", "");
      },
      wrap: true,
    },
    {
      name: "LAST UPDATED",
      selector: (row) => row.modifiedAt.replace(".0", ""),
      wrap: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.status === "OPEN",
          style: (row) => ({
            backgroundColor: row.status === "OPEN" && "#677AA3",
            color: "black",
          }),
        },
        {
          when: (row) => row.status === "CLOSE",
          style: (row) => ({
            backgroundColor: row.status === "CLOSE" && "#E6E9F0",
            color: "black",
          }),
        },
      ],
    },
    {
      name: "",
      selector: (row) => (
        <div className="flex space-x-1">
          <DocumentMagnifyingGlassIcon
            className="h-5 w-5 cursor-pointer"
            color="#677AA3"
            onClick={() => handleDetail(row.id, row)}
          />
          <Popconfirm
            title="Are you sure to delete this project?"
            onConfirm={() => confirm(row.id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <TrashIcon className="h-4 w-4 cursor-pointer" color="#677AA3" />
          </Popconfirm>
        </div>
      ),
      width: "70px",
      wrap: true,
    },
  ];

  const fetchProject = (page) => {
    setLoading(true);
    const body = {
      pageNo: page,
      totalElement: 10,
      // keywords: "",
    };
    const dataFilter =
      sortField !== "" ? Object.assign(body, { sortField: sortField }) : body;
    getProjects(authState.token, dataFilter)
      .then(({ data }) => {
        setData(data.dataListingResponse);
        setTotalPages(data.totalPage);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          expiredToken();
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (searchKey === "") {
      fetchProject(currentPage);
    }

    fetchProject(1);
    // getProjectsCount(authState?.token)
    //   .then(({ data }) => setTotalPages(data.data))
    //   .catch((err) => console.log(err));
  }, [authState.token, currentPage, searchKey, sortField, deleteSuccess]);

  const onChange = (pagination) => {
    setCurrentPage(pagination);
  };

  const handleDetail = (id, item) => {
    navigate(`/projects/${id}`, {
      state: {
        item,
      },
    });
  };

  // console.log(totalPages);

  return (
    <div className="m-8">
      {contextHolder}
      <div className="flex justify-between items-center">
        <div className="w-[80%]">
          <form onSubmit={onSearch}> 
            <SearchInput
              placeholder={"Search by name..."}
              //handleOnChange={(e) => console.log('test')}
              handleOnChange={(e) => setSearchKey(e.target.value)}
            />
          </form>
        </div>
        <div className=" mt-2">
          <Sort
            handleChange={(e) => setSortField(e.target.value)}
            dataSort={sortBy}
          />
        </div>
      </div>
      <div className="flex items-center ml-3 lg:mr-5 mt-6">
        <div className="w-full ">
          <DataTable
            columns={columns}
            data={data}
            // defaultSortFieldId={1}
            customStyles={customStyles}
            // selectableRows
            progressPending={loading}
          />
        </div>
      </div>
      <div className="flex justify-end my-3">
        <Pagination
          defaultCurrent={1}
          total={totalPages * 10}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
