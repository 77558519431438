import React, { useState } from "react";
import WrapperForm from "../../components/WrapperForm";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { logout } from "../../redux/auth/authSlice";
import { changePassword } from "../../utils/https/Profile";

export default function TabChangePassword() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    username: authState?.username,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  //   console.log(authState);

  function handleSave() {
    if (input.oldPassword === "") {
      return message.error("Current password is required!");
    }
    if (input.newPassword === "") {
      return message.error("New password is required!");
    }
    if (input.confirmPassword === "") {
      return message.error("Confirm password is required!");
    }
    if (input.newPassword !== input.confirmPassword) {
      return message.error("New password and Confirm password doesn't match!");
    }
    setLoading(true);
    changePassword(authState?.token, input)
      .then(({ data }) => {
        setInput({
          username: authState?.username,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setLoading(false);
        message.success(data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          message.error("Wrong current password!");
        }
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  }

  return (
    <WrapperForm title={"Account Detail"}>
      {contextHolder}
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            type={"password"}
            id={"oldPassword"}
            name={"oldPassword"}
            value={input.oldPassword}
            children={"Current Password"}
            placeholder="Enter current password"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) =>
              setInput({ ...input, oldPassword: e.target.value })
            }
          />
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            type={"password"}
            id={"newPassword"}
            name={"newPassword"}
            value={input.newPassword}
            children={"New Password"}
            placeholder="Enter new password"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) =>
              setInput({ ...input, newPassword: e.target.value })
            }
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            type={"password"}
            id={"confirmPassword"}
            name={"confirmPassword"}
            value={input.confirmPassword}
            children={"Confirm Password"}
            placeholder="Enter confirm password"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) =>
              setInput({ ...input, confirmPassword: e.target.value })
            }
          />
        </div>
      </div>
      <div className="flex justify-end my-7 mx-4">
        <Button
          buttonStyle={"btn--secondary"}
          onClick={() =>
            setInput({
              username: authState?.username,
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            })
          }
          restClassName="w-16 mr-3"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          loading={loading}
          restClassName="w-16"
          spinStyle={{ marginTop: 10 }}
        >
          Create
        </Button>
      </div>
    </WrapperForm>
  );
}
