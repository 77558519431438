import React from "react";
import dashboardIcon from "../assets/images/dashboard-icon.png";
import projectsIcon from "../assets/images/project-icon.png";
import componentsIcon from "../assets/images/component-icon.png";
import inventoryIcon from "../assets/images/inventory-icon.png";
import customersIcon from "../assets/images/customers-icon.png";
import vendorsIcon from "../assets/images/vendors-icon.png";
import reportsIcon from "../assets/images/reports-icon.png";
import usersIcon from "../assets/images/users-icon.png";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const token = useSelector((state) => state?.user?.user?.data?.data?.token);
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const menu = [
    {
      parentMenu: "",
      menuName: "Dashboard",
      menuIcon: dashboardIcon,
      url: "/",
    },

    {
      parentMenu: "Manufacturing",
      menuName: "Components",
      menuIcon: componentsIcon,
      url: "components",
    },
    {
      parentMenu: "Management",
      menuName: "Inventory",
      menuIcon: inventoryIcon,
      url: "inventory",
    },
  ];

  let adminMenu = [
    {
      parentMenu: "Management",
      menuName: "Customers",
      menuIcon: customersIcon,
      url: "customers",
    },
    {
      parentMenu: "Management",
      menuName: "Vendors",
      menuIcon: vendorsIcon,
      url: "vendors",
    },
    {
      parentMenu: "Management",
      menuName: "Users",
      menuIcon: usersIcon,
      url: "users",
    },
  ];
  // let onlyAdminAndBuyerMenu = [
  //   {
  //     parentMenu: "Manufacturing",
  //     menuName: "Components",
  //     menuIcon: componentsIcon,
  //     url: "components",
  //   },
  // ];
  let menuWithoutBuyer = [
    {
      parentMenu: "Manufacturing",
      menuName: "Projects",
      menuIcon: projectsIcon,
      url: "projects",
    },
    {
      parentMenu: "Reports",
      menuName: "Reports",
      menuIcon: vendorsIcon,
      url: "reports",
    },
  ];

  if (authState.roles[0] === "ADMIN") {
    menu.push(...adminMenu);
  }
  // if (
  //   authState.roles[0] === "ADMIN" ||
  //   authState.roles[0] === "BUYER"
  // ) {
  //   menu.push(...onlyAdminAndBuyerMenu);
  // }
  if (
    authState?.roles[0] === "ADMIN" ||
    authState?.roles[0] === "PROJECT_MANAGER" ||
    authState?.roles[0] === "DESIGN_ENGINEER"
  ) {
    menu.push(...menuWithoutBuyer);
  }

  if (token !== undefined) {
    return (
      <>
        <div className="min-h-screen bg-[#000A1E] text-white w-44 rounded  fixed font-Inter">
          <div className="">
            {menu.map(
              (item, i) =>
                item.parentMenu === "" && (
                  <div className="flex " key={i}>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          height: 45,
                          backgroundColor: isActive ? "#002E8C" : "#000A1E",
                          width: "100%",
                          display: "flex",
                          color: "white",
                          textDecoration: "none",
                          alignItems: "center",
                          padding: 15,
                        };
                      }}
                      to={item.url}
                    >
                      <img
                        src={item.menuIcon}
                        alt={item.menuName}
                        className="mr-5"
                        style={{
                          color: "grey",
                          maxHeight: 30,
                          background: "transparent",
                        }}
                      />
                      {item.menuName}
                    </NavLink>
                  </div>
                )
            )}
            <h5 className="font-bold text-secondary-blue p-5 ">
              Manufacturing
            </h5>
            {menu.map(
              (item, i) =>
                item.parentMenu === "Manufacturing" && (
                  <div className="flex " key={i}>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          height: 45,
                          backgroundColor: isActive ? "#002E8C" : "#000A1E",
                          width: "100%",
                          display: "flex",
                          color: "white",
                          textDecoration: "none",
                          alignItems: "center",
                          padding: 15,
                        };
                      }}
                      to={item.url}
                    >
                      <img
                        src={item.menuIcon}
                        alt={item.menuName}
                        className="mr-5"
                        style={{
                          color: "grey",
                          maxHeight: 30,
                          background: "transparent",
                        }}
                      />
                      {item.menuName}
                    </NavLink>
                  </div>
                )
            )}
            <h5 className="font-bold text-secondary-blue p-5">Management</h5>
            {menu.map(
              (item, i) =>
                item.parentMenu === "Management" && (
                  <div className="flex " key={i}>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          height: 45,
                          backgroundColor: isActive ? "#002E8C" : "#000A1E",
                          width: "100%",
                          display: "flex",
                          color: "white",
                          textDecoration: "none",
                          alignItems: "center",
                          padding: 15,
                        };
                      }}
                      to={item.url}
                    >
                      <img
                        src={item.menuIcon}
                        alt={item.menuName}
                        className="mr-5"
                        style={{
                          color: "grey",
                          maxHeight: 45,
                          background: "transparent",
                        }}
                      />
                      {item.menuName}
                    </NavLink>
                  </div>
                )
            )}
            {(authState?.roles[0] === "ADMIN" ||
              authState?.roles[0] === "PROJECT_MANAGER" ||
              authState?.roles[0] === "DESIGN_ENGINEER") && (
              <h5 className="font-bold text-secondary-blue p-5">Reports</h5>
            )}

            {menu.map(
              (item, i) =>
                item.parentMenu === "Reports" && (
                  <div className="flex " key={i}>
                    <NavLink
                      style={({ isActive }) => {
                        return {
                          height: 45,
                          backgroundColor: isActive ? "#002E8C" : "#000A1E",
                          width: "100%",
                          display: "flex",
                          color: "white",
                          textDecoration: "none",
                          alignItems: "center",
                          padding: 15,
                        };
                      }}
                      to={item.url}
                    >
                      <img
                        src={item.menuIcon}
                        alt={item.menuName}
                        className="mr-5"
                        style={{
                          color: "grey",
                          maxHeight: 30,
                          background: "transparent",
                        }}
                      />
                      {item.menuName}
                    </NavLink>
                  </div>
                )
            )}
          </div>
        </div>
      </>
    );
  }
}
