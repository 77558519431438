import React, { useEffect, useState } from "react";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { CalendarIcon, PlusIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/outline";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import WrapperForm from "../../components/WrapperForm";
import {
  createComponent,
  createSubComponent,
  getComponents,
  getSubComponents,
} from "../../utils/https/Components";
import { useNavigate } from "react-router-dom";
import Layouts from "../../components/Layouts";
import Tab from "../../components/Tab";
import AddSubComponent from "../../components/AddSubComponent";
import { getVendors } from "../../utils/https/Vendors";
import { logout } from "../../redux/auth/authSlice";
// import DataTable from "react-data-table-component";

export default function CreateComponentTest() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Layouts>
      <div className="flex  items-center bg-[#F9F9FB] -mr-16 -ml-5 xl:-mt-8">
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"All Components"}
          iconTab={<CalendarIcon className="h-6 w-6 mr-2" />}
          // activeTab={activeTab === 1 && "bg-gray-200"}
          onClick={() => {
            activeTab !== 1 && setActiveTab(1);
            navigate("/components");
          }}
        />
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"Create"}
          iconTab={<PlusIcon className="h-6 w-6 mr-2" />}
          activeTab={"bg-gray-200"}
          onClick={() => {
            activeTab !== 2 && setActiveTab(2);
            navigate("/components/create");
          }}
        />
      </div>
      {/* {activeTab === 1 && <AllComponents />} */}
      <FormCreateComponent />
    </Layouts>
  );
}

export const FormCreateComponent = ({ handleCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const [loading, setLoading] = useState(false);
  const [dataComponent, setDataComponent] = useState([]);
  const [dataSUb, setDataSUb] = useState([]);
  const [dataDisplayParam, setDataDisplayParam] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [dataVendor, setDataVendor] = useState([]);
  const [componentLevel, setComponentLevel] = useState("0");
  const [input, setInput] = useState({
    name: "",
    description: "",
    manufacturer: "",
    manufacturerPartNumber: "",
    productCategory: "",
    packageSize: 0,
    tolerance: 0,
    temperature: 0,
    unitCost: 0,
    powerRating: 0,
  });

  useEffect(() => {
    if (input.componentLevel === "TOP_LEVEL") {
      const body = {
        pageNo: 1,
        totalElement: 1000,
        componentLevel: ["MAIN_COMPONENT", "SUB_ITEM", "RAW_PART"],
      };
      getComponents(authState?.token, body)
        .then(({ data }) => {
          setDataComponent(data.dataListingResponse);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            expiredToken();
          }
        });
    } else if (input.componentLevel === "MAIN_COMPONENT") {
      const body = {
        pageNo: 1,
        totalElement: 1000,
        componentLevel: ["SUB_ITEM", "RAW_PART"],
      };
      getComponents(authState?.token, body)
        .then(({ data }) => {
          setDataComponent(data.dataListingResponse);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            expiredToken();
          }
        });
    } else if (input.componentLevel === "SUB_ITEM") {
      const body = {
        pageNo: 1,
        totalElement: 1000,
        componentLevel: ["RAW_PART"],
      };
      getComponents(authState?.token, body)
        .then(({ data }) => setDataComponent(data.dataListingResponse))
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            expiredToken();
          }
        });
    }
  }, [authState.token, componentLevel]);

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "MPN",
      selector: (row) => row.manufacturerPartNumber,
      wrap: true,
    },
    {
      name: "MANUFACTURER",
      selector: (row) => row.manufacturer,
      wrap: true,
    },
    {
      name: "COMPONENT LEVEL",
      selector: (row) => row.componentLevel,
      wrap: true,
    },
    {
      name: "QUANTITY",
      selector: (row, index) => {
        return (
          <InputBox
            value={row.quantity}
            placeholder="Enter quantity"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            type={"number"}
            onChange={(e) => handleChangeQuantity(e, index)}
          />
        );
      },
      wrap: true,
    },
    {
      name: "",
      selector: (row, index) => {
        return (
          <TrashIcon
            className="h-4 w-4 cursor-pointer"
            color="#677AA3"
            onClick={() => handleRemoveItemTable(index)}
          />
        );
      },
      width: "70px",
    },
  ];

  function expiredToken() {
    {
      messageApi.open({
        type: "error",
        content: "Your login was expired. You will redirect to sign in page.",
        duration: 2,
      });
    }
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  useEffect(() => {
    getSubComponents(authState?.token, dataDisplayParam.id)
      .then(({ data }) => {
        setDataDisplay(data.dataListingResponse);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataDisplayParam]);

  useEffect(() => {
    const body = {
      pageNo: 1,
      totalElement: 10000,
    };
    getVendors(authState?.token, body)
      .then(({ data }) => {
        setDataVendor(data.dataListingResponse);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  }, [authState?.token]);

  const isSameData = (dataComponent, dataSUb) =>
    dataComponent.id === dataSUb.id &&
    dataComponent.manufacturerPartNumber === dataSUb.manufacturerPartNumber;
  const onlyInLeft2 = (left, right, compareFunction) =>
    left?.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );

  const onlyInA = onlyInLeft2(dataComponent, dataSUb, isSameData);
  const onlyInB = onlyInLeft2(dataSUb, dataComponent, isSameData);

  const result = [...onlyInA, ...onlyInB];

  const ExpandedComponent = ({ data }) => {
    // const inputRef = useRef();
    setDataDisplayParam(data);

    return (
      <div>
        {dataDisplay.map((data, idx) => {
          return (
            <div className="grid grid-cols-5 mx-16 py-3" key={idx}>
              <div>{data.componentName}</div>
              <div>{data.manufacturerPartNumber}</div>
              <div>{data.manufacturer}</div>
              <div>{data.componentLevel}</div>
              <div>{data.quantity}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleChangeQuantity = (e, index) => {
    const tempArr = dataSUb.map((item, idx) =>
      idx === index ? { ...item, quantity: e.target.valueAsNumber } : item
    );
    setDataSUb([...tempArr]);
  };
  const handleRemoveItemTable = (index) => {
    const list = [...dataSUb];
    list.splice(index, 1);
    setDataSUb(list);
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };
  const handleOnChangeNum = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: parseInt(value) });
  };

  const onSelect = (e) => {
    setInput({ ...input, componentLevel: e.target.value });
    setComponentLevel(e.target.value);
  };

  // console.log(dataSUb);

  const getSubData = (name, manufacturerPartNumber) => {
    const body = {
      pageNo: 1,
      totalElement: 1,
      name: name,
      manufacturerPartNumber,
    };

    getComponents(authState?.token, body)
      .then(({ data }) => {
        let body = data.dataListingResponse.map((list) => {
          return {
            ...list,
            quantity: "0",
          };
        });
        setDataSUb([...dataSUb, ...body]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreate = () => {
    if (input.componentLevel === "0" || input.componentLevel === undefined)
      return message.error("Component Type is required!");
    if (input.name === "")
      return message.error("Component's name is required!");
    if (input.manufacturerPartNumber === "")
      return message.error("Manufacturer PN is required!");
    if (input.vendorId === "" || input.vendorId === undefined)
      return message.error("Vendor is required!");
    if (isNaN(input.packageSize))
      return message.error("Package size is required!");
    if (isNaN(input.powerRating))
      return message.error("Power rating is required!");
    if (isNaN(input.tolerance)) return message.error("Tolerance is required!");
    if (isNaN(input.temperature))
      return message.error("Temperature is required!");
    if (isNaN(input.unitCost)) return message.error("Unit cost is required!");
    setLoading(true);
    createComponent(authState?.token, input)
      .then(({ data }) => {
        if (
          input.componentLevel === "SUB_ITEM" ||
          input.componentLevel === "TOP_LEVEL" ||
          input.componentLevel === "MAIN_COMPONENT"
        ) {
          let body = dataSUb.map((list) => {
            return {
              partComponentId: list.id,
              quantity: list.quantity,
              componentId: data.data.id,
            };
          });

          // console.log(body);

          createSubComponent(authState?.token, body)
            .then(({ data }) => {
              setLoading(false);
              message.success(data.message);
              setInput({
                name: "",
                description: "",
                manufacturer: "",
                manufacturerPartNumber: "",
                productCategory: "",
                packageSize: 0,
                tolerance: 0,
                temperature: 0,
                unitCost: 0,
                powerRating: 0,
              });
              navigate("/components");
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              if (err.response.status === 401) {
                expiredToken();
              }
            });
        } else if (input.componentLevel === "RAW_PART") {
          setLoading(false);
          message.success(data.message);
          setInput({
            name: "",
            description: "",
            manufacturer: "",
            manufacturerPartNumber: "",
            productCategory: "",
            packageSize: 0,
            tolerance: 0,
            temperature: 0,
            unitCost: 0,
            powerRating: 0,
          });
          navigate("/components");
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          expiredToken();
        }
        setLoading(false);
      });
  };

  // console.log(input);

  return (
    <WrapperForm title={"Create New Component"}>
      {contextHolder}
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4 flex flex-col ">
          <label htmlFor="componentType" className="font-semibold mb-2">
            Components Type
          </label>
          <select
            id="componentType"
            defaultValue="0"
            className="border-2 border-gray-200 rounded p-3 "
            onChange={onSelect}
          >
            <option value="0" defaultValue={true}>
              Select component type
            </option>
            <option value="TOP_LEVEL">LEVEL 0</option>
            <option value="MAIN_COMPONENT">LEVEL 1</option>
            <option value="SUB_ITEM">LEVEL 2</option>
            <option value="RAW_PART">LEVEL 3</option>
          </select>
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"name"}
            name={"name"}
            value={input.name}
            children={"Component's Name"}
            placeholder="Enter component's name "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-full pt-8 px-4">
          <InputBox
            id={"description"}
            name={"description"}
            value={input.description}
            children={"Description"}
            placeholder="Description about this components"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-3/12 pt-8 px-4 flex flex-col">
          <label htmlFor="vendorId" className="font-semibold mb-2">
            Vendor Name
          </label>
          <select
            id="vendorId"
            defaultValue="0"
            className="border-2 border-gray-200 rounded p-3 "
            onChange={(e) => setInput({ ...input, vendorId: e.target.value })}
          >
            <option value="0" defaultValue={true}>
              Select vendor
            </option>
            {dataVendor?.map((data, index) => {
              return (
                <option value={data.id} key={index}>
                  {data.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="w-3/12 pt-8 px-4">
          <InputBox
            id={"manufacturer"}
            name={"manufacturer"}
            value={input.manufacturer}
            children={"Manufacturer"}
            placeholder="Manufacturer"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 pt-8 px-4 ">
          <InputBox
            id={"manufacturerPartNumber"}
            name={"manufacturerPartNumber"}
            value={input.manufacturerPartNumber}
            children={"Manufacturer PN"}
            placeholder="Parts Number"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 pt-8 px-4 ">
          <InputBox
            id={"productCategory"}
            name={"productCategory"}
            value={input.productCategory}
            children={"Product Category"}
            placeholder="Category"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-3/12 pt-8 px-4 ">
          <InputBox
            id={"packageSize"}
            name={"packageSize"}
            value={input.packageSize}
            children={"Package Size"}
            placeholder="Size"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            type={"number"}
            onChange={handleOnChangeNum}
          />
        </div>
        <div className="w-3/12 pt-8 px-4">
          <InputBox
            id={"powerRating"}
            name={"powerRating"}
            value={input.powerRating}
            children={"Power Rating"}
            placeholder="Power value"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            type={"text"}
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 pt-8 px-4 ">
          <InputBox
            id={"tolerance"}
            name={"tolerance"}
            value={input.tolerance}
            children={"Tolerance"}
            placeholder="Tolerance value"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            type={"number"}
            onChange={handleOnChangeNum}
          />
        </div>
        <div className="w-3/12 pt-8 px-4 ">
          <InputBox
            id={"temperature"}
            name={"temperature"}
            value={input.temperature}
            children={"Temperature"}
            placeholder="Temperature value"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            type={"number"}
            onChange={handleOnChangeNum}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-3/12 pt-8 px-4 ">
          <InputBox
            step={0.1}
            id={"unitCost"}
            name={"unitCost"}
            value={input.unitCost}
            children={"Unit Cost"}
            placeholder="Price"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            type={"number"}
            onChange={(e) =>
              setInput({ ...input, unitCost: e.target.valueAsNumber })
            }
          />
        </div>
      </div>

      {(input?.componentLevel === "TOP_LEVEL" ||
        input?.componentLevel === "MAIN_COMPONENT" ||
        input?.componentLevel === "SUB_ITEM") && (
        <WrapperForm
          title={`${
            input.componentLevel === "TOP_LEVEL"
              ? "Add Main component / Sub items / Raw"
              : input?.componentLevel === "MAIN_COMPONENT"
              ? "Add Sub items / Raw"
              : "Add raw parts"
          }`}
          optionStyle="h-auto pb-3"
        >
          <AddSubComponent
            result={result}
            dataSub={dataSUb}
            getSubData={getSubData}
            columns={columns}
            restClassName="absolute"
            expandedComponent={ExpandedComponent}
          />
        </WrapperForm>
      )}
      <div className="flex justify-end my-7 mx-4">
        <Button
          buttonStyle={"btn--secondary"}
          restClassName="w-16 mr-3"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          loading={loading}
          restClassName="w-16"
          spinStyle={{ marginTop: 10 }}
        >
          Create
        </Button>
      </div>
    </WrapperForm>
  );
};
