import React from "react";

export default function Tab({
  activeTab,
  iconTab,
  titleTab,
  onClick,
  optionStyle,
}) {
  return (
    <div
      className={`flex p-5 items-center cursor-pointer ${activeTab} ${optionStyle} `}
      onClick={onClick}
    >
      {iconTab}
      {titleTab}
    </div>
  );
}
