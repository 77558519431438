import React, { useState } from "react";
import LayoutAuth from "./LayoutAuth";
import logoMrp from "../../assets/images/mrp-logo.png";
import { UserIcon } from "@heroicons/react/24/solid";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { message } from "antd";
import { resetPassword } from "../../utils/https/Auth";

export default function ForgetPassword() {
  const [input, setInput] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  //   console.log(input);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.email === "") {
      message.error("Please enter your email!");
    }
    if (!input.email.includes("@")) {
      return message.error("Please enter a valid email!");
    }
    setLoading(true);
    resetPassword(input)
      .then(() => {
        message.success(
          "Your password has been reset, please check your email!"
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <LayoutAuth>
      <div className="flex justify-center mt-20">
        <h5 className="font-bold text-2xl text-primary-blue mt-8">
          Forgot your password?
        </h5>
      </div>
      <div className="flex justify-center mt-4">
        <p className="text-secondary-blue text-center ">
          Enter email address associated with your account. <br /> and we'll
          send you a link to reset you password.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-start bg-[#FFFFFF] rounded p-1 w-4/6 mx-auto mt-6">
          <div className="w-1/12 flex items-center">
            <UserIcon className="h-6 w-6" color="#99A7C1" />
          </div>

          {/* <input
                type="text"
                className="bg-[#FFFFFF] rounded p-2 w-full placeholder:text-primary-blue focus:outline-none"
                placeholder="Enter your email"
                onChange={(e) => setUsername(e.target.value)}
              /> */}
          <div className="w-full">
            <InputBox
              id={"username"}
              value={input.email}
              name="username"
              className="bg-[#FFFFFF] rounded p-2  placeholder:text-primary-blue focus:outline-none"
              // style={{ width: "100%" }}
              placeholder="Enter your email"
              onChange={(e) => setInput({ email: e.target.value })}
            />
          </div>
        </div>
        <div className="flex justify-center w-4/6 mx-auto  mt-4">
          <Button
            children={"Verify"}
            type="submit"
            restClassName={"w-full"}
            loading={loading}
          />
        </div>
      </form>
    </LayoutAuth>
  );
}
