import React, { useState } from "react";
import WrapperForm from "../../components/WrapperForm";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { message } from "antd";
import { createUser } from "../../utils/https/Users";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/authSlice";

export default function CreateUser() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    fullName: "",
    role: ["ADMIN"],
  });

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const handleCreate = () => {
    if (input.fullName === "") {
      return message.error("Please enter Fullname!");
    }
    if (input.username === "") {
      return message.error("Please enter username!");
    }
    if (input.email === "") {
      return message.error("Please enter email!");
    }
    if (!input.email.includes("@")) {
      return message.error("Please enter a valid email!");
    }
    if (input.phoneNumber === "") {
      return message.error("Please enter phone number!");
    }
    if (input.role.length < 1) {
      return message.error("Please enter select a role!");
    }
    setLoading(true);

    createUser(authState?.token, input)
      .then((res) => {
        message.success("User registered successfully!");
        setLoading(false);
        setInput({
          username: "",
          email: "",
          phoneNumber: "",
          fullName: "",
          role: ["ADMIN"],
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };
  return (
    <WrapperForm title={"Create new user"}>
      {contextHolder}
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"fullName"}
            name={"fullName"}
            value={input.fullName}
            children={"Full Name"}
            placeholder="Enter Full name "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, fullName: e.target.value })}
          />
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"username"}
            name={"username"}
            value={input.username}
            children={"Username"}
            placeholder="Enter Username "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, username: e.target.value })}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"email"}
            name={"email"}
            value={input.email}
            children={"Email"}
            placeholder="Enter email"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, email: e.target.value })}
          />
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            type={"number"}
            id={"phoneNumber"}
            name={"phoneNumber"}
            value={input.phoneNumber}
            children={"Phone number"}
            placeholder="Enter phone number "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) =>
              setInput({ ...input, phoneNumber: e.target.value })
            }
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4 flex flex-col">
          <label htmlFor="role" className="font-semibold ">
            Role
          </label>
          <select
            id="role"
            defaultValue="ADMIN"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, role: [e.target.value] })}
          >
            <option value="ADMIN" defaultValue={true}>
              Admin
            </option>
            <option value="PROJECT_MANAGER" defaultValue={true}>
              Project Manager
            </option>
            <option value="DESIGN_ENGINEER" defaultValue={true}>
              Design Engineer
            </option>
            <option value="BUYER" defaultValue={true}>
              Buyer
            </option>
          </select>
        </div>
      </div>
      <div className="flex justify-end my-7 mx-4">
        <Button
          buttonStyle={"btn--secondary"}
          onClick={() =>
            setInput({
              username: "",
              email: "",
              phoneNumber: "",
              fullName: "",
              role: ["ADMIN"],
            })
          }
          restClassName="w-16 mr-3"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          loading={loading}
          restClassName="w-16"
          spinStyle={{ marginTop: 10 }}
        >
          Create
        </Button>
      </div>
    </WrapperForm>
  );
}
