import React from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

export default function CardOverview({
  title,
  icon,
  totalNum,
  subtitle,
  primary = true,
  redirectTo = "/",
}) {
  return (
    <div className="h-auto p-4 rounded-md bg-[#FFFFFF] border border-solid border-[#E6E9F0] ">
      <div className="flex justify-between">
        <p className="text-secondary-blue text-xs">{title}</p>
        <Link to={redirectTo} className="text-xs">
          See Details
        </Link>
      </div>
      <div className="flex justify-start space-x-3">
        <div
          className={`${
            primary ? "bg-primary-blue" : "bg-secondary-blue"
          } h-11 w-11  p-2.5 rounded-lg`}
        >
          {/* <CalendarIcon className="h-6 w-6" color="white" /> */}
          <img src={icon} alt="" />
        </div>
        <div>
          <p className="font-bold">
            {totalNum} <br />
            <span className="font-normal  text-secondary-blue text-xs">
              {subtitle}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
