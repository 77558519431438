import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import calendarIc from "../../assets/images/calendar-svg.svg";
import cpuIc from "../../assets/images/cpu-svg.svg";
import paperIc from "../../assets/images/paper-svg.svg";
import imgDashboard from "../../assets/images/avatar-signin.svg";
import Layouts from "../../components/Layouts";
import {
  getCountComponent,
  getCountOpenProject,
  getProjectsCount,
} from "../../utils/https/Dashboard";
import CardOverview from "./CardOverview";
import CardRecentProjects from "./CardRecentProjects";
import { logout } from "../../redux/auth/authSlice";
import { message } from "antd";
import { getProjects } from "../../utils/https/Projects";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const [countProject, setCountProject] = useState(0);
  const [countOpenProject, setOpenCountProject] = useState(0);
  const [countComponent, setCountComponent] = useState(0);
  const [dataProjects, setDataProjects] = useState([]);

  function expiredToken() {
    {
      messageApi.open({
        type: "error",
        content: "Your login was expired. You will redirect to sign in page.",
        duration: 2,
      });
    }
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  useEffect(() => {
    getProjectsCount(authState?.token)
      .then(({ data }) => setCountProject(data.data))
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          expiredToken();
        }
      });

    getCountOpenProject(authState?.token)
      .then(({ data }) => setOpenCountProject(data.data))
      .catch((err) => {
        console.log(err);
      });

    getCountComponent(authState?.token)
      .then(({ data }) => setCountComponent(data.data))
      .catch((err) => {
        console.log(err);
      });
    const body = {
      pageNo: 1,
      totalElement: 3,
      sortField: "createdAt",
    };
    getProjects(authState?.token, body)
      .then(({ data }) => setDataProjects(data.dataListingResponse))
      .catch((err) => console.log(err));
  }, [authState.token]);

  const handleDetail = (id, item) => {
    navigate(`/projects/${id}`, {
      state: {
        item,
      },
    });
  };

  return (
    <Layouts>
      {contextHolder}
      <div className="bg-primary-blue h-auto p-16 pb-0 rounded-lg overflow-hidden">
        <div className="flex justify-between">
          <div className="w-8/12">
            <h5 className="text-white font-bold text-2xl">Welcome back!</h5>
            <p className="font-bold text-white text-lg">
              {authState.fullName} -{" "}
              <span className="font-normal ">{authState?.roles[0]}</span>
            </p>
            <p className="text-white">
              This is a software platform that helps you to create new projects,
              Built-of-Materials (BOM) with ease and managing it's stock
              inventory at the same time.
            </p>
          </div>
          <img src={imgDashboard} className="max-h-96 -mb-28" alt="" />
        </div>
      </div>
      <h5 className="text-primary-blue mt-8 font-bold text-xl">Overview</h5>
      <div className="flex items-center space-x-6">
        <div className="w-3/12 ">
          <CardOverview
            redirectTo={"/projects"}
            title={"Total Projects"}
            icon={calendarIc}
            totalNum={countProject}
            subtitle={"Projects"}
          />
        </div>
        <div className="w-3/12 ">
          <CardOverview
            redirectTo={"/projects"}
            title={"Open Project"}
            icon={calendarIc}
            totalNum={countOpenProject}
            subtitle={"Projects"}
            primary={false}
          />
        </div>
        <div className="w-3/12 ">
          <CardOverview
            redirectTo={"/components"}
            title={"Total Components"}
            icon={cpuIc}
            totalNum={countComponent}
            subtitle={"Components"}
          />
        </div>
        <div className="w-3/12 ">
          <CardOverview
            redirectTo="/components"
            title={"Total BoM created"}
            icon={paperIc}
            totalNum={9}
            subtitle={"Bill of Materials"}
          />
        </div>
      </div>
      <h5 className="text-primary-blue mt-8 font-bold text-xl">
        Recent Projects
      </h5>
      <div className="flex items-center space-x-6">
        {dataProjects?.map((item, index) => {
          return (
            <div className="w-3/12" key={index}>
              <CardRecentProjects
                onClick={() => handleDetail(item.id, item)}
                title={item.name}
                description={item.description}
                // bomEntries={"20 BoM entries"}
                date={item.createdDate}
              />
            </div>
          );
        })}
      </div>
    </Layouts>
  );
}
