import React, { useRef, useState } from "react";
import DataTable from "react-data-table-component";

export default function AddSubComponent({
  dataSub,
  columns,
  expandedComponent,
  result,
  getSubData,
  restClassName,
}) {
  const inputRef = useRef();
  const [isFocus, setIsFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [namePart, setNamePart] = useState("");
  return (
    <>
      <div className="w-full ">
        {dataSub.length > 0 && (
          <DataTable
            expandableRows
            expandableRowsComponent={expandedComponent}
            columns={columns}
            data={dataSub}
            sortFunction={false}
          />
        )}
      </div>
      <input
        id={"namePart"}
        name={"namePart"}
        placeholder="Enter part number or name"
        className="border-2 border-gray-200 rounded p-3  my-5 ml-3"
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          if (!isHovered) {
            setIsFocus(false);
          }
        }}
        value={namePart}
        onChange={(e) => setNamePart(e.target.value)}
        ref={inputRef}
      />

      {isFocus && (
        <div
          className={`shadow-lg w-2/12 h-32 overflow-y-scroll ${restClassName}`}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          {result?.map((suggestion, idx) => {
            const isMatchPartNum =
              suggestion.manufacturerPartNumber
                .toLowerCase()
                .indexOf(namePart.toLowerCase()) > -1;
            const isMatchName =
              suggestion.name.toLowerCase().indexOf(namePart.toLowerCase()) >
              -1;
            return (
              <div key={idx}>
                {isMatchPartNum ? (
                  <div
                    className="p-3  hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      getSubData("", suggestion.manufacturerPartNumber);
                      setNamePart("");
                      inputRef.current.focus();
                    }}
                  >
                    {suggestion.manufacturerPartNumber}
                  </div>
                ) : (
                  isMatchName && (
                    <div
                      className="p-3 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        getSubData(suggestion.name, "");
                        setNamePart("");
                        inputRef.current.focus();
                      }}
                    >
                      {suggestion.name}
                    </div>
                  )
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
