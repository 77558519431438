let prod = {
  url: process.env.REACT_APP_URL_PRODUCTION,
};
let staging = {
  url: process.env.REACT_APP_URL_STAGING,
};

console.log(process.env.REACT_APP_URL_PRODUCTION);

export const config =
  //prod;
  window.location.host === "mrp.testservices.com.sg" ? prod : staging;
