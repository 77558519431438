import React from "react";
import TextDataInfo from "../../components/TextDataInfo";
import WrapperDataInfo from "../../components/WrapperDataInfo";

function CustomerInfo({ data, addressLine, otherContacts }) {
  // const authState = useSelector((state) => state?.user?.user?.data?.data);
  // const addressSplit = data.item.address.split(",");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [input, setInput] = useState({
  //   name: data.item.name,
  //   vendorCode: data.item.vendorCode,
  //   addressLine: addressSplit[0],
  //   city: addressSplit[1],
  //   country: addressSplit[2],
  //   postalCode: addressSplit[3],
  //   currency: data.item.currency,
  //   paymentTerms: data.item.paymentTerms,
  // });

  // console.log(data);

  // const openNotificationWithIcon = () => {
  //   notification["success"]({
  //     message: "Success",
  //     description: "Customer updated successfully.",
  //   });
  // };

  // const handleOnChange = (e) => {
  //   const { name, value } = e.target;
  //   setInput({ ...input, [name]: value });
  // };

  // const handleOk = () => {
  //   updateCustomers(authState?.token, input, data?.item?.id)
  //     .then(() => {
  //       openNotificationWithIcon();
  //       setIsModalOpen(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <WrapperDataInfo>
      <div className="flex space-x-3">
        <div className="w-6/12 flex ">
          <TextDataInfo children={"Payment Terms :"} data={data.paymentTerms} />
        </div>
        <div className="w-6/12 flex ">
          <TextDataInfo children={"Currency :"} data={data.currency} />
        </div>
      </div>
      <div className="flex space-x-3">
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Address Line :"} data={addressLine[0]} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"City :"} data={addressLine[1]} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Country :"} data={addressLine[2]} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Postal Code :"} data={addressLine[3]} />
        </div>
      </div>
      <p className="text-primary-black font-semibold text-lg mt-2">Contact</p>
      <div className="flex space-x-3 -mt-4">
        <div className="w-3/12 flex ">
          <TextDataInfo
            children={"Contact name :"}
            data={data.customerContact}
          />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Phone number :"} data={data.phoneNumber} />
        </div>
      </div>
      <p className="text-primary-black font-semibold text-lg mt-2">
        Other contacts
      </p>
      {data?.otherContacts?.length < 1 && "NA"}
      {data?.otherContacts?.map((contact, index) => (
        <div className="flex space-x-3  -mt-4" key={index}>
          <div className="w-3/12 flex mb-2">
            <TextDataInfo
              children={"Contact name :"}
              data={contact.contactName}
            />
          </div>
          <div className="w-3/12 flex ">
            <TextDataInfo
              children={"Phone number :"}
              data={contact.contactPhoneNumber}
            />
          </div>
        </div>
      ))}
      {/* <div className="flex  justify-end w-20">
        <Button children={"Edit"} onClick={() => setIsModalOpen(true)} />
      </div> */}

      {/* <Modal
        title="Edit Customer Info"
        open={modalOpen}
        onOk={handleOk}
        okText={"Save"}
        onCancel={handleCancel}
        width="800px"
      >
        <div className="flex space-x-2">
          <div className="w-6/12 space-y-5">
            <InputBox
              id={"name"}
              name="name"
              type={"text"}
              children={"Name"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.name}
            />
          </div>
          <div className="w-6/12 space-y-5">
            <InputBox
              id={"customerCode"}
              name="customerCode"
              type={"text"}
              children={"Customer Code"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.customerCode}
            />
          </div>
          <div className="w-6/12 space-y-5 ">
            <InputBox
              id={"currency"}
              name="currency"
              type={"text"}
              children={"Currency"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.currency}
              onChange={handleOnChange}
            />
            <InputBox
              id={"addressLine"}
              name="addressLine"
              type={"text"}
              children={"Address Line"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.addressLine}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-6/12 space-y-5">
            <InputBox
              id={"paymentTerms"}
              name="paymentTerms"
              type={"text"}
              children={"Payment Terms"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.paymentTerms}
              onChange={handleOnChange}
            />
            <InputBox
              id={"city"}
              name="city"
              type={"text"}
              children={"City"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.city}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-6/12 space-y-5">
            <InputBox
              id={"country"}
              name="country"
              type={"text"}
              children={"Country"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.country}
              onChange={handleOnChange}
            />
            <InputBox
              id={"postalCode"}
              name="postalCode"
              type={"text"}
              children={"Postal Code"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.postalCode}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </Modal> */}
    </WrapperDataInfo>
  );
}

export default CustomerInfo;
