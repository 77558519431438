import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  data: {},
  totalPage: 0,
  isLoading: false,
};

const filterInventorySlice = createSlice({
  name: "filterInventory",
  initialState,
  reducers: {
    getDataByFilter: (state, action) => {
      state.data = action.payload.dataListingResponse;
      state.totalPage = action.payload.totalPage;
    },
    clearInventoryFilter: (state, action) => {
      return initialState;
    },
  },
});

export const { getDataByFilter, clearInventoryFilter } =
  filterInventorySlice.actions;
export default filterInventorySlice.reducer;
