import React, { useEffect, useState } from "react";
import { EyeIcon } from "@heroicons/react/24/outline";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPurchasing } from "../../../utils/https/Purchasing";
import CreateNewPurchase from "./CreateNewPurchase";
import { Pagination, message } from "antd";
import { logout } from "../../../redux/auth/authSlice";
import Sort from "../../../components/Sort";

export default function PurchasingTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const state = useSelector((state) => state);
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");

  const sortBy = [
    { title: "Order Id", value: "orderId" },
    { title: "Status", value: "status" },
    { title: "Created At", value: "createdAt" },
  ];

  useEffect(() => {
    fetchPurchase(currentPage);
  }, [
    authState.token,
    state.user.updateData.updatedSuccess,
    currentPage,
    sortField,
  ]);

  const fetchPurchase = (page) => {
    const body = {
      pageNo: page,
      totalElement: 10,
    };
    const dataFilter =
      sortField !== "" ? Object.assign(body, { sortField: sortField }) : body;
    getPurchasing(authState.token, dataFilter)
      .then(({ data }) => {
        setData(data.dataListingResponse);
        setTotalPages(data.totalPage);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  // console.log(data);

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const columns = [
    {
      name: "PURCHASE ORDER",
      selector: (row) => row.orderId,
      sortable: true,
      wrap: true,
    },
    {
      name: "DATE",
      selector: (row) => row.createdAt.replace(".0", ""),
      wrap: true,
    },
    {
      name: "REQUESTED BY",
      selector: (row) => row.requestedBy,
      wrap: true,
    },
    {
      name: "DELIVERY DATE",
      selector: (row) => {
        return row.deliveryDate;
      },
      wrap: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      wrap: true,
    },
    {
      name: "AMOUNT",
      selector: (row) => "SGD " + row.cost,
      wrap: true,
    },
    {
      name: "",
      selector: (row, index) => {
        return (
          <EyeIcon
            className="h-5 w-5 cursor-pointer"
            color="#677AA3"
            onClick={() => handleDetail(row.purchaseId, row)}
          />
        );
      },
      sortable: true,
      width: "70px",
      wrap: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#E6E9F0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  // console.log(data);

  const handleDetail = (id, item) => {
    navigate(`/inventory/purchasing-request/${id}`, {
      state: {
        item,
      },
    });
  };

  const onChangePage = (pagination) => {
    setCurrentPage(pagination);
  };

  return (
    <>
      {contextHolder}
      <div className="flex justify-between h-12 items-center mt-6">
        <CreateNewPurchase />
        <Sort
          handleChange={(e) => setSortField(e.target.value)}
          dataSort={sortBy}
        />
      </div>
      <DataTable
        className="mt-5"
        columns={columns}
        data={data}
        // defaultSortFieldId={1}
        customStyles={customStyles}
        // selectableRows
      />
      <div className="flex justify-end my-2">
        <Pagination
          defaultCurrent={1}
          total={totalPages * 10}
          onChange={onChangePage}
          showSizeChanger={false}
        />
      </div>
    </>
  );
}
