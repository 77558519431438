import React, { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Modal, notification } from "antd";
import InputBox from "../../components/Input/Input";
import TextDataInfo from "../../components/TextDataInfo";
import Button from "../../components/Button/Button";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { updateStock } from "../../utils/https/Inventory";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../redux/updateData/updateDataSlice";

export default function IncreaseStock({ handleClose, showModal, item }) {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [stock, setStock] = useState(0);
  const [description, setDescription] = useState("");

  useEffect(() => {
    setStock(item?.stock);
  }, [item?.stock]);

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Success",
      description: "Stock updated.",
    });
  };

  const onSave = () => {
    const body = {
      componentId: item.id,
      amount: stock,
      remark: description,
    };

    updateStock(authState?.token, body)
      .then(({ data }) => {
        dispatch(updateData(data.data));
        setDescription("");
        openNotificationWithIcon();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      title="Stock adjustment"
      open={showModal}
      onCancel={handleClose}
      width="893px"
      footer={[
        <div className="space-x-2" key={"jkjwiq"}>
          <Button
            children={"Cancel"}
            style={{ width: 65 }}
            buttonStyle={"btn--secondary"}
            onClick={handleClose}
          />
          <Button children={"Save"} style={{ width: 96 }} onClick={onSave} />
        </div>,
      ]}
    >
      <div className="flex justify-between">
        <TextDataInfo children={"Comment :"} data={item.name} />
        <TextDataInfo children={"Description :"} data={item.description} />
        <TextDataInfo children={"Manufacturer :"} data={item.manufacturer} />
        <TextDataInfo
          children={"Manufacturer Number :"}
          data={item.manufacturerPartNumber}
        />
      </div>
      <div className="flex space-x-3">
        <div className="w-full">
          <p className="font-semibold">Quantity</p>
          <div className="flex  h-10  border-2 border-gray-200 justify-between   items-center px-3 py-4  rounded">
            {stock > 0 ? (
              <MinusCircleIcon
                className="h-6 w-6 cursor-pointer"
                color="#677AA3"
                onClick={() => setStock(stock - 1)}
              />
            ) : (
              <MinusCircleIcon className="h-6 w-6 " color="#677AA3" />
            )}
            <InputBox
              id={"amount"}
              value={stock === undefined ? item.stock : stock}
              name={"amount"}
              className="w-full text-center placeholder:text-center placeholder:text-gray-400 focus:outline-none"
              placeholder={"Enter quantity"}
              type="number"
              onChange={(e) => setStock(e.target.value)}
            />
            <PlusCircleIcon
              className="h-6 w-6 cursor-pointer"
              color="#002E8C"
              onClick={() => setStock(stock + 1)}
            />
          </div>
        </div>
        <div className="w-full">
          <p className="font-semibold">Description</p>
          <div>
            <InputBox
              id={"remark"}
              name={"remark"}
              value={description}
              className="placeholder:text-gray-400 focus:outline-none border-2 border-gray-200 rounded px-3 py-4 h-10 "
              placeholder="Description of stock changes."
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
