import React, { useEffect, useState } from "react";
import { message } from "antd";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Layouts from "../../../components/Layouts";
import {
  closePurchasing,
  confirmPurchasing,
  getPurchaseListDetails,
} from "../../../utils/https/Purchasing";

export default function PurchasingDetails() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const { state } = useLocation();
  const [onHover, setOnHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchaseNotes, setPurchaseNotes] = useState("");
  const [status, setStatus] = useState(state.item.status);
  const [data, setData] = useState([]);

  useEffect(() => {
    getPurchaseListDetails(authState?.token, state.item.purchaseId)
      .then(({ data }) => {
        setData(data.data.purchaseComponentInformation);
        setPurchaseNotes(data.data.purchaseNotes);
      })
      .catch((err) => console.log(err));
  }, [authState?.token, state.item.purchaseId]);

  const columns = [
    {
      name: "COMMENT",
      selector: (row) => row.componentName,
      wrap: true,
    },
    {
      name: "MANUFACTURER",
      selector: (row) => row.manufacturer,
      wrap: true,
    },
    {
      name: "MPN",
      selector: (row) => row.manufacturerPartNumber,
      wrap: true,
    },
    {
      name: "VENDOR NAME",
      selector: (row) => row.vendorName,
      wrap: true,
    },
    {
      name: "VENDOR CODE",
      selector: (row) => row.vendorCode,
      wrap: true,
    },
    {
      name: "ORDER QUANTITY",
      selector: (row) => row.quantity,
      wrap: true,
    },
    {
      name: "UNIT COST",
      selector: (row) => {
        return "SGD " + row.unitCost;
      },
      wrap: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#E6E9F0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  // const handleHover = () => {
  //   setOnHover(!onHover);
  // };

  const handleConfirm = () => {
    const body = {
      purchaseId: state.item.purchaseId,
    };
    setLoading(true);
    confirmPurchasing(authState?.token, body)
      .then(({ data }) => {
        message.success(data.message);
        setStatus(data.data.status);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleClose = () => {
    const body = {
      purchaseId: state.item.purchaseId,
    };
    setLoading(true);
    closePurchasing(authState?.token, body)
      .then(({ data }) => {
        message.success(data.message);
        setStatus(data.data.status);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Layouts>
      <div className="flex  w-full">
        <div className="w-8/12 space-x-2">
          <Link to="/inventory">
            Inventory <span className="text-primary-black">/</span>
          </Link>
          <a className="cursor-text">
            Purchasing Request <span className="text-primary-black ">/</span>
          </a>
          <a className="text-primary-black hover:text-primary-black cursor-text">
            PO Number <span className="text-primary-black">/</span>
          </a>
        </div>
      </div>
      <div className="mt-8">
        <h5 className="font-semibold text-2xl">
          PO Number {state.item.orderId}
        </h5>
        <p>
          Request by :
          <span className="font-semibold"> {state.item.requestedBy}</span>
        </p>
        <p>
          Purchase notes :
          <span className="font-semibold"> {purchaseNotes}</span>
        </p>
        <p>
          Date Submited :
          <span className="font-semibold">
            {" "}
            {state.item.createdAt.replace(".0", "")}
          </span>
        </p>
      </div>
      {authState?.roles[0] !== "ADMIN" && (
        <div className="grid place-content-end">
          <Button
            children={status}
            className={"w-48 h-8 border border-[#002E8C]"}
            disabled
          />
        </div>
      )}
      <DataTable
        className="mt-5"
        columns={columns}
        data={data}
        customStyles={customStyles}
      />
      {authState?.roles[0] === "ADMIN" && (
        <div className="grid place-content-end">
          {status === "CLOSED" ? (
            <Button
              children={"Order Received"}
              restClassName="w-48 h-8"
              buttonStyle={"btn--disabled"}
              disabled
            />
          ) : status === "CONFIRMED" ? (
            <Button
              children={!onHover ? "Place Order" : "Order Received"}
              restClassName="w-48 h-8"
              className={
                !onHover
                  ? "w-48 h-8 border border-[#002E8C]"
                  : "w-48 h-8 text-white  bg-[#002E8C]"
              }
              onMouseEnter={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              loading={loading}
              onClick={handleClose}
            />
          ) : (
            <Button
              children={!onHover ? "Waiting Confirmation" : "Place Order"}
              className={
                !onHover
                  ? "w-48 h-8 border border-[#002E8C]"
                  : "w-48 h-8 text-white  bg-[#002E8C]"
              }
              onMouseEnter={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
              loading={loading}
              onClick={handleConfirm}
            />
          )}
        </div>
      )}
    </Layouts>
  );
}
