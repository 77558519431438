import React from "react";

export default function Sort({ dataSort, handleChange }) {
  return (
    <select
      id="sort-by"
      defaultValue=""
      className="border-2 border-gray-200 rounded px-3 py-2"
      onChange={handleChange}
    >
      <option value="" defaultValue={true}>
        Sort By
      </option>
      {dataSort.map((item, index) => (
        <option key={index} value={item.value} defaultValue={true}>
          {item.title}
        </option>
      ))}
    </select>
  );
}
