import React, { useState } from "react";
import {
  InformationCircleIcon,
  DocumentTextIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import Tab from "../../components/Tab";
import ComponentInfo from "./ComponentInfo";

export default function ComponentData({ dataDisplay }) {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div className="flex space-x-4">
      <div className="w-60 mt-8 ">
        <Tab
          titleTab={"Component Info"}
          iconTab={<InformationCircleIcon className="h-5 w-5" />}
          onClick={() => activeTab !== 1 && setActiveTab(1)}
          activeTab={activeTab === 1 && "bg-gray-200"}
          optionStyle={"justify-between flex-row-reverse"}
        />
        <Tab
          titleTab={"Image & Files"}
          iconTab={<DocumentTextIcon className="h-5 w-5" />}
          onClick={() => activeTab !== 2 && setActiveTab(2)}
          activeTab={activeTab === 2 && "bg-gray-200"}
          optionStyle={"justify-between flex-row-reverse"}
        />
        <Tab
          titleTab={"Other Action"}
          iconTab={<PencilIcon className="h-5 w-5" />}
          onClick={() => activeTab !== 3 && setActiveTab(3)}
          activeTab={activeTab === 3 && "bg-gray-200"}
          optionStyle={"justify-between flex-row-reverse"}
        />
      </div>
      <div className="w-full mt-8">
        {activeTab === 1 && <ComponentInfo data={dataDisplay} />}
      </div>
    </div>
  );
}
