import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import imgProject from "../../assets/images/img-project.png";
import {
  InformationCircleIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import Tab from "../../components/Tab";
import { Link, useLocation } from "react-router-dom";
import { message, Modal, notification, Select } from "antd";
import InputBox from "../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import DetailInfo from "../../components/DetailInfo";
import ComponentData from "./ComponentData";
import { updateComponent } from "../../utils/https/Components";
import ComponentSub from "./ComponentSub";
import Button from "../../components/Button/Button";
import { clearStateUpdate } from "../../redux/updateData/updateDataSlice";
import { logout } from "../../redux/auth/authSlice";
const { Option } = Select;

export default function ComponentDetails() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [activeTab, setActiveTab] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [dataDisplay, setDataDisplay] = useState({
    name: state.item.name,
    description: state.item.description,
    manufacturer: state.item.manufacturer,
    manufacturerPartNumber: state.item.manufacturerPartNumber,
    productCategory: state.item.productCategory,
    packageSize: state.item.packageSize,
    powerRating: state.item.powerRating,
    tolerance: state.item.tolerance,
    temperature: state.item.temperature,
    unitCost: state.item.unitCost,
  });
  const [input, setInput] = useState({
    name: state.item.name,
    description: state.item.description,
    manufacturer: state.item.manufacturer,
    manufacturerPartNumber: state.item.manufacturerPartNumber,
    productCategory: state.item.productCategory,
    packageSize: state.item.packageSize,
    powerRating: state.item.powerRating,
    tolerance: state.item.tolerance,
    temperature: state.item.temperature,
    unitCost: state.item.unitCost,
  });

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Success",
      description: "Component updated successfully.",
    });
  };

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleOnChangeNum = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: parseFloat(value) });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setLoading(true);
    updateComponent(authState?.token, state?.item?.id, input)
      .then(({ data }) => {
        setDataDisplay({
          name: data.data.name,
          description: data.data.description,
          manufacturer: data.data.manufacturer,
          manufacturerPartNumber: data.data.manufacturerPartNumber,
          packageSize: data.data.packageSize,
          powerRating: data.data.powerRating,
          productCategory: data.data.productCategory,
          temperature: data.data.temperature,
          tolerance: data.data.tolerance,
          unitCost: data.data.unitCost,
        });
        setLoading(false);
        openNotificationWithIcon();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  return (
    <Layouts>
      {contextHolder}
      <div className="flex  w-full">
        <div className="w-8/12 space-x-2">
          <Link to="/components">
            All Components <span className="text-primary-black">/</span>
          </Link>
          <a className="cursor-text">
            Component Detail <span className="text-primary-black">/</span>
          </a>
          <a className="text-primary-black hover:text-primary-black cursor-text ">
            {input?.name} <span className="text-primary-black">/</span>
          </a>
        </div>
      </div>
      <div className="flex items-center mt-6">
        <div className="w-11/12 flex items-center space-x-2">
          <DetailInfo
            img={imgProject}
            name={dataDisplay.name}
            description={dataDisplay.description}
          />
        </div>
        <Button
          children={"Edit component"}
          restClassName="w-40"
          onClick={() => {
            setIsModalOpen(true);
            dispatch(clearStateUpdate());
          }}
        />
      </div>
      <div className="flex  items-center bg-[#F9F9FB] -mr-16  mt-4">
        <Tab
          optionStyle={"xl:w-3/12"}
          titleTab={"Component Data"}
          iconTab={<InformationCircleIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 1 && "bg-gray-200"}
          onClick={() => activeTab !== 1 && setActiveTab(1)}
        />
        {state.item.componentLevel !== "RAW_PART" && (
          <Tab
            optionStyle={"xl:w-2/12"}
            titleTab={"Sub-part"}
            iconTab={<NewspaperIcon className="h-6 w-6 mr-2" />}
            activeTab={activeTab === 2 && "bg-gray-200"}
            onClick={() => activeTab !== 2 && setActiveTab(2)}
          />
        )}
      </div>
      {activeTab === 1 && <ComponentData dataDisplay={dataDisplay} />}
      {activeTab === 2 && <ComponentSub item={state} />}

      <Modal
        title="Edit Component"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <div className="flex space-x-3 justify-end">
            <Button
              children={"Cancel"}
              restClassName="w-16"
              buttonStyle={"btn--secondary"}
              onClick={handleCancel}
            />
            <Button
              children={"Save"}
              loading={loading}
              spinStyle={{ marginTop: 10 }}
              restClassName="w-16"
              onClick={handleOk}
            />
          </div>
        }
        width="1000px"
        className="font-Inter"
      >
        <div className="flex space-x-2">
          <div className="w-6/12 space-y-3">
            <InputBox
              id={"name"}
              name="name"
              type={"text"}
              children={"Name"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.name}
            />
            <InputBox
              id={"productCategory"}
              name="productCategory"
              type={"text"}
              children={"Product Category"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.productCategory}
              onChange={handleOnChange}
            />
            <InputBox
              step={0.1}
              id={"powerRating"}
              name="powerRating"
              type={"number"}
              children={"Power Rating"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.powerRating}
              onChange={handleOnChangeNum}
            />
          </div>
          <div className="w-6/12 space-y-3">
            <InputBox
              id={"description"}
              name="description"
              type={"text"}
              children={"Description"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.description}
            />
            <InputBox
              step={0.1}
              id={"unitCost"}
              name="unitCost"
              type={"number"}
              children={"Unit Cost"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.unitCost}
              onChange={handleOnChangeNum}
            />
            <InputBox
              step={0.1}
              id={"tolerance"}
              name="tolerance"
              type={"number"}
              children={"Tolerance"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.tolerance}
              onChange={handleOnChangeNum}
            />
          </div>
          <div className="w-6/12 space-y-3">
            <InputBox
              id={"manufacturer"}
              name="manufacturer"
              type={"text"}
              children={"Manufacturer"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.manufacturer}
              onChange={handleOnChange}
            />
            <InputBox
              step={0.1}
              id={"temperature"}
              name="temperature"
              type={"number"}
              children={"Temperature"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.temperature}
              onChange={handleOnChangeNum}
            />
          </div>
          <div className="w-6/12 space-y-3">
            <InputBox
              id={"manufacturerPartNumber"}
              name="manufacturerPartNumber"
              type={"text"}
              children={"Manufacturer PN"}
              className="border border-gray-200 rounded p-1 mt-2 disabled:opacity-75"
              value={input.manufacturerPartNumber}
              onChange={handleOnChange}
              disabled
            />
            <InputBox
              //step={0.1}
              id={"packageSize"}
              name="packageSize"
              type={"text"}
              children={"Package Size"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.packageSize}
              onChange={handleOnChange}
              //onChange={handleOnChangeNum}
            />
          </div>
        </div>
      </Modal>
    </Layouts>
  );
}
