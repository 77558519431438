import React, { useEffect, useRef, useState } from "react";
import Container from "../../components/Container";
import avatarSignIn from "../../assets/images/avatar-signin.svg";
import logoMrp from "../../assets/images/mrp-logo.png";
import Button from "../../components/Button/Button";
import { UserIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { clearState, loginUser } from "../../redux/auth/authSlice";
import { message } from "antd";
import "antd/dist/antd.css";
import InputBox from "../../components/Input/Input";
import { Link } from "react-router-dom";
import LayoutAuth from "./LayoutAuth";

export default function SignIn() {
  const dispatch = useDispatch();
  const [input, setInput] = useState({ username: "", password: "" });
  const state = useSelector((state) => state.user.user);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.username === "") return message.error("Please input username!");
    if (input.password === "") return message.error("Please input password!");

    dispatch(loginUser(input));
  };

  const ref = useRef(true);
  useEffect(() => {
    if (ref.current) {
      dispatch(clearState());
      ref.current = false;
      return;
    }
    if (state?.data?.error === "Unauthorized")
      message.error("Sign in failed wrong email or password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <LayoutAuth>
      <div className="flex justify-center">
        <img src={logoMrp} style={{ maxWidth: 210 }} alt="mrp-logo" />
      </div>
      <div className="flex justify-center">
        <h5 className="font-bold text-2xl text-primary-blue mt-8">
          Hello, please sign in.
        </h5>
      </div>
      <div className="flex justify-center mt-4">
        <p className="text-secondary-blue text-center ">
          Welcome to{" "}
          <span className="font-bold">Test Service MRP Platform</span>, sign in
          using <br /> username & password we give to you.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-start bg-[#FFFFFF] rounded p-1 w-4/6 mx-auto mt-6">
          <div className="w-1/12 flex items-center">
            <UserIcon className="h-6 w-6" color="#99A7C1" />
          </div>

          {/* <input
                type="text"
                className="bg-[#FFFFFF] rounded p-2 w-full placeholder:text-primary-blue focus:outline-none"
                placeholder="Enter your email"
                onChange={(e) => setUsername(e.target.value)}
              /> */}
          <div className="w-full">
            <InputBox
              id={"username"}
              value={input.username}
              name="username"
              className="bg-[#FFFFFF] rounded p-2  placeholder:text-primary-blue focus:outline-none"
              // style={{ width: "100%" }}
              placeholder="Enter your email"
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="flex justify-start bg-[#FFFFFF] rounded p-1 w-4/6 mx-auto mt-6">
          <div className="w-1/12 flex items-center">
            <LockClosedIcon className="h-6 w-6" color="#99A7C1" />
          </div>

          <div className="w-full">
            <InputBox
              type="password"
              className="bg-[#FFFFFF] rounded p-2 w-full placeholder:text-primary-blue focus:outline-none"
              id={"password"}
              value={input.password}
              name="password"
              placeholder="Enter your password"
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="flex justify-end w-4/6 mx-auto mt-3">
          <Link to="/forget-password" className="text-center">
            Forgot password?
          </Link>
        </div>
        <div className="flex justify-center w-4/6 mx-auto  mt-4">
          <Button
            children={"Login"}
            type="submit"
            restClassName={"w-full"}
            loading={state.isLoading}
          />
        </div>
      </form>
    </LayoutAuth>
  );
}
