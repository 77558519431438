import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import imgProject from "../../assets/images/img-project.png";
import {
  InformationCircleIcon,
  DocumentTextIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import Tab from "../../components/Tab";
import { Modal, message, notification } from "antd";
import InputBox from "../../components/Input/Input";
import { updateVendor } from "../../utils/https/Vendors";
import { useDispatch, useSelector } from "react-redux";
import DetailInfo from "../../components/DetailInfo";
import { updateCustomers } from "../../utils/https/Customers";
import CustomerInfo from "./CustomerInfo";
import Button from "../../components/Button/Button";
import {
  clearStateUpdate,
  updateData,
} from "../../redux/updateData/updateDataSlice";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import { logout } from "../../redux/auth/authSlice";

export default function CustomerDetails() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressLine, setAddressLine] = useState(state.item.address.split(","));
  const [otherContacts, setOtherContacts] = useState(state.item.otherContacts);
  const [dataDisplay, setDataDisplay] = useState({
    name: state.item.name,
    customerCode: state.item.customerCode,
    addressLine: addressLine[0],
    city: addressLine[1],
    country: addressLine[2],
    postalCode: addressLine[3],
    currency: state.item.currency,
    paymentTerms: state.item.paymentTerms,
    customerContact: state.item.customerContact,
    phoneNumber: state.item.phoneNumber,
    otherContacts: state.item.otherContacts,
  });
  const [input, setInput] = useState({
    name: state.item.name,
    customerCode: state.item.customerCode,
    addressLine: addressLine[0],
    city: addressLine[1],
    country: addressLine[2],
    postalCode: addressLine[3],
    currency: state.item.currency,
    paymentTerms: state.item.paymentTerms,
    customerContact: state.item.customerContact,
    phoneNumber: state.item.phoneNumber,
  });

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const paymentOptions = [
    {
      id: 1,
      value: "Payment in Advance",
    },
    {
      id: 2,
      value: "Cash in Advance",
    },
    {
      id: 3,
      value: "Cash on Delivery",
    },
    {
      id: 4,
      value: "Net d Days",
    },
    {
      id: 5,
      value: "Net end of Month",
    },
  ];

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Success",
      description: "Customer updated successfully.",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    const inputBody = { ...input, otherContacts: [...otherContacts] };
    setLoading(true);
    updateCustomers(authState?.token, inputBody, state?.item?.id)
      .then(({ data }) => {
        openNotificationWithIcon();
        setIsModalOpen(false);
        setLoading(false);
        setAddressLine(data.data.address.split(","));
        setDataDisplay({
          name: data.data.name,
          customerCode: data.data.customerCode,
          currency: data.data.currency,
          paymentTerms: data.data.paymentTerms,
          customerContact: data.data.customerContact,
          phoneNumber: data.data.phoneNumber,
          otherContacts: data.data.otherContacts,
        });
        dispatch(updateData());
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleOnChangeOtherContacts = (e, index) => {
    const { name, value } = e.target;
    const list = [...otherContacts];
    list[index][name] = value;
    setOtherContacts(list);
  };

  const handleOtherContacts = () => {
    setOtherContacts([
      ...otherContacts,
      { contactName: "", contactPhoneNumber: "" },
    ]);
  };

  const handleOtherContactRemove = (index) => {
    const list = [...otherContacts];
    list.splice(index, 1);
    setOtherContacts(list);
  };

  return (
    <Layouts>
      {contextHolder}
      <div className="w-full flex items-center justify-between">
        <div className="w-8/12 flex items-center space-x-2">
          <DetailInfo
            img={imgProject}
            name={dataDisplay.name}
            description={dataDisplay.customerCode}
          />
        </div>
        <Button
          children={"Edit customer details"}
          restClassName="w-40"
          onClick={() => {
            setIsModalOpen(true);
            dispatch(clearStateUpdate());
          }}
        />
      </div>
      <div className="flex space-x-4">
        <div className="w-60 mt-8 ">
          <Tab
            titleTab={"Customer Info"}
            iconTab={<InformationCircleIcon className="h-5 w-5" />}
            onClick={() => activeTab !== 1 && setActiveTab(1)}
            activeTab={activeTab === 1 && "bg-gray-200"}
            optionStyle={"justify-between flex-row-reverse"}
          />
          <Tab
            titleTab={"Image & Files"}
            iconTab={<DocumentTextIcon className="h-5 w-5" />}
            onClick={() => activeTab !== 2 && setActiveTab(2)}
            activeTab={activeTab === 2 && "bg-gray-200"}
            optionStyle={"justify-between flex-row-reverse"}
          />
          <Tab
            titleTab={"Other Action"}
            iconTab={<PencilIcon className="h-5 w-5" />}
            onClick={() => activeTab !== 3 && setActiveTab(3)}
            activeTab={activeTab === 3 && "bg-gray-200"}
            optionStyle={"justify-between flex-row-reverse"}
          />
        </div>
        <div className="w-full mt-8">
          {activeTab === 1 && (
            <CustomerInfo
              data={dataDisplay}
              otherContacts={dataDisplay.otherContacts}
              addressLine={addressLine}
            />
          )}
        </div>
      </div>

      <Modal
        title="Edit Customer Info"
        open={isModalOpen}
        onOk={handleOk}
        confirmLoading={loading}
        okText={"Save"}
        onCancel={handleCancel}
        width="1100px"
      >
        <div className="flex space-x-2">
          <div className="w-6/12 space-y-5">
            <InputBox
              id={"name"}
              name="name"
              type={"text"}
              children={"Name"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.name}
            />
            <InputBox
              id={"addressLine"}
              name="addressLine"
              type={"text"}
              children={"Address Line"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.addressLine}
              onChange={handleOnChange}
            />
            <p className="text-primary-black font-semibold text-lg mt-2 ">
              Contact
            </p>
            <div className="flex space-x-2">
              <InputBox
                id={"customerContact"}
                name="customerContact"
                type={"text"}
                children={"Customer contact"}
                className="border border-gray-200 rounded p-1 mt-2"
                value={input.customerContact}
                onChange={handleOnChange}
              />
              <InputBox
                id={"phoneNumber"}
                name="phoneNumber"
                type={"text"}
                children={"Phone number"}
                className="border border-gray-200 rounded p-1 mt-2"
                value={input.phoneNumber}
                onChange={handleOnChange}
              />
            </div>
            <p className="text-primary-black font-semibold text-lg mt-2 ">
              Other contacts
            </p>
            {otherContacts?.map((contact, index) => (
              <div className="flex space-x-2 items-center" key={index}>
                <InputBox
                  id={"contactName"}
                  name="contactName"
                  type={"text"}
                  children={"Customer contact"}
                  className="border border-gray-200 rounded p-1 mt-2"
                  value={contact.contactName}
                  onChange={(e) => handleOnChangeOtherContacts(e, index)}
                />
                <InputBox
                  id={"contactPhoneNumber"}
                  name="contactPhoneNumber"
                  type={"text"}
                  children={"Phone number"}
                  className="border border-gray-200 rounded p-1 mt-2"
                  value={contact.contactPhoneNumber}
                  onChange={(e) => handleOnChangeOtherContacts(e, index)}
                />
                {otherContacts.length > 1 && (
                  <TrashIcon
                    className="h-5 w-5 mt-7 cursor-pointer"
                    color="#F20000"
                    onClick={() => handleOtherContactRemove(index)}
                  />
                )}
              </div>
            ))}
            {otherContacts.length < 2 && (
              <div
                className="flex items-center  w-32 cursor-pointer "
                onClick={handleOtherContacts}
              >
                <PlusCircleIcon className="h-6 w-6 mr-2" color="#002E8C" />
                <p className="text-primary-blue mt-3">Add more</p>
              </div>
            )}
          </div>
          <div className="w-6/12 space-y-5">
            <InputBox
              id={"customerCode"}
              name="customerCode"
              type={"text"}
              children={"Customer Code"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.customerCode}
            />
            <InputBox
              id={"city"}
              name="city"
              type={"text"}
              children={"City"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.city}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-6/12 space-y-5 ">
            <InputBox
              id={"currency"}
              name="currency"
              type={"text"}
              children={"Currency"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.currency}
              onChange={handleOnChange}
            />
            <InputBox
              id={"country"}
              name="country"
              type={"text"}
              children={"Country"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.country}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-6/12 space-y-5">
            <div className="flex flex-col">
              <label htmlFor="paymentTerms" className="font-semibold ">
                Payment Terms
              </label>
              <select
                id="paymentTerms"
                defaultValue={input.paymentTerms}
                className="border-2 border-gray-200 rounded p-1 mt-2"
                onChange={(e) =>
                  setInput({ ...input, paymentTerms: e.target.value })
                }
              >
                {paymentOptions.map((item, idx) => (
                  <option value={item.value} key={idx}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="currency" className="font-semibold ">
                Currency
              </label>
              <select
                id="currency"
                defaultValue={input.currency}
                className="border-2 border-gray-200 rounded p-1 mt-2"
                onChange={(e) =>
                  setInput({ ...input, currency: e.target.value })
                }
              >
                <option value="SGD">SGD</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </div>
        </div>
      </Modal>
    </Layouts>
  );
}
