import axios from "axios";
import { config } from "../hosts";

export const getVendors = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-vendor`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const createVendor = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/create-vendor`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const updateVendor = (token, body, vendorId) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/update-vendor/${vendorId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const deleteVendor = (token, vendorId) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/delete-vendor/${vendorId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
