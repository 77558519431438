import React, { useState } from "react";
import { Modal, notification } from "antd";
import { useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import InputBox from "../../components/Input/Input";
import TextDataInfo from "../../components/TextDataInfo";
import WrapperDataInfo from "../../components/WrapperDataInfo";
import { updateVendor } from "../../utils/https/Vendors";

export default function VendorInfo({ vendorDataInfo }) {
  // const authState = useSelector((state) => state?.user?.user?.data?.data);
  // const addressSplit = vendorDataInfo.item.address.split(",");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [input, setInput] = useState({
  //   name: vendorDataInfo.item.name,
  //   vendorCode: vendorDataInfo.item.vendorCode,
  //   addressLine: addressSplit[0],
  //   city: addressSplit[1],
  //   country: addressSplit[2],
  //   postalCode: addressSplit[3],
  //   currency: vendorDataInfo.item.currency,
  //   paymentTerms: vendorDataInfo.item.paymentTerms,
  // });

  // const openNotificationWithIcon = () => {
  //   notification["success"]({
  //     message: "Success",
  //     description: "Vendor updated successfully.",
  //   });
  // };

  // const handleOnChange = (e) => {
  //   const { name, value } = e.target;
  //   setInput({ ...input, [name]: value });
  // };

  // const handleOk = () => {
  //   updateVendor(authState?.token, input, vendorDataInfo?.item?.id)
  //     .then((res) => {
  //       openNotificationWithIcon();
  //       setIsModalOpen(false);
  //       console.log(res);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  // console.log(vendorDataInfo);

  return (
    <WrapperDataInfo>
      <div className="flex space-x-3">
        <div className="w-6/12 flex ">
          <TextDataInfo
            children={"Payment Terms :"}
            data={vendorDataInfo.paymentTerms}
          />
        </div>
        <div className="w-6/12 flex ">
          <TextDataInfo
            children={"Currency :"}
            data={vendorDataInfo.currency}
          />
        </div>
      </div>
      <div className="flex space-x-3">
        <div className="w-3/12 flex ">
          <TextDataInfo
            children={"Address Line :"}
            data={vendorDataInfo.addressLine}
          />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"City :"} data={vendorDataInfo.city} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Country :"} data={vendorDataInfo.country} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo
            children={"Postal Code :"}
            data={vendorDataInfo.postalCode}
          />
        </div>
      </div>
      {/* <div className="flex  justify-end w-20">
        <Button children={"Edit"} onClick={() => setIsModalOpen(true)} />
      </div> */}

      {/* <Modal
        title="Edit Vendor Info"
        open={isModalOpen}
        onOk={handleOk}
        okText={"Save"}
        onCancel={handleCancel}
        width="800px"
      >
        <div className="flex space-x-2">
          <div className="w-6/12">
            <InputBox
              id={"currency"}
              name="currency"
              type={"text"}
              children={"Currency"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.currency}
              onChange={handleOnChange}
            />
            <InputBox
              id={"paymentTerms"}
              name="paymentTerms"
              type={"text"}
              children={"Payment Terms"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.paymentTerms}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-6/12">
            <InputBox
              id={"addressLine"}
              name="addressLine"
              type={"text"}
              children={"Address Line"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.addressLine}
              onChange={handleOnChange}
            />
            <InputBox
              id={"city"}
              name="city"
              type={"text"}
              children={"City"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.city}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-6/12">
            <InputBox
              id={"country"}
              name="country"
              type={"text"}
              children={"Country"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.country}
              onChange={handleOnChange}
            />
            <InputBox
              id={"postalCode"}
              name="postalCode"
              type={"text"}
              children={"Postal Code"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.postalCode}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </Modal> */}
    </WrapperDataInfo>
  );
}
