import React, { useEffect, useState } from "react";
import { Modal, notification, Table } from "antd";
import Button from "../../components/Button/Button";
import { draftBOM, submitDraftBOMRequest } from "../../utils/https/Projects";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { clearStateUpdate } from "../../redux/updateData/updateDataSlice";

export default function DraftBOM({ item }) {
  const dispatch = useDispatch();
  const updateState = useSelector((state) => state);
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const [showModalDraft, setShowModalDraft] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(item.item.status);
  const [data, setData] = useState([]);

  const handleShowModalDraft = () => {
    setShowModalDraft(true);
    dispatch(clearStateUpdate());
  };

  const handleCloseModalDraft = () => {
    setShowModalDraft(false);
  };

  const openNotification = () => {
    notification.open({
      placement: "top",
      description: (
        <div className="flex flex-col items-center">
          <CheckCircleIcon className="h-12 w-12" />
          <p>The purchase was successfully submitted.</p>
        </div>
      ),
      className: "custom-class text-white",
      style: {
        width: 340,
        backgroundColor: "#002E8C",
      },
    });
  };

  useEffect(() => {
    const body = {
      projectId: item.item.id,
    };
    draftBOM(authState?.token, body)
      .then(({ data }) => {
        setData(data.data);
      })
      .catch((err) => console.log(err));
  }, [authState?.token, updateState.user.updateData.updatedSuccess]);

  const columns = [
    {
      title: "COMMENTS",
      dataIndex: "componentName",
      key: "componentName",
      wrap: true,
    },
    {
      title: "PART NUMBER",
      dataIndex: "manufacturerPartNumber",
      key: "manufacturerPartNumber",
      wrap: true,
    },
    {
      title: "REQUIRED QUANTITY",
      dataIndex: "requiredStock",
      key: "requiredStock",
      wrap: true,
    },
    {
      title: "AVAILABLE STOCK",
      dataIndex: "availableStock",
      key: "availableStock",
      wrap: true,
    },
  ];

  const handleSubmit = () => {
    const body = {
      projectId: item.item.id,
    };
    setLoading(true);

    submitDraftBOMRequest(authState?.token, body)
      .then(() => {
        setLoading(false);
        openNotification();
        setShowModalDraft(false);
        setStatus("SUBMITTED");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        children={
          <div className="flex items-center justify-center">
            <ClipboardDocumentCheckIcon className="w-5 h-5 mr-1" /> Draft BoM
          </div>
        }
        onClick={handleShowModalDraft}
        buttonStyle={
          status === "SUBMITTED" ? "btn--disabled" : "btn--primary--outline"
        }
        disabled={status === "SUBMITTED" ? true : false}
        restClassName="w-32 h-10 justify-self-right"
      />
      <Modal
        open={showModalDraft}
        footer={
          <div className="flex w- space-x-3 justify-end">
            <Button
              children={"Cancel"}
              restClassName="w-16"
              buttonStyle={"btn--secondary"}
              onClick={handleCloseModalDraft}
            />
            <Button
              children={"Submit Request"}
              loading={loading}
              restClassName="w-40"
              onClick={handleSubmit}
            />
          </div>
        }
        width="900px"
        className="font-Inter"
        bodyStyle={{ padding: "0px" }}
        closable={false}
      >
        <div className="w-full">
          <div className="bg-[#002E8C] p-4  text-white">Project Info</div>
          <div className="bg-[#FFFFFF] p-4">
            {/* It looks like the system has detected insufficient stock on certain
          components, the list of items below requires a stock increase. */}
            <div className="p-4">
              <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
              />
            </div>
            After you submit the request, system will notify{" "}
            <span className="font-bold">[ADMIN]</span> to puchase the items.
          </div>
        </div>
      </Modal>
    </>
  );
}
