import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getBOM } from "../../utils/https/Projects";

export default function ProjectInfo({ projectDataInfo }) {
  const { state } = useLocation();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const [bomEntries, setBomEntries] = useState(0);
  useEffect(() => {
    getBOM(authState?.token, state.item.id)
      .then(({ data }) => {
        setBomEntries(data);
      })
      .catch((err) => console.log(err));
  }, [authState?.token, state.item.id]);
  return (
    <div className="border-2 border-gray-200 h-auto py-4 px-8 rounded-lg">
      <div className="flex justify-between">
        <p>
          <span className="font-bold text-secondary-blue">BOM Entries :</span>
          <br />
          {bomEntries?.length}
        </p>
        <p>
          <span className="font-bold text-secondary-blue">Last modified :</span>
          <br />
          {projectDataInfo?.modifiedAt?.replace(".0", "")}
        </p>
        <p>
          <span className="font-bold text-secondary-blue">Created :</span>
          <br />
          {projectDataInfo?.createdDate?.replace(".0", "")}
        </p>
        <p>
          <span className="font-bold text-secondary-blue">Job number :</span>
          <br />
          {projectDataInfo?.jobNumber}
        </p>
      </div>
    </div>
  );
}
