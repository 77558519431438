import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSubComponents } from "../../utils/https/Components";
// import Pagination from "../../components/Pagination";
import CreateAddSub from "./CreateAddSub";

export default function ComponentSub({ item }) {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const updateState = useSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(7);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenParts, setIsModalOpenParts] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState([]);

  const columns = [
    {
      title: "NAME",
      dataIndex: "componentName",
      key: "componentName",
      wrap: true,
    },

    {
      title: "MPN",
      dataIndex: "manufacturerPartNumber",
      key: "manufacturerPartNumber",
      wrap: true,
    },
    {
      title: "MANUFACTURER",
      dataIndex: "manufacturer",
      key: "manufacturer",
      wrap: true,
    },
    {
      title: "VENDOR",
      dataIndex: "vendorName",
      key: "vendorName",
      wrap: true,
    },
    {
      title: "CATEGORY",
      dataIndex: "productCategory",
      key: "productCategory",
      wrap: true,
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
      wrap: true,
    },
    {
      title: "SIZE",
      dataIndex: "packageSize",
      key: "packageSize",
      wrap: true,
    },
    {
      title: "UNIT COST",
      dataIndex: "unitCost",
      key: "unitCost",
      wrap: true,
    },
    {
      title: "DESCRIPTION",
      dataIndex: "componentDescription",
      key: "componentDescription",
      wrap: true,
    },
    {
      title: "COMPONENT LEVEL",
      // dataIndex: "componentLevel",
      key: "componentLevel",
      wrap: true,
      render: (item) =>
        item.componentLevel === "TOP_LEVEL"
          ? "LEVEL 0"
          : item.componentLevel === "MAIN_COMPONENT"
          ? "LEVEL 1"
          : item.componentLevel === "SUB_ITEM"
          ? "LEVEL 2"
          : item.componentLevel === "RAW_PART"
          ? "LEVEL 3"
          : "",
    },
    // {
    //   title: "ACTION",
    //   key: "manufacturerPartNumber",
    //   fixed: "right",
    //   width: 100,
    //   render: (item) => (
    //     <div className="flex">
    //       <PencilSquareIcon
    //         className="h-4 w-4 cursor-pointer"
    //         color="#677AA3"
    //         onClick={() => {
    //           // handleShowModalEdit();
    //           setDataEdit(item);
    //         }}
    //       />
    //       <TrashIcon
    //         className="h-4 w-4 cursor-pointer"
    //         color="#677AA3"
    //         onClick={() => {
    //           // handleShowModalEdit();
    //           setDataEdit(item);
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    getSubComponents(authState?.token, item.item.id)
      .then(({ data }) => {
        setData(data);
        // console.log(data);
      })
      .catch((err) => console.log(err));
  }, [
    authState?.token,
    item.item.id,
    isModalOpen,
    isModalOpenParts,
    updateState.user.updateData.updatedSuccess,
  ]);

  const lastPostsIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostsIndex - postsPerPage;
  // const currentPosts = data.slice(firstPostIndex, lastPostsIndex);

  return (
    <>
      <CreateAddSub item={item} currentData={data} />
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1500 }}
        className="mt-12"
        size="small"
        pagination={false}
      />
    </>
  );
}
