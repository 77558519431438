import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import DataTable from "react-data-table-component";
import GenerateReport from "./GenerateReport";
import { EyeIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export default function Reports() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "JOB NUMBER",
      selector: (row) => row.jobNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "PROJECT NAME",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
    },
    {
      name: "CREATED",
      selector: (row) => {
        return row?.createdDate?.replace(".0", "");
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "LAST UPDATED",
      selector: (row) => row?.modifiedAt?.replace(".0", ""),
      sortable: true,
      wrap: true,
    },
    {
      name: "",
      selector: (row) => {
        return (
          <EyeIcon
            className="h-5 w-5 cursor-pointer"
            color="#677AA3"
            onClick={() =>
              navigate(`/reports/${row.id}`, {
                state: {
                  row,
                },
              })
            }
          />
        );
      },
      sortable: true,
      width: "90px",
      wrap: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#E6E9F0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  // console.log(data.dataListingResponse);

  return (
    <Layouts>
      <h5 className="lg:text-2xl  text-lg text-primary-blue font-bold">
        Report
        <p className="lg:text-base text-sm text-primary-black font-normal">
          In this report module you can generate report based on what you
          selected.
        </p>
      </h5>
      <div className="flex mt-8">
        <GenerateReport handleSetData={setData} />
      </div>
      <DataTable
        className="mt-5"
        columns={columns}
        data={data.dataListingResponse}
        defaultSortFieldId={1}
        customStyles={customStyles}
        // selectableRows
      />
    </Layouts>
  );
}
