import React from "react";
import paperBlueIc from "../../assets/images/paper-blue-svg.svg";
import calendarBlueIc from "../../assets/images/calendar-blue-svg.svg";
import Button from "../../components/Button/Button";

export default function CardRecentProjects({
  title,
  description,
  bomEntries,
  date,
  onClick,
}) {
  return (
    <div className="h-auto p-4 rounded-md bg-[#FFFFFF] border border-solid border-[#E6E9F0]">
      <div className="w-12 ">
        <Button
          children={"Open"}
          onClick={onClick}
          buttonStyle={"btn--primary--outline"}
        />
      </div>
      <h5 className="font-bold my-2">{title}</h5>
      <p className="mb-6">{description}</p>
      {/* <div className="flex justify-start space-x-1">
        <img src={paperBlueIc} className="h-5 w-5" alt="" />
        <div>
          <p>{bomEntries}</p>
        </div>
      </div> */}
      <div className="flex justify-start space-x-1">
        <img src={calendarBlueIc} className="h-5 w-5" alt="" />
        <div>
          <p>{date}</p>
        </div>
      </div>
    </div>
  );
}
