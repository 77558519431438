import React, { useState } from "react";
import WrapperForm from "../../components/WrapperForm";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, message } from "antd";
import { logout } from "../../redux/auth/authSlice";
import { editProfile } from "../../utils/https/Profile";

export default function TabAccountDetail() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    fullName: authState?.fullName,
    username: authState?.username,
    email: authState?.email,
    phoneNumber: authState?.phoneNumber,
  });
  // console.log(authState);

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const handleSave = () => {
    if (input.fullName === "") {
      return message.error("Please enter Fullname!");
    }
    if (input.username === "") {
      return message.error("Please enter username!");
    }
    if (input.email === "") {
      return message.error("Please enter email!");
    }
    if (!input.email.includes("@")) {
      return message.error("Please enter a valid email!");
    }
    if (input.phoneNumber === "") {
      return message.error("Please enter phone number!");
    }
    setLoading(true);
    const body = {
      username: input.username === authState?.username ? null : input.username,
      email: input.email === authState?.email ? null : input.email,
      phoneNumber: input.phoneNumber,
      fullName: input.fullName,
    };

    editProfile(authState?.token, authState?.id, body)
      .then((res) => {
        message.success("User information successfully updated.");
        setLoading(false);
        dispatch(logout());
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };
  return (
    <WrapperForm title={"Account Detail"}>
      {contextHolder}
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"fullName"}
            name={"fullName"}
            value={input.fullName}
            children={"Full Name"}
            placeholder="Enter Full name "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, fullName: e.target.value })}
          />
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"username"}
            name={"username"}
            value={input.username}
            children={"Username"}
            placeholder="Enter Username "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, username: e.target.value })}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"email"}
            name={"email"}
            value={input.email}
            children={"Email"}
            placeholder="Enter email"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, email: e.target.value })}
          />
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"phoneNumber"}
            name={"phoneNumber"}
            value={input.phoneNumber}
            children={"Phone number"}
            placeholder="Enter phone number with your country code"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) =>
              setInput({ ...input, phoneNumber: e.target.value })
            }
          />
        </div>
      </div>
      <div className="flex justify-end my-7 mx-4">
        <Button
          buttonStyle={"btn--secondary"}
          onClick={() =>
            setInput({
              fullName: authState?.fullName,
              username: authState?.username,
              email: authState?.email,
              phoneNumber: authState?.phoneNumber,
            })
          }
          restClassName="w-16 mr-3"
        >
          Cancel
        </Button>
        <Popconfirm
          title="You will be automatically logout after this update, are you sure?"
          onConfirm={() => handleSave()}
          okText="Yes, Save"
          cancelText="No"
        >
          <Button
            loading={loading}
            restClassName="w-auto px-4"
            spinStyle={{ marginTop: 10 }}
          >
            Save changes
          </Button>
        </Popconfirm>
      </div>
    </WrapperForm>
  );
}
