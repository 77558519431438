import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import Button from "../../../components/Button/Button";
import InputBox from "../../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { getInventory } from "../../../utils/https/Inventory";
import {
  clearInventoryFilter,
  getDataByFilter,
} from "../../../redux/filterInventory/filterInventorySlice";

export default function StockCount({
  tabSubFilter,
  setTabSubFilter,
  pageNoCount = 1,
}) {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    stockFrom: "",
    stockTo: "",
  });
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  useEffect(() => {
    handleApply();
  }, [pageNoCount]);

  const handleApply = () => {
    const body = {
      pageNo: pageNoCount,
      totalElement: 10,
      stockFrom: input.stockFrom,
      stockTo: input.stockTo,
    };
    getInventory(authState?.token, body)
      .then(({ data }) => {
        // console.log(data);
        dispatch(getDataByFilter(data));
      })
      .catch((err) => console.log(err));
  };

  return (
    <Popover
      placement="bottom"
      content={
        <div>
          <div className="flex my-3 w-72 justify-between items-center">
            <InputBox
              name={"stockFrom"}
              id={"stockFrom"}
              type="number"
              className="border bg-white border-gray-300 w-28 rounded-lg py-2 px-4"
              placeholder="Min"
              value={input.stockFrom}
              onChange={handleOnChange}
            />
            <div>-</div>
            <InputBox
              name={"stockTo"}
              id={"stockTo"}
              type="number"
              className="border bg-white border-gray-300 w-28 rounded-lg py-2 px-4"
              placeholder="Max"
              value={input.stockTo}
              onChange={handleOnChange}
            />
          </div>
          <div className="flex justify-end  space-x-3">
            <Button
              children={"Reset"}
              style={{ width: 65 }}
              buttonStyle={"btn--secondary"}
              onClick={() => {
                dispatch(clearInventoryFilter());
                setInput({ stockFrom: "", stockTo: "" });
              }}
            />
            <Button
              children={"Apply filter"}
              style={{ width: 96 }}
              onClick={handleApply}
            />
          </div>
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <div
        className="cursor-pointer"
        onClick={() => tabSubFilter !== 2 && setTabSubFilter(2)}
      >
        <div className={`${tabSubFilter !== 2 && "text-secondary-blue"} pr-4`}>
          Stock count
        </div>
        <div
          className={`${
            tabSubFilter === 2 && "border-[#002E8C]"
          } border-b-2 mt-2`}
        ></div>
      </div>
    </Popover>
  );
}
