import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import InputBox from "../../components/Input/Input";
import WrapperForm from "../../components/WrapperForm";
import { editUserByAdmin } from "../../utils/https/Users";
import { logout } from "../../redux/auth/authSlice";

export default function UserDetails() {
  const navigate = useNavigate();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    username: state.item.username,
    email: state.item.email,
    phoneNumber: state.item.phoneNumber,
    fullName: state.item.fullName,
    role: state.item.role,
  });

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const handleSave = () => {
    if (input.fullName === "") {
      return message.error("Please enter Fullname!");
    }
    if (input.username === "") {
      return message.error("Please enter username!");
    }
    if (input.email === "") {
      return message.error("Please enter email!");
    }
    if (!input.email.includes("@")) {
      return message.error("Please enter a valid email!");
    }
    if (input.phoneNumber === "") {
      return message.error("Please enter phone number!");
    }
    if (input.role.length < 1) {
      return message.error("Please enter select a role!");
    }
    let username =
      input.username === state.item.username ? null : input.username;
    let role = input.role === state.item.role ? [state.item.role] : input.role;
    let email = input.email === state.item.email ? null : input.email;
    let fixInput = { ...input, username, role, email };
    setLoading(true);

    editUserByAdmin(authState?.token, state.item.id, fixInput)
      .then((res) => {
        message.success("Edit user successfully!");
        setLoading(false);
        navigate("/users");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  return (
    <Layouts>
      {contextHolder}
      <div className="flex  w-full">
        <div className="w-8/12 space-x-2">
          <Link to="/users">
            Users <span className="text-primary-black">/</span>
          </Link>
          <a className="cursor-text ">
            All users <span className="text-primary-black ">/</span>
          </a>
          <a className="text-primary-black cursor-text">User's detail</a>
        </div>
      </div>
      <WrapperForm title={"User Details"}>
        <div className="flex items-center">
          <div className="w-6/12 pt-8 px-4">
            <InputBox
              id={"fullName"}
              name={"fullName"}
              value={input.fullName}
              children={"Full Name"}
              placeholder="Enter Full name "
              className="border-2 border-gray-200 rounded p-3 mt-2"
              onChange={(e) => setInput({ ...input, fullName: e.target.value })}
            />
          </div>
          <div className="w-6/12 pt-8 px-4">
            <InputBox
              id={"username"}
              name={"username"}
              value={input.username}
              children={"Username"}
              placeholder="Enter Username "
              className="border-2 border-gray-200 rounded p-3 mt-2"
              onChange={(e) => setInput({ ...input, username: e.target.value })}
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-6/12 pt-8 px-4">
            <InputBox
              id={"email"}
              name={"email"}
              value={input.email}
              children={"Email"}
              placeholder="Enter email"
              className="border-2 border-gray-200 rounded p-3 mt-2"
              onChange={(e) => setInput({ ...input, email: e.target.value })}
            />
          </div>
          <div className="w-6/12 pt-8 px-4">
            <InputBox
              type={"text"}
              id={"phoneNumber"}
              name={"phoneNumber"}
              value={input.phoneNumber}
              children={"Phone number"}
              placeholder="Enter phone number with your country code"
              className="border-2 border-gray-200 rounded p-3 mt-2"
              onChange={(e) =>
                setInput({ ...input, phoneNumber: e.target.value })
              }
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-6/12 pt-8 px-4 flex flex-col">
            <label htmlFor="role" className="font-semibold ">
              Role
            </label>
            <select
              id="role"
              defaultValue={state.item.role}
              className="border-2 border-gray-200 rounded p-3 mt-2"
              onChange={(e) => setInput({ ...input, role: [e.target.value] })}
            >
              <option value="ADMIN" defaultValue={true}>
                Admin
              </option>
              <option value="PROJECT_MANAGER" defaultValue={true}>
                Project Manager
              </option>
              <option value="DESIGN_ENGINEER" defaultValue={true}>
                Design Engineer
              </option>
              <option value="BUYER" defaultValue={true}>
                Buyer
              </option>
            </select>
          </div>
        </div>
        <div className="flex justify-end my-7 mx-4">
          <Button
            buttonStyle={"btn--secondary"}
            onClick={() =>
              setInput({
                username: state.item.username,
                email: state.item.email,
                phoneNumber: state.item.phoneNumber,
                fullName: state.item.fullName,
                role: state.item.role,
              })
            }
            restClassName="w-16 mr-3"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            loading={loading}
            restClassName="w-auto px-4"
            spinStyle={{ marginTop: 10 }}
          >
            Save changes
          </Button>
        </div>
      </WrapperForm>
    </Layouts>
  );
}
