import axios from "axios";
import { config } from "../hosts";

export const getProjectsCount = (token) => {
  return axios.get(`${config.url}mrp-test-service/api/general/count-project`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });
};

export const getCountOpenProject = (token) => {
  return axios.get(
    `${config.url}mrp-test-service/api/general/count-open-project`,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const getCountComponent = (token) => {
  return axios.get(
    `${config.url}mrp-test-service/api/general/count-component`,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
