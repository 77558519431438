import React, { useState } from "react";
import { Modal, notification } from "antd";
import { useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import InputBox from "../../components/Input/Input";
import TextDataInfo from "../../components/TextDataInfo";
import WrapperDataInfo from "../../components/WrapperDataInfo";
import { updateVendor } from "../../utils/https/Vendors";
import { updateComponent } from "../../utils/https/Components";

export default function ComponentInfo({ data }) {
  // const authState = useSelector((state) => state?.user?.user?.data?.data);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [input, setInput] = useState({
  //   name: componentDataInfo.item.name,
  //   description: componentDataInfo.item.description,
  //   manufacturer: componentDataInfo.item.manufacturer,
  //   manufacturerPartNumber: componentDataInfo.item.manufacturerPartNumber,
  //   productCategory: componentDataInfo.item.productCategory,
  //   packageSize: componentDataInfo.item.packageSize,
  //   powerRating: componentDataInfo.item.powerRating,
  //   tolerance: componentDataInfo.item.tolerance,
  //   temperature: componentDataInfo.item.temperature,
  //   unitCost: componentDataInfo.item.unitCost,
  // });

  // const openNotificationWithIcon = () => {
  //   notification["success"]({
  //     message: "Success",
  //     description: "Component updated successfully.",
  //   });
  // };

  // const handleOnChange = (e) => {
  //   const { name, value } = e.target;
  //   setInput({ ...input, [name]: value });
  // };

  // const handleOk = () => {
  //   updateComponent(authState?.token, componentDataInfo?.item?.id, input)
  //     .then(() => {
  //       openNotificationWithIcon();
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <WrapperDataInfo>
      <div className="flex space-x-3">
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Manufacturer :"} data={data?.manufacturer} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo
            children={"Manufacturer PN :"}
            data={data?.manufacturerPartNumber}
          />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo
            children={"Product Category :"}
            data={data?.productCategory}
          />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Size :"} data={data?.packageSize} />
        </div>
      </div>
      <div className="flex space-x-3">
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Power Rating :"} data={data?.powerRating} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Tolerance :"} data={data?.tolerance} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Temperature :"} data={data?.temperature} />
        </div>
        <div className="w-3/12 flex ">
          <TextDataInfo children={"Unit Cost :"} data={data?.unitCost} />
        </div>
      </div>
      {/* <div className="flex  justify-end w-20">
        <Button children={"Edit"} onClick={() => setIsModalOpen(true)} />
      </div> */}

      {/* <Modal
        title="Edit Component"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="800px"
        className="font-Inter"
      >
        <div className="flex space-x-2">
          <div className="w-3/12 space-y-3">
            <InputBox
              id={"manufacturer"}
              name="manufacturer"
              type={"text"}
              children={"Manufacturer"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.manufacturer}
              onChange={handleOnChange}
            />
            <InputBox
              id={"manufacturerPartNumber"}
              name="manufacturerPartNumber"
              type={"text"}
              children={"Manufacturer PN"}
              className="border border-gray-200 rounded p-1 mt-2 disabled:opacity-75"
              value={input.manufacturerPartNumber}
              onChange={handleOnChange}
              disabled
            />
          </div>
          <div className="w-3/12 space-y-3">
            <InputBox
              id={"temperature"}
              name="temperature"
              type={"text"}
              children={"Temperature"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.temperature}
              onChange={handleOnChange}
            />
            <InputBox
              id={"unitCost"}
              name="unitCost"
              type={"text"}
              children={"Unit Cost"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.unitCost}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-3/12 space-y-3">
            <InputBox
              id={"productCategory"}
              name="productCategory"
              type={"text"}
              children={"Product Category"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.productCategory}
              onChange={handleOnChange}
            />
            <InputBox
              id={"packageSize"}
              name="packageSize"
              type={"text"}
              children={"Package Size"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.packageSize}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-3/12 space-y-3">
            <InputBox
              id={"powerRating"}
              name="powerRating"
              type={"text"}
              children={"Power Rating"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.powerRating}
              onChange={handleOnChange}
            />
            <InputBox
              id={"tolerance"}
              name="tolerance"
              type={"text"}
              children={"Tolerance"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.tolerance}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </Modal> */}
    </WrapperDataInfo>
  );
}
