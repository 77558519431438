import React, { useState } from "react";
import ManufacturerFilter from "./ManufacturerFilter";
import StockCount from "./StockCount";

export default function AddFilter({ pageNoCount }) {
  const [tabSubFilter, setTabSubFilter] = useState(0);

  return (
    <div className="flex font-semibold text-primary-blue bg-[#E6E9F0]  rounded-t-lg border-b border-gray-200  pt-4 justify-between ">
      <ManufacturerFilter
        tabSubFilter={tabSubFilter}
        setTabSubFilter={setTabSubFilter}
      />
      {/* <div
        className="cursor-pointer"
        onClick={() => tabSubFilter !== 2 && setTabSubFilter(2)}
      >
        <div className="pr-4 text-secondary-blue">Stock count</div>
        <div
          className={`${
            tabSubFilter === 2 && " border-[#002E8C]  "
          } border-b-2 mt-2`}
        ></div>
      </div> */}
      <StockCount
        pageNoCount={pageNoCount}
        tabSubFilter={tabSubFilter}
        setTabSubFilter={setTabSubFilter}
      />
    </div>
  );
}
