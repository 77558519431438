import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
// import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import { deleteBOM, getBOM } from "../../utils/https/Projects";
import Pagination from "../../components/Pagination";
import EditBoM from "./EditBoM";
import {
  clearStateUpdate,
  updateData,
} from "../../redux/updateData/updateDataSlice";
import CreateComponentParts from "./CreateComponentParts";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import CreateBOM from "./CreateBOM";
import DraftBOM from "./DraftBOM";

export default function ProjectBOM({ item }) {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const updateState = useSelector((state) => state);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(7);
  const [showModalEdit, setShowModalEdit] = useState(false);
  // const [showModalDraft, setShowModalDraft] = useState(false);
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState([]);

  const confirm = (id) => {
    deleteBOM(authState?.token, id)
      .then(() => {
        message.success("BOM deleted successfully.");
        setDeleteSuccess(!deleteSuccess);
      })
      .catch((err) => console.log(err));
  };
  const cancel = (e) => {
    console.log(e);
  };

  const columns = [
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      wrap: true,
      height: 30,
    },
    {
      title: "COMMENTS",
      dataIndex: "bomComment",
      key: "bomComment",
      wrap: true,
    },
    {
      title: "LEVEL",
      // dataIndex: "level",
      key: "componentLevel",
      filters: [
        {
          text: "LEVEL 0",
          value: "TOP_LEVEL",
        },
        {
          text: "LEVEL 1",
          value: "MAIN_COMPONENTS",
        },
        {
          text: "LEVEL 2",
          value: "SUB_ITEM",
        },
        {
          text: "LEVEL 3",
          value: "RAW_PART",
        },
      ],
      onFilter: (value, record) => record.level.indexOf(value) === 0,
      wrap: true,
      render: (item) => (
        <p>
          {item.componentLevel === "TOP_LEVEL"
            ? "LEVEL 0"
            : item.componentLevel === "MAIN_COMPONENTS"
            ? "LEVEL 1"
            : item.componentLevel === "SUB_ITEM"
            ? "LEVEL 2"
            : item.componentLevel === "RAW_PART"
            ? "LEVEL 3"
            : ""}
        </p>
      ),
    },
    {
      title: "MPN",
      dataIndex: "manufacturerPartNumber",
      key: "manufacturerPartNumber",
      wrap: true,
    },
    {
      title: "MANUFACTURER",
      dataIndex: "manufacturer",
      key: "manufacturer",
      wrap: true,
    },
    {
      title: "VENDOR CODE",
      dataIndex: "vendorCode",
      key: "vendorCode",
      wrap: true,
    },
    {
      title: "VENDOR NAME",
      dataIndex: "vendorName",
      key: "vendorName",
      wrap: true,
    },
    {
      title: "CATEGORY",
      dataIndex: "productCategory",
      key: "productCategory",
      wrap: true,
    },
    {
      title: "SIZE",
      dataIndex: "packageSize",
      key: "packageSize",
      wrap: true,
    },
    {
      title: "UNIT COST",
      dataIndex: "unitCost",
      key: "unitCost",
      wrap: true,
    },
    {
      title: "DESCRIPTION",
      dataIndex: "componentDescription",
      key: "componentDescription",
      wrap: true,
    },
    {
      title: "DESIGNATOR",
      dataIndex: "designator",
      key: "designator",
      wrap: true,
    },
    {
      title: "ACTION",
      key: "manufacturerPartNumber",
      fixed: "right",
      width: 100,
      render: (item) => (
        <div className="flex space-x-2">
          <PencilSquareIcon
            className="h-4 w-4 cursor-pointer"
            color="#677AA3"
            onClick={() => {
              handleShowModalEdit();
              setDataEdit(item);
            }}
          />
          <Popconfirm
            title="Are you sure to delete this BOM?"
            onConfirm={() => {
              confirm(item.id);
              dispatch(updateData());
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <TrashIcon className="h-4 w-4 cursor-pointer" color="#677AA3" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getBOM(authState?.token, item.item.id)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => console.log(err));
  }, [
    authState?.token,
    item.item.id,
    deleteSuccess,
    updateState.user.updateData.updatedSuccess,
  ]);

  // const handleShowModalDraft = () => {
  //   setShowModalDraft(true);
  //   dispatch(clearStateUpdate());
  // };
  const handleShowModalEdit = () => {
    setShowModalEdit(true);
    dispatch(clearStateUpdate());
  };
  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  };
  // const handleCloseModalDraft = () => {
  //   setShowModalDraft(false);
  // };

  const lastPostsIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostsIndex - postsPerPage;
  const currentPosts = data.slice(firstPostIndex, lastPostsIndex);

  return (
    <div className="mt-7">
      <div className=" flex ">
        <div className="w-10/12 space-x-2">
          <CreateBOM item={item} />
          <CreateComponentParts />
        </div>
        <div className="w-2/12">
          {/* <Button
            children={
              <div className="flex items-center justify-center">
                <ClipboardDocumentCheckIcon className="w-5 h-5 mr-1" /> Draft
                BoM
              </div>
            }
            onClick={handleShowModalDraft}
            buttonStyle={
              item.item.status === "SUBMITTED"
                ? "btn--disabled"
                : "btn--primary--outline"
            }
            disabled={item.item.status === "SUBMITTED" ? true : false}
            restClassName="w-32 h-10 justify-self-right"
          /> */}

          <DraftBOM
            // handleCloseModalDraft={handleCloseModalDraft}
            // showModalDraft={showModalDraft}
            item={item}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1500 }}
        className="mt-12"
        size="small"
        pagination={false}
      />
      {/* <Pagination
        totalPosts={data.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      /> */}
      <EditBoM
        handleCloseModalEdit={handleCloseModalEdit}
        item={dataEdit}
        showModalEdit={showModalEdit}
      />
    </div>
  );
}
