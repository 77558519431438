import axios from "axios";
import { config } from "../hosts";

export const changePassword = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/auth/change-password`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const editProfile = (token, userId, body) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/auth/update-profile/${userId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
