import React, { useEffect, useState } from "react";
import { Modal, Table, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputBox from "../../components/Input/Input";
import { updateBOM } from "../../utils/https/Projects";
import {
  getSubComponents,
  updateComponent,
} from "../../utils/https/Components";
import { updateData } from "../../redux/updateData/updateDataSlice";
import Button from "../../components/Button/Button";
import DataTable from "react-data-table-component";

export default function EditBoM({ handleCloseModalEdit, showModalEdit, item }) {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [input, setInput] = useState({
    quantity: "",
    bomComment: "",
    designator: "",
  });
  const [inputComponent, setInputComponent] = useState({
    name: "",
    description: "",
    manufacturer: "",
    manufacturerPartNumber: "",
    productCategory: "",
    packageSize: "",
    tolerance: "",
    temperature: "",
    unitCost: "",
    powerRating: "",
    vendorCode: "",
    vendorName: "",
  });

  const columns = [
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      wrap: true,
      height: 30,
    },
    {
      title: "COMPONENT NAME",
      dataIndex: "componentName",
      key: "componentName",
      wrap: true,
    },
    {
      title: "LEVEL",
      // dataIndex: "level",
      key: "level",
      filters: [
        {
          text: "LEVEL 0",
          value: "TOP_LEVEL",
        },
        {
          text: "LEVEL 1",
          value: "MAIN_COMPONENTS",
        },
        {
          text: "LEVEL 2",
          value: "SUB_ITEM",
        },
        {
          text: "LEVEL 3",
          value: "RAW_PART",
        },
      ],
      onFilter: (value, record) => record.level.indexOf(value) === 0,
      wrap: true,
      render: (item) => (
        <p>
          {item.componentLevel === "TOP_LEVEL"
            ? "LEVEL 0"
            : item.componentLevel === "MAIN_COMPONENTS"
            ? "LEVEL 1"
            : item.componentLevel === "SUB_ITEM"
            ? "LEVEL 2"
            : item.componentLevel === "RAW_PART"
            ? "LEVEL 3"
            : ""}
        </p>
      ),
    },
    {
      title: "MPN",
      dataIndex: "manufacturerPartNumber",
      key: "manufacturerPartNumber",
      wrap: true,
    },
    {
      title: "MANUFACTURER",
      dataIndex: "manufacturer",
      key: "manufacturer",
      wrap: true,
    },
    {
      title: "VENDOR NAME",
      dataIndex: "vendorName",
      key: "vendorName",
      wrap: true,
    },
    {
      title: "CATEGORY",
      dataIndex: "productCategory",
      key: "productCategory",
      wrap: true,
    },
    {
      title: "SIZE",
      dataIndex: "packageSize",
      key: "packageSize",
      wrap: true,
    },
    {
      title: "UNIT COST",
      dataIndex: "unitCost",
      key: "unitCost",
      wrap: true,
    },
    {
      title: "DESCRIPTION",
      dataIndex: "componentDescription",
      key: "componentDescription",
      wrap: true,
    },
  ];

  useEffect(() => {
    getSubComponents(authState?.token, item.componentId)
      .then(({ data }) => setData(data))
      .catch((err) => console.log(err));
  }, [authState?.token, item.componentId]);

  useEffect(() => {
    setIsModalOpen(showModalEdit);
    setInput({
      quantity: item.quantity,
      bomComment: item.bomComment,
      designator: item.designator,
    });

    setInputComponent({
      name: item.componentName,
      description: item.description,
      manufacturer: item.manufacturer,
      manufacturerPartNumber: item.manufacturerPartNumber,
      productCategory: item.productCategory,
      packageSize: item.packageSize,
      tolerance: item.tolerance,
      temperature: item.temperature,
      unitCost: item.unitCost,
      powerRating: item.powerRating,
      vendorCode: item.vendorCode,
      vendorName: item.vendorName,
    });
  }, [showModalEdit]);

  const handleOnChangeComponent = (e) => {
    const { name, value } = e.target;
    setInputComponent({ ...inputComponent, [name]: value });
  };

  const handleOnChangeFloat = (e) => {
    const { name, value } = e.target;
    setInputComponent({ ...inputComponent, [name]: parseFloat(value) });
  };

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Success",
      description: "BOM successfully updated.",
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleOk = () => {
    setLoading(true);
    updateComponent(authState?.token, item.componentId, inputComponent)
      .then(() => {
        // setIsModalOpen(false);
        updateBOM(authState?.token, item.id, input)
          .then(({ data }) => {
            // console.log(data);
            setLoading(false);
            dispatch(updateData(data.data));
            // setIsModalOpen(false);
            openNotificationWithIcon();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <Modal
      title="Component details"
      open={showModalEdit}
      onCancel={handleCloseModalEdit}
      footer={
        <div className="flex space-x-3 justify-end">
          <Button
            children={"Cancel"}
            restClassName="w-16"
            buttonStyle={"btn--secondary"}
            onClick={handleCloseModalEdit}
          />
          <Button
            children={"Save"}
            loading={loading}
            spinStyle={{ marginTop: 10 }}
            restClassName="w-16"
            onClick={handleOk}
          />
        </div>
      }
      width="900px"
      className="font-Inter"
    >
      <div className="flex space-x-2 ">
        <div className="w-6/12 space-y-3">
          <InputBox
            id={"bomComment"}
            name="bomComment"
            type={"text"}
            children={"Comments"}
            className="border border-gray-200 rounded p-1 mt-2"
            value={input.bomComment}
            onChange={handleOnChange}
          />
          <InputBox
            id={"designator"}
            name="designator"
            type={"text"}
            children={"Designator"}
            className="border border-gray-200 rounded p-1 mt-2"
            value={input.designator}
            onChange={handleOnChange}
          />
        </div>
        <div className="w-6/12 ">
          <InputBox
            id={"quantity"}
            name="quantity"
            type={"number"}
            children={"Quantity"}
            className="border border-gray-200 rounded p-1 mt-2"
            value={input.quantity}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <h5 className="text-lg mt-5 border-b border-gray-200">Component Info</h5>
      <div className="flex space-x-2 mt-2">
        <div className="w-6/12 flex flex-col ">
          <InputBox
            id={"name"}
            name={"name"}
            value={inputComponent.name}
            children={"Component's Name"}
            placeholder="Enter component's name "
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            onChange={handleOnChangeComponent}
            disabled
          />
        </div>
        <div className="w-6/12">
          <InputBox
            id={"description"}
            name={"description"}
            value={inputComponent.description}
            children={"Description "}
            placeholder="Description about this Component "
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            onChange={handleOnChangeComponent}
            disabled
          />
        </div>
      </div>
      <div className="flex space-x-2 mt-2">
        <div className="w-3/12">
          <InputBox
            id={"manufacturer"}
            name={"manufacturer"}
            value={inputComponent.manufacturer}
            children={"Manufacturer"}
            placeholder="Manufacturer"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            onChange={handleOnChangeComponent}
            disabled
          />
        </div>
        <div className="w-3/12">
          <InputBox
            id={"manufacturerPartNumber"}
            name={"manufacturerPartNumber"}
            value={inputComponent.manufacturerPartNumber}
            children={"Manufacturer PN"}
            placeholder="Parts Number"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            onChange={handleOnChangeComponent}
            disabled
          />
        </div>
        <div className="w-3/12">
          <InputBox
            id={"vendorCode"}
            name={"vendorCode"}
            value={inputComponent.vendorCode}
            children={"Vendor code"}
            placeholder="Vendor code"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            onChange={handleOnChangeComponent}
            disabled
          />
        </div>
        <div className="w-3/12">
          <InputBox
            id={"vendorName"}
            name={"vendorName"}
            value={inputComponent.vendorName}
            children={"Vendor name"}
            placeholder="Vendor name"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            onChange={handleOnChangeComponent}
            disabled
          />
        </div>
      </div>
      <div className="flex space-x-2 mt-2">
        <div className="w-3/12">
          <InputBox
            id={"productCategory"}
            name={"productCategory"}
            value={inputComponent.productCategory}
            children={"Product Category"}
            placeholder="Category"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            onChange={handleOnChangeComponent}
            disabled
          />
        </div>
        <div className="w-3/12">
          <InputBox
            step={0.1}
            id={"packageSize"}
            name={"packageSize"}
            value={inputComponent.packageSize}
            children={"Package Size"}
            placeholder="Size"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            type={"number"}
            onChange={handleOnChangeFloat}
            disabled
          />
        </div>
        <div className="w-3/12">
          <InputBox
            step={0.1}
            id={"powerRating"}
            name={"powerRating"}
            value={inputComponent.powerRating}
            children={"Power Rating"}
            placeholder="Power value"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            type={"number"}
            onChange={handleOnChangeFloat}
            disabled
          />
        </div>
        <div className="w-3/12">
          <InputBox
            step={0.1}
            id={"tolerance"}
            name={"tolerance"}
            value={inputComponent.tolerance}
            children={"Tolerance"}
            placeholder="Tolerance value"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            type={"number"}
            onChange={handleOnChangeFloat}
            disabled
          />
        </div>
      </div>
      <div className="flex space-x-2 mt-2">
        <div className="w-3/12">
          <InputBox
            step={0.1}
            id={"temperature"}
            name={"temperature"}
            value={inputComponent.temperature}
            children={"Temperature"}
            placeholder="Temperature value"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            type={"number"}
            onChange={handleOnChangeFloat}
            disabled
          />
        </div>
        <div className="w-3/12">
          <InputBox
            step={0.1}
            id={"unitCost"}
            name={"unitCost"}
            value={inputComponent.unitCost}
            children={"Unit Cost"}
            placeholder="Price"
            className="border-2 border-gray-200 bg-slate-200 rounded p-1 mt-2"
            type={"number"}
            onChange={handleOnChangeFloat}
            disabled
          />
        </div>
      </div>
      <h5 className="text-lg mt-5 -mb-6 border-b border-gray-200">Sub Item</h5>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1500 }}
        className="mt-12"
        size="small"
        pagination={false}
      />
    </Modal>
  );
}
