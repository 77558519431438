import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import Tab from "../../components/Tab";
import { PlusIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import AllUsers from "./AllUsers";
import CreateUser from "./CreateUser";

export default function UserManagement() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Layouts>
      <div className="flex  items-center bg-[#F9F9FB] -mr-16 -ml-5 xl:-mt-8">
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"All Users"}
          iconTab={<UserGroupIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 1 && "bg-gray-200"}
          onClick={() => activeTab !== 1 && setActiveTab(1)}
        />
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"Create"}
          iconTab={<PlusIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 2 && "bg-gray-200"}
          onClick={() => {
            activeTab !== 2 && setActiveTab(2);
          }}
        />
      </div>
      {activeTab === 1 && <AllUsers />}
      {activeTab === 2 && <CreateUser />}
    </Layouts>
  );
}
