import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const authState = useSelector((state) => state.user.user);

  if (authState?.data?.data?.token === undefined)
    return <Navigate to={"/sign-in"} />;
  return children ? children : <Outlet />;
}

export function PrivateRoute({ children, redirectPath = "/" }) {
  const authState = useSelector((state) => state.user.user);

  if (authState?.data?.data?.token !== undefined)
    return <Navigate to={redirectPath} />;

  return children ? children : <Outlet />;
}

export function AdminRoute({ children }) {
  const authState = useSelector((state) => state?.user?.user?.data?.data);

  if (authState?.roles[0] !== "ADMIN") return <Navigate to={-1} />;

  return children ? children : <Outlet />;
}

// export function OnlyAdminAndBuyerRoute({ children }) {
//   const authState = useSelector((state) => state?.user?.user?.data?.data);

//   if (
//     authState?.roles[0] === "ROLE_ADMIN" ||
//     authState?.roles[0] === "ROLE_BUYER"
//   ) {
//     return children ? children : <Outlet />;
//   } else {
//     return <Navigate to={-1} />;
//   }
// }

export function RouteWithoutBuyer({ children }) {
  const authState = useSelector((state) => state?.user?.user?.data?.data);

  if (
    authState?.roles[0] === "ADMIN" ||
    authState?.roles[0] === "PROJECT_MANAGER" ||
    authState?.roles[0] === "DESIGN_ENGINEER"
  ) {
    return children ? children : <Outlet />;
  } else {
    return <Navigate to={-1} />;
  }
}
