import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import { ShoppingBagIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import Tab from "../../components/Tab";
import InventoryTab from "./InventoryTab/InventoryTab";
import PurchasingTab from "./PurchasingTab/PurchasingTab";

export default function Inventory() {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Layouts>
      <div className="flex  items-center bg-[#F9F9FB] -mr-16 -ml-5 xl:-mt-8">
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"Inventories"}
          iconTab={<Squares2X2Icon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 1 && "bg-gray-200"}
          onClick={() => activeTab !== 1 && setActiveTab(1)}
        />
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"Purchasing"}
          iconTab={<ShoppingBagIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 2 && "bg-gray-200"}
          onClick={() => {
            activeTab !== 2 && setActiveTab(2);
          }}
        />
      </div>
      {activeTab === 1 && <InventoryTab />}
      {activeTab === 2 && <PurchasingTab />}
    </Layouts>
  );
}
