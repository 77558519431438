import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  data: [],
  updatedSuccess: false,
};

const updateDataSlice = createSlice({
  name: "refreshData",
  initialState,
  reducers: {
    updateData: (state, action) => {
      state.data = action.payload;
      state.updatedSuccess = true;
    },
    clearStateUpdate: (state, action) => {
      return initialState;
    },
  },
});

export const { updateData, clearStateUpdate } = updateDataSlice.actions;
export default updateDataSlice.reducer;
