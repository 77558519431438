import axios from "axios";
import { config } from "../hosts";

export const getProjects = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-project`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const createProjects = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/create-project`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const createBOM = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/create-bom`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const getBOM = (token, projectId) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-bom/${projectId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const updateProject = (token, projectId, body) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/update-project/${projectId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const updateBOM = (token, bomId, body) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/update-bom/${bomId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const draftBOM = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/check-stock`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const submitDraftBOMRequest = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/submit-bom`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const deleteProject = (token, projectId) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/delete-project/${projectId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const deleteBOM = (token, bomId) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/delete-bom/${bomId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
