import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import imgProject from "../../assets/images/img-project.png";
import {
  PencilSquareIcon,
  InformationCircleIcon,
  DocumentTextIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import Tab from "../../components/Tab";
import VendorInfo from "./VendorInfo";
import { Modal, message, notification } from "antd";
import InputBox from "../../components/Input/Input";
import { updateVendor } from "../../utils/https/Vendors";
import { useDispatch, useSelector } from "react-redux";
import DetailInfo from "../../components/DetailInfo";
import { clearStateUpdate } from "../../redux/updateData/updateDataSlice";
import Button from "../../components/Button/Button";
import { logout } from "../../redux/auth/authSlice";

export default function VendorDetails() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state } = useLocation();
  const [address, setAddress] = useState(state.item.address);
  const addressSplit = address.split(",");
  const [dataDisplay, setDataDisplay] = useState({
    name: state.item.name,
    vendorCode: state.item.vendorCode,
    addressLine: addressSplit[0],
    city: addressSplit[1],
    country: addressSplit[2],
    postalCode: addressSplit[3],
    currency: state.item.currency,
    paymentTerms: state.item.paymentTerms,
  });
  const [input, setInput] = useState({
    name: state.item.name,
    vendorCode: state.item.vendorCode,
    addressLine: addressSplit[0],
    city: addressSplit[1],
    country: addressSplit[2],
    postalCode: addressSplit[3],
    currency: state.item.currency,
    paymentTerms: state.item.paymentTerms,
  });

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const paymentOptions = [
    {
      id: 1,
      value: "Payment in Advance",
    },
    {
      id: 2,
      value: "Cash in Advance",
    },
    {
      id: 3,
      value: "Cash on Delivery",
    },
    {
      id: 4,
      value: "Net d Days",
    },
    {
      id: 5,
      value: "Net end of Month",
    },
  ];

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Success",
      description: "Vendor updated successfully.",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setLoading(true);
    updateVendor(authState?.token, input, state?.item?.id)
      .then(({ data }) => {
        openNotificationWithIcon();
        setIsModalOpen(false);
        setAddress(data.data.address);
        let newAddress = data.data.address.split(",");
        setDataDisplay({
          name: data.data.name,
          vendorCode: data.data.vendorCode,
          addressLine: newAddress[0],
          city: newAddress[1],
          country: newAddress[2],
          postalCode: newAddress[3],
          currency: data.data.currency,
          paymentTerms: data.data.paymentTerms,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  return (
    <Layouts>
      {contextHolder}
      <div className="flex items-center ">
        <div className="w-11/12 flex items-center space-x-2">
          <DetailInfo
            img={imgProject}
            name={input.name}
            description={input.vendorCode}
            // showModal={showModal}
          />
        </div>
        <Button
          children={"Edit vendor details"}
          restClassName="w-40"
          onClick={() => {
            setIsModalOpen(true);
            dispatch(clearStateUpdate());
          }}
        />
      </div>
      <div className="flex space-x-4">
        <div className="w-60 mt-8 ">
          <Tab
            titleTab={"Vendor Info"}
            iconTab={<InformationCircleIcon className="h-5 w-5" />}
            onClick={() => activeTab !== 1 && setActiveTab(1)}
            activeTab={activeTab === 1 && "bg-gray-200"}
            optionStyle={"justify-between flex-row-reverse"}
          />
          <Tab
            titleTab={"Image & Files"}
            iconTab={<DocumentTextIcon className="h-5 w-5" />}
            onClick={() => activeTab !== 2 && setActiveTab(2)}
            activeTab={activeTab === 2 && "bg-gray-200"}
            optionStyle={"justify-between flex-row-reverse"}
          />
          <Tab
            titleTab={"Other Action"}
            iconTab={<PencilIcon className="h-5 w-5" />}
            onClick={() => activeTab !== 3 && setActiveTab(3)}
            activeTab={activeTab === 3 && "bg-gray-200"}
            optionStyle={"justify-between flex-row-reverse"}
          />
        </div>
        <div className="w-full mt-8">
          {activeTab === 1 && <VendorInfo vendorDataInfo={dataDisplay} />}
        </div>
      </div>
      <Modal
        title="Edit Vendor"
        open={isModalOpen}
        okText={"Save"}
        onOk={handleOk}
        onCancel={handleCancel}
        width="800px"
        className="font-Inter"
      >
        <div className="flex space-x-2">
          <div className="w-6/12 space-y-2">
            <InputBox
              id={"name"}
              name="name"
              type={"text"}
              children={"Name"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.name}
            />
            <div>
              <label htmlFor="paymentTerms" className="font-semibold ">
                Payment Terms
              </label>
              <select
                id="paymentTerms"
                defaultValue={input.paymentTerms}
                className="border-2 border-gray-200 rounded p-1 mt-2"
                onChange={(e) =>
                  setInput({ ...input, paymentTerms: e.target.value })
                }
              >
                {paymentOptions.map((item, idx) => (
                  <option value={item.value} key={idx}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>
            <InputBox
              id={"country"}
              name="country"
              type={"text"}
              children={"Country"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.country}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-6/12 space-y-2">
            <InputBox
              id={"vendorCode"}
              name="vendorCode"
              type={"text"}
              children={"Vendor Code"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.vendorCode}
            />
            <InputBox
              id={"addressLine"}
              name="addressLine"
              type={"text"}
              children={"Address Line"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.addressLine}
              onChange={handleOnChange}
            />
            <InputBox
              id={"postalCode"}
              name="postalCode"
              type={"text"}
              children={"Postal Code"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.postalCode}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-6/12 space-y-2">
            <div className="flex flex-col">
              <label htmlFor="currency" className="font-semibold ">
                Currency
              </label>
              <select
                id="currency"
                defaultValue={input.currency}
                className="border-2 border-gray-200 rounded p-1 mt-2"
                onChange={(e) =>
                  setInput({ ...input, currency: e.target.value })
                }
              >
                <option value="SGD">SGD</option>
                <option value="USD">USD</option>
              </select>
            </div>
            <InputBox
              id={"city"}
              name="city"
              type={"text"}
              children={"City"}
              className="border border-gray-200 rounded p-1 mt-2"
              value={input.city}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </Modal>
    </Layouts>
  );
}
