import React, { useState } from "react";
import { CalendarIcon, PlusIcon } from "@heroicons/react/24/solid";
import Layouts from "../../components/Layouts";
import AllCustomers from "./AllCustomers";
import CreateCustomers from "./CreateCustomers";

export default function Customers() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Layouts>
      <div className="w-full flex bg-[#F9F9FB] items-center justify-start  xl:-mt-8 lg:-ml-5 ml-9">
        <div
          className={`xl:w-2/12 flex items-center p-5 cursor-pointer ${
            activeTab === 1 && "bg-gray-200"
          }`}
          onClick={() => activeTab !== 1 && setActiveTab(1)}
        >
          <CalendarIcon className="h-6 w-6 mr-1" />
          All Customers
        </div>
        <div
          className={`xl:w-2/12 flex items-center p-5 cursor-pointer ${
            activeTab === 2 && "bg-gray-200"
          }`}
          onClick={() => activeTab !== 2 && setActiveTab(2)}
        >
          <PlusIcon className="h-6 w-6 mr-1" />
          Create
        </div>
      </div>
      {activeTab === 1 && <AllCustomers />}
      {activeTab === 2 && <CreateCustomers />}
    </Layouts>
  );
}
