import axios from "axios";
import { config } from "../hosts";

export const getReports = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-report`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const exportExcel = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/export-report-excel`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const getReportDetails = (token, projectId) => {
  return axios.get(
    `${config.url}mrp-test-service/api/general/get-report-detail/${projectId}`,

    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
