import React from "react";
import Container from "../../components/Container";
import avatarSignIn from "../../assets/images/avatar-signin.svg";

export default function LayoutAuth({ children }) {
  return (
    <div
      className="bg-[#E5E5E5] font-Inter -mb-4 overflow-hidden"
      style={{ minHeight: "99vh" }}
    >
      <Container>
        <div className="flex items-center ">
          <div className="w-6/12 mt-20">{children}</div>
          <div className="w-6/12 ">
            <div className="  absolute  bottom-0 right-0">
              <img
                src={avatarSignIn}
                alt="avatar-signin"
                style={{ maxHeight: "90vh" }}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
