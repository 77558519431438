import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import { message } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import InputBox from "../../components/Input/Input";
import WrapperForm from "../../components/WrapperForm";
import { createCustomer } from "../../utils/https/Customers";
// import countries from "../../utils/JSON/countries.json";
import dialPhone from "../../utils/JSON/dial_phone.json";
import { logout } from "../../redux/auth/authSlice";

export default function CreateCustomers() {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  // const [isFocus, setIsFocus] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  const [otherContacts, setOtherContacts] = useState([
    { contactName: "", contactPhoneNumber: "" },
  ]);
  // const inputRef = useRef();
  const [input, setInput] = useState({
    name: "",
    customerCode: "",
    addressLine: "",
    city: "",
    country: "",
    postalCode: "",
    currency: "SGD",
    // countryNo: "+65",
    phoneNumber: "",
    customerContact: "",
    paymentTerms: "",
  });

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleOtherContacts = () => {
    setOtherContacts([
      ...otherContacts,
      { contactName: "", contactPhoneNumber: "" },
    ]);
  };

  const handleOnChangeOtherContacts = (e, index) => {
    const { name, value } = e.target;
    const list = [...otherContacts];
    list[index][name] = value;
    setOtherContacts(list);
  };

  const handleOtherContactRemove = (index) => {
    const list = [...otherContacts];
    list.splice(index, 1);
    setOtherContacts(list);
  };

  const paymentOptions = [
    {
      id: 1,
      value: "Payment in Advance",
    },
    {
      id: 2,
      value: "Cash in Advance",
    },
    {
      id: 3,
      value: "Cash on Delivery",
    },
    {
      id: 4,
      value: "Net d Days",
    },
    {
      id: 5,
      value: "Net end of Month",
    },
  ];

  const onCreate = () => {
    if (input.name === "") {
      return message.error("Please enter Customer's name");
    }
    // if (input.customerCode === "") {
    //   return message.error("Please enter Customer's Code");
    // }
    if (input.paymentTerms === "" || input.paymentTerms === "0") {
      return message.error("Please select payment terms");
    }
    if (input.addressLine === "") {
      return message.error("Please enter Address Line");
    }
    if (input.city === "") {
      return message.error("Please enter City");
    }
    if (input.country === "") {
      return message.error("Please enter Country");
    }
    // if (input.customerContact === "") {
    //   return message.error("Please enter contact name");
    // }
    if (input.country === "") {
      return message.error("Please enter dial phone");
    }
    // if (input.phoneNo === "") {
    //   return message.error("Please enter phone number");
    // }
    const inputBody =
      otherContacts[0].contactName !== ""
        ? { ...input, otherContacts: [...otherContacts] }
        : input;
    setLoading(true);
    createCustomer(authState?.token, inputBody)
      .then((res) => {
        setLoading(false);
        setInput({
          name: "",
          customerCode: "",
          addressLine: "",
          city: "",
          country: "",
          postalCode: "",
          currency: "SGD",
          // countryNo: "+65",
          phoneNumber: "",
          customerContact: "",
          paymentTerms: "",
        });
        setOtherContacts([{ contactName: "", contactPhoneNumber: "" }]);
        message.success("Customer created successfully");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  return (
    <WrapperForm title={"Create new customer"}>
      {contextHolder}
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"name"}
            name={"name"}
            value={input.name}
            children={"Customer's Name"}
            placeholder="Enter Customer's Name "
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-6/12 pt-8 px-4">
          <InputBox
            id={"customerCode"}
            name={"customerCode"}
            value={input.customerCode}
            children={"Customer Code"}
            placeholder="Enter Customer Code"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-6/12 pt-8 px-4 flex flex-col">
          <label htmlFor="paymentTerms" className="font-semibold ">
            Payment Terms
          </label>
          <select
            id="paymentTerms"
            defaultValue="0"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) =>
              setInput({ ...input, paymentTerms: e.target.value })
            }
          >
            <option value="0" defaultValue={true} key="ieiwesdj">
              Select payment terms
            </option>
            {paymentOptions.map((item, idx) => (
              <option value={item.value} key={idx}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
        <div className="w-6/12 pt-8 px-4 flex flex-col">
          <label htmlFor="currency" className="font-semibold ">
            Currency
          </label>
          <select
            id="currency"
            defaultValue="SGD"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={(e) => setInput({ ...input, currency: e.target.value })}
          >
            <option value="SGD" defaultValue={true}>
              SGD
            </option>
            <option value="USD">USD</option>
          </select>
        </div>
      </div>
      <h5 className="font-medium text-lg px-4 pt-8">Customer Address</h5>
      <div className="flex items-center px-4 space-x-2">
        <div className="w-3/12 ">
          <InputBox
            id={"addressLine"}
            name={"addressLine"}
            value={input.addressLine}
            children={"Country of Origin"}
            placeholder="Country of Origin (optional)"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 ">
          <InputBox
            id={"city"}
            name={"city"}
            value={input.city}
            children={"City"}
            placeholder="City (optional)"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 ">
          <InputBox
            id={"country"}
            name={"country"}
            value={input.country}
            children={"Country"}
            placeholder="Country (optionl)"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div className="w-3/12 ">
          <InputBox
            id={"postalCode"}
            name={"postalCode"}
            value={input.postalCode}
            children={"Postal Code"}
            placeholder="Postal Code"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
      </div>
      <h5 className="font-medium text-lg px-4 pt-8">Primary Contact</h5>
      <div className="flex items-center px-4 space-x-6">
        <div className="w-6/12  ">
          <InputBox
            id={"customerContact"}
            name={"customerContact"}
            value={input.customerContact}
            children={"Contact name"}
            placeholder="Enter Contact name"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            onChange={handleOnChange}
          />
        </div>
        <div>
          <p className="font-semibold">Phone number</p>
          <div className="flex -mt-1 ">
            {/* <div className="relative ">
              <input
                id={"countryNo"}
                name={"countryNo"}
                placeholder="+65"
                className="border-2 border-gray-200 rounded p-3  mr-1 w-20 justify-center"
                onFocus={() => {
                  setIsFocus(true);
                }}
                onBlur={() => {
                  if (!isHovered) {
                    setIsFocus(false);
                  }
                }}
                value={input.countryNo}
                onChange={handleOnChange}
                ref={inputRef}
              />
              {isFocus && (
                <div
                  className="shadow-lg  absolute w-full"
                  onMouseEnter={() => {
                    setIsHovered(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false);
                  }}
                >
                  {dialPhone.map((suggestion, idx) => {
                    const isMatch =
                      suggestion.dial_code
                        .toLowerCase()
                        .indexOf(input.countryNo.toLowerCase()) > -1;
                    return (
                      <div key={idx}>
                        {input.countryNo !== "" && isMatch && (
                          <div
                            className="p-3 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              setInput({
                                ...input,
                                countryNo: suggestion.dial_code,
                              });
                              inputRef.current.focus();
                            }}
                          >
                            {suggestion.dial_code}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div> */}
            <InputBox
              id={"phoneNumber"}
              name={"phoneNumber"}
              type="number"
              value={input.phoneNumber}
              placeholder="Enter phone number"
              className="border-2 border-gray-200 rounded p-3 "
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>
      <h5 className="font-medium text-lg px-4 pt-8">
        Secondary contacts (optional)
      </h5>
      {otherContacts?.map((singleContact, index) => {
        return (
          <div
            className="flex items-center px-4 space-x-6 space-y-2"
            key={index}
          >
            <div className="w-6/12  ">
              <InputBox
                id={"contactName"}
                name={"contactName"}
                value={singleContact.contactName}
                children={"Contact name"}
                placeholder="Enter Contact name"
                className="border-2 border-gray-200 rounded p-3 mt-2"
                onChange={(e) => handleOnChangeOtherContacts(e, index)}
              />
            </div>
            <div>
              <p className="font-semibold">Phone number</p>
              <div className="flex -mt-1 ">
                {/* <div className="relative ">
            <input
              id={"countryNo"}
              name={"countryNo"}
              placeholder="+65"
              className="border-2 border-gray-200 rounded p-3  mr-1 w-20 justify-center"
              onFocus={() => {
                setIsFocus(true);
              }}
              onBlur={() => {
                if (!isHovered) {
                  setIsFocus(false);
                }
              }}
              value={singleContact.countryNo}
              onChange={handleOnChange}
              ref={inputRef}
            />
            {isFocus && (
              <div
                className="shadow-lg  absolute w-full"
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                }}
              >
                {dialPhone.map((suggestion, idx) => {
                  const isMatch =
                    suggestion.dial_code
                      .toLowerCase()
                      .indexOf(input.countryNo.toLowerCase()) > -1;
                  return (
                    <div key={idx}>
                      {input.countryNo !== "" && isMatch && (
                        <div
                          className="p-3 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setInput({
                              ...input,
                              countryNo: suggestion.dial_code,
                            });
                            inputRef.current.focus();
                          }}
                        >
                          {suggestion.dial_code}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div> */}
                <InputBox
                  id={"contactPhoneNumber"}
                  name={"contactPhoneNumber"}
                  type="number"
                  value={singleContact.contactPhoneNumber}
                  placeholder="Enter phone number"
                  className="border-2 border-gray-200 rounded p-3 "
                  onChange={(e) => handleOnChangeOtherContacts(e, index)}
                />
                {otherContacts.length > 1 && (
                  <TrashIcon
                    className="h-5 w-5 mt-4 cursor-pointer"
                    color="#F20000"
                    onClick={() => handleOtherContactRemove(index)}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
      {otherContacts.length < 2 && (
        <div
          className="flex items-center  w-32 cursor-pointer ml-4"
          onClick={handleOtherContacts}
        >
          <PlusCircleIcon className="h-6 w-6 mr-2" color="#002E8C" />
          <p className="text-primary-blue mt-3">Add more</p>
        </div>
      )}
      <div className="flex justify-end my-7 mx-4">
        <Button buttonStyle={"btn--secondary"} restClassName="w-16 mr-3">
          Cancel
        </Button>
        <Button
          onClick={onCreate}
          loading={loading}
          restClassName="w-16"
          spinStyle={{ marginTop: 10 }}
        >
          Create
        </Button>
      </div>
    </WrapperForm>
  );
}
