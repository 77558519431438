import axios from "axios";
import { config } from "../hosts";

export const resetPassword = (body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/auth/reset-password`,
    body
    // {
    //   headers: {
    //     Authorization: `Bearer ` + token,
    //   },
    // }
  );
};
