import {
  DocumentTextIcon,
  EllipsisVerticalIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { Pagination, Popover, message } from "antd";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { clearStateUpdate } from "../../../redux/updateData/updateDataSlice";
import { getInventory } from "../../../utils/https/Inventory";
import AddFilter from "../Filter/AddFilter";
import IncreaseStock from "../IncreaseStock";
import { logout } from "../../../redux/auth/authSlice";
import Sort from "../../../components/Sort";

export default function InventoryTab() {
  const state = useSelector((state) => state);
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  // const dataPage = state.user.getDataInventoryByFilter;
  const dataFilter = state.user.getDataInventoryByFilter.data;
  const [dataIncrease, setDataIncrease] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortField, setSortField] = useState("");
  const [loading, setLoading] = useState(false);

  const sortBy = [
    { title: "Name", value: "name" },
    { title: "Description", value: "description" },
    { title: "Manufacturer", value: "manufacturer" },
    { title: "MPN", value: "manufacturerPartNumber" },
  ];
  // console.log(dataPage);

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  useEffect(() => {
    fetchInventory(currentPage);
  }, [
    authState?.token,
    state.user.updateData.updatedSuccess,
    currentPage,
    sortField,
  ]);

  const fetchInventory = (page) => {
    setLoading(true);
    const body = {
      pageNo: page,
      totalElement: 10,
      order: "descending",
    };
    const bodyFilter =
      sortField !== "" ? Object.assign(body, { sortField: sortField }) : body;
    getInventory(authState?.token, bodyFilter)
      .then(({ data }) => {
        setInventoryData(data.dataListingResponse);
        setTotalPages(data.totalPage);
        // console.log(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  const handleOpenChange = (index, newOpen) => {
    setOpen(newOpen);
  };

  const handleShowModal = () => {
    setShowModal(true);
    dispatch(clearStateUpdate());
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.name,
      // sortable: true,
      wrap: true,
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      // sortable: true,
      wrap: true,
    },
    {
      name: "MANUFACTURER",
      selector: (row) => row.manufacturer,
      // sortable: true,
      wrap: true,
    },
    {
      name: "MANUFACTURER NUMBER",
      selector: (row) => {
        return row.manufacturerPartNumber;
      },
      // sortable: true,
      wrap: true,
    },
    {
      name: "AVAILABLE STOCK",
      selector: (row) => row.stock,
      // sortable: true,
      wrap: true,
    },
    {
      name: "",
      selector: (row, index) => {
        return (
          <Popover
            placement="bottom"
            trigger={"click"}
            open={showModal ? false : open}
            onOpenChange={handleOpenChange}
            content={
              <div className="text-secondary-blue ">
                <div
                  className="flex cursor-pointer hover:bg-gray-200 p-2"
                  onClick={() => {
                    handleShowModal();
                    setDataIncrease(row);
                  }}
                >
                  <PlusCircleIcon className="h-5 w-5 mr-2" color="#677AA3" />
                  Adjust stock
                </div>
                <div className="flex cursor-pointer mt-2 hover:bg-gray-200 p-2">
                  <DocumentTextIcon className="h-5 w-5 mr-2" color="#677AA3" />
                  See details
                </div>
              </div>
            }
          >
            <EllipsisVerticalIcon
              className="h-5 w-5 cursor-pointer"
              color="#677AA3"
              // onClick={() => handleDetail(row.id, row)}
            />
          </Popover>
        );
      },
      // sortable: true,
      width: "70px",
      wrap: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#E6E9F0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const onChangePage = (pagination) => {
    setCurrentPage(pagination);
  };

  // console.log(dataFilter);

  return (
    <>
      {contextHolder}
      <div className="flex  justify-between mt-6">
        <div className="w-64">
          <AddFilter pageNoCount={currentPage} />
        </div>
        <div>
          <Sort
            handleChange={(e) => setSortField(e.target.value)}
            dataSort={sortBy}
          />
        </div>
      </div>
      <DataTable
        className="mt-5"
        columns={columns}
        data={dataFilter?.length !== undefined ? dataFilter : inventoryData}
        defaultSortFieldId={1}
        customStyles={customStyles}
        progressPending={loading}
        // selectableRows
      />
      <div className="flex justify-end my-2">
        <Pagination
          defaultCurrent={1}
          total={totalPages * 10}
          onChange={onChangePage}
          showSizeChanger={false}
        />
      </div>
      <IncreaseStock
        showModal={showModal}
        handleClose={handleCloseModal}
        item={dataIncrease}
      />
    </>
  );
}
