import React from "react";

export default function TextDataInfo({ children, data }) {
  return (
    <p>
      <span className="font-bold text-secondary-blue">{children}</span>
      <br />
      {data}
    </p>
  );
}
