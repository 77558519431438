import React, { useEffect } from "react";
import Container from "./Container";
import { PlusCircleIcon, BellAlertIcon } from "@heroicons/react/24/solid";
import profileIcon from "../assets/images/profile-icon.png";
import mrpLogo from "../assets/images/mrp-logo.png";
import { useDispatch } from "react-redux";
import { logout } from "../redux/auth/authSlice";
import { Dropdown, Menu } from "antd";
import { clearInventoryFilter } from "../redux/filterInventory/filterInventorySlice";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function Header() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearInventoryFilter());
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <Link to={"/profile"}>Profile</Link>,
        },
        {
          key: "2",
          label: <button onClick={handleLogout}>Logout</button>,
        },
      ]}
    />
  );
  return (
    <header
      className="font-Inter sticky top-0 bg-[#FFFFFF] shadow-sm shadow-slate-300 py-4 pr-6"
      style={{ zIndex: 5 }}
    >
      <div className="grid grid-cols-10">
        <div className="col-start-1 col-end-2 ml-5">
          <a href="/">
            <img
              src={mrpLogo}
              style={{ maxWidth: 100 }}
              className="lg:-ml-1 ml-1"
              alt="mrp-ic"
            />
          </a>
        </div>
        <div className="col-end-12 col-span-2">
          <ul className="space-x-5  flex justify-end items-center flex-row space-y-0">
            <li>
              <PlusCircleIcon className="h-6 w-6" color="#002E8C" />
            </li>
            <li>
              <BellAlertIcon className="h-6 w-6" color="#677AA3" />
            </li>
            <Dropdown overlay={menu}>
              <button onClick={(e) => e.preventDefault()}>
                <UserCircleIcon className="h-6 w-6" color="#002E8C" />
              </button>
            </Dropdown>
          </ul>
        </div>
      </div>
    </header>
  );
}
