import React, { useEffect, useState } from "react";
import Layouts from "../../components/Layouts";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { exportExcel, getReportDetails } from "../../utils/https/Reports";
import Button from "../../components/Button/Button";
import { PrinterIcon } from "@heroicons/react/24/outline";
import { Table, message } from "antd";
import { logout } from "../../redux/auth/authSlice";

export default function ReportDetails() {
  const { state } = useLocation();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    getReportDetails(authState?.token, state?.row?.id)
      .then(({ data }) => {
        setDatas(data);
      })
      .catch((err) => console.log(err));
  }, [authState?.token]);

  const columns = [
    {
      title: "COMMENT",
      //   width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "DESCRIPTION",
      //   width: 100,
      dataIndex: "description",
      key: "description",
      //   sorter: true,
    },
    {
      title: "MANUFACTURER",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "MPN",
      dataIndex: "manufacturerPartNumber",
      key: "manufacturerPartNumber",
    },
    {
      title: "PRODUCT CATEGORY",
      dataIndex: "productCategory",
      key: "productCategory",
    },
    {
      title: "PACKAGE",
      dataIndex: "packageSize",
      key: "packageSize",
    },

    {
      title: "RATING",
      dataIndex: "powerRating",
      key: "powerRating",
    },

    {
      title: "TOL",
      dataIndex: "tolerance",
      key: "tolerance",
    },
    {
      title: "TEMP",
      dataIndex: "temperature",
      key: "temperature",
    },
    {
      title: "UNIT COST",
      dataIndex: "temperature",
      key: "temperature",
    },
    {
      title: "VENDOR",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "QTY PER BOARD",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "QTY TO PURCHASE",
      dataIndex: "purchaseQuantity",
      key: "purchaseQuantity",
    },
    {
      title: "TOTAL COST",
      dataIndex: "purchaseCost",
      key: "purchaseCost",
    },
    {
      title: "PURCHASE STATUS",
      dataIndex: "purchaseStatus",
      key: "purchaseStatus",
    },
    {
      title: "PURCHASE DELIVERY DATE",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
    },
    {
      title: "DESIGNATOR",
      dataIndex: "designator",
      key: "designator",
    },
  ];

  function expiredToken() {
    messageApi.open({
      type: "error",
      content: "Your login was expired. You will redirect to sign in page.",
      duration: 2,
    });
    setTimeout(() => {
      dispatch(logout());
    }, 3000);
  }

  const handleExport = () => {
    const body = {
      projectId: state?.row?.id,
    };
    exportExcel(authState?.token, body)
      .then(({ data }) => {
        downloadXlsx(data.data, "report");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          expiredToken();
        }
      });
  };

  function downloadXlsx(data, filename) {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
    const downloadLink = document.createElement("a");
    const fileName = filename + ".xlsx";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <Layouts>
      {contextHolder}
      <div className="flex  w-full">
        <div className="w-8/12 space-x-2">
          <Link to="/reports">
            Report list <span className="text-primary-blue">/</span>
          </Link>
          <a className="text-primary-black hover:text-primary-black cursor-text">
            Report Detals <span className="text-primary-black"></span>
          </a>
        </div>
      </div>
      <div className="mt-8 flex items-center justify-between">
        <div>
          <h5 className="font-semibold text-2xl">Bill of Material</h5>
          <p>
            Source from :
            <span className="font-semibold"> {state.row.name}</span>
          </p>
          <p>
            Creation date :
            <span className="font-semibold">
              {" "}
              {state.row.createdDate.replace(".0", "")}
            </span>
          </p>
        </div>
        <Button
          children={
            <div className="flex items-center justify-center">
              <PrinterIcon className="h-5 w-5 mr-1" />
              Export as xlsx
            </div>
          }
          restClassName={"w-40 h-10"}
          onClick={handleExport}
        />
      </div>
      <Table
        columns={columns}
        dataSource={datas}
        scroll={{
          x: 3000,
        }}
      />
    </Layouts>
  );
}
