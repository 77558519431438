import axios from "axios";
import { config } from "../hosts";

export const getPurchasing = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-purchase`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const createPurchase = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/create-purchase`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const confirmPurchasing = (token, body) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/confirm-purchase`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const closePurchasing = (token, body) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/close-purchase`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};

export const getPurchaseListDetails = (token, purchaseId) => {
  return axios.get(
    `${config.url}mrp-test-service/api/general/get-purchase/${purchaseId}`,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
