import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../../utils/hosts";

let initialState = {
  data: {},
  // isLogin: false,
  isLoading: false,
  error: "",
  token: "",
};

export const loginUser = createAsyncThunk("user", async (body, loading) => {
  let res = await fetch(`${config.url}mrp-test-service/api/auth/signin`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return await res.json();
});

// export const logoutUser = () => {

// }

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = action.payload;
    },
    addUser: (state, action) => {
      state.data = action.payload;
    },
    clearState: (state) => {
      return initialState;
    },
    logout: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state, action) => {
      state.isLoading = true;
      // state.isLogin = false;
      state.data = {};
    },
    [loginUser.fulfilled]: (state, { payload: data }) => {
      state.isLoading = false;
      state.token = data.token;
      state.data = data;
      // state.isLogin = true;
      state.error = "";
    },
    [loginUser.rejected]: (state, { payload: data }) => {
      state.isLoading = false;
      // state.isLogin = false;
      state.error = data;
      state.data = {};
    },
  },
});

export const { addToken, addUser, clearState, logout } = authSlice.actions;
export default authSlice.reducer;
