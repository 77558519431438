import React from "react";
// import { PencilSquareIcon } from "@heroicons/react/24/outline";

export default function DetailInfo({ img, name, description, showModal }) {
  return (
    <>
      <img src={img} alt="" />
      <div className="flex flex-col ">
        <div className="flex space-x-2">
          <h5 className="font-bold text-2xl">{name}</h5>
          {/* <PencilSquareIcon className="h-6 w-6 mt-1 text-secondary-blue cursor-pointer" /> */}
        </div>
        <div className="flex space-x-2">
          <p>{description}</p>
          {/* <PencilSquareIcon
            className="h-6 w-6  text-secondary-blue cursor-pointer"
            onClick={showModal}
          /> */}
        </div>
      </div>
    </>
  );
}
