import React from "react";
import "antd/dist/antd.min.css";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import ProtectedRoute, {
  AdminRoute,
  // OnlyAdminAndBuyerRoute,
  PrivateRoute,
  RouteWithoutBuyer,
} from "./components/ProtectedRoute/ProtectedRoute";
import Sidebar from "./components/Sidebar";
import SignIn from "./pages/Auth/SignIn";
import ComponentDetails from "./pages/ComponentPage/ComponentDetails";
import Components from "./pages/ComponentPage/Components";
import CreateComponent from "./pages/ComponentPage/CreateComponent";
import CreateComponentTest from "./pages/ComponentPage/CreateComponentTest";
import CustomerDetails from "./pages/Customers/CustomerDetails";
import Customers from "./pages/Customers/Customers";
import Dashboard from "./pages/Dashboard/Dashboard";
import Inventory from "./pages/InventoryPage/Inventory";
import PurchasingDetails from "./pages/InventoryPage/PurchasingTab/PurchasingDetails";
import EditBoM from "./pages/Projects/EditBoM";
import ProjectDetails from "./pages/Projects/ProjectDetails";
import Projects from "./pages/Projects/Projects";
import Reports from "./pages/Reports/Reports";
import VendorDetails from "./pages/Vendor/VendorDetails";
import Vendors from "./pages/Vendor/Vendors";
import ReportDetails from "./pages/Reports/ReportDetails";
import UserManagement from "./pages/UserManagement/UserManagement";
import Profile from "./pages/Profile/Profile";
import UserDetails from "./pages/UserManagement/UserDetails";
import ForgetPassword from "./pages/Auth/ForgetPassword";

function App(props) {
  const token = useSelector((state) => state?.user?.user?.data?.data?.token);
  return (
    <BrowserRouter>
      {token !== undefined && <Header />}
      <div className={token !== undefined ? "flex flex-row" : ""}>
        {token !== undefined && <Sidebar />}
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/components" element={<Components />} />
            <Route
              path="/components/create"
              element={<CreateComponentTest />}
            />
            <Route
              path="/components/:id"
              element={<ComponentDetails {...props} />}
            />
            <Route path="/inventory" element={<Inventory />} />
            <Route
              path="/inventory/purchasing-request/:id"
              element={<PurchasingDetails />}
            />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customers/:id" element={<CustomerDetails />} />

            <Route element={<AdminRoute />}>
              <Route path="/users" element={<UserManagement />} />
              <Route path="/users/:id" element={<UserDetails />} />
              <Route path="/vendors" element={<Vendors />} />
              <Route
                path="/vendors/:id"
                element={<VendorDetails {...props} />}
              />
            </Route>
            <Route element={<RouteWithoutBuyer />}>
              <Route path="/projects" element={<Projects />} />
              <Route
                path="/projects/:id"
                element={<ProjectDetails {...props} />}
              />
              <Route
                path="/projects-bom/:id"
                element={<EditBoM {...props} />}
              />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reports/:id" element={<ReportDetails />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
