import React, { useEffect, useRef, useState } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { message, Modal } from "antd";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import InputBox from "../../components/Input/Input";
import {
  createSubComponent,
  getComponents,
  getSubComponents,
} from "../../utils/https/Components";
import {
  clearStateUpdate,
  updateData,
} from "../../redux/updateData/updateDataSlice";

export default function CreateAddSub({ item, currentData }) {
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [dataSUb, setDataSUb] = useState([]);
  const [dataDisplayParam, setDataDisplayParam] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [dataComponent, setDataComponent] = useState([]);
  const [isFocus, setIsFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [namePart, setNamePart] = useState("");

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "MPN",
      selector: (row) => row.manufacturerPartNumber,
      wrap: true,
    },
    {
      name: "MANUFACTURER",
      selector: (row) => row.manufacturer,
      wrap: true,
    },
    {
      name: "COMPONENT LEVEL",
      selector: (row) => row.componentLevel,
      wrap: true,
    },
    {
      name: "QUANTITY",
      selector: (row, index) => {
        return (
          <InputBox
            value={row.quantity}
            placeholder="Enter quantity"
            className="border-2 border-gray-200 rounded p-3 mt-2"
            type={"number"}
            onChange={(e) => handleChangeQuantity(e, index)}
          />
        );
      },
      wrap: true,
    },
    {
      name: "",
      selector: (row, index) => {
        return (
          <TrashIcon
            className="h-4 w-4 cursor-pointer"
            color="#677AA3"
            onClick={() => handleRemoveItemTable(index)}
          />
        );
      },
      width: "70px",
    },
  ];

  // console.log(currentData);

  const ExpandedComponent = ({ data }) => {
    // const inputRef = useRef();
    setDataDisplayParam(data.dataListingResponse);

    return (
      <div>
        {dataDisplay.map((data, idx) => {
          return (
            <div className="grid grid-cols-5 mx-16 py-3" key={idx}>
              <div>{data.componentName}</div>
              <div>{data.manufacturerPartNumber}</div>
              <div>{data.manufacturer}</div>
              <div>{data.componentLevel}</div>
              <div>{data.quantity}</div>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    getSubComponents(authState?.token, dataDisplayParam.id)
      .then(({ data }) => {
        setDataDisplay(data.dataListingResponse);
      })
      .catch((err) => console.log(err));
  }, [dataDisplayParam]);

  useEffect(() => {
    if (item.item.componentLevel === "TOP_LEVEL") {
      const body = {
        pageNo: 1,
        totalElement: 1000,
        componentLevel: ["SUB_ITEM", "RAW_PART", "MAIN_COMPONENT"],
      };
      getComponents(authState?.token, body)
        .then(({ data }) => {
          setDataComponent(data.dataListingResponse);
        })
        .catch((err) => console.log(err));
    } else if (item.item.componentLevel === "MAIN_COMPONENT") {
      const body = {
        pageNo: 1,
        totalElement: 1000,
        componentLevel: ["SUB_ITEM", "RAW_PART"],
      };
      getComponents(authState?.token, body)
        .then(({ data }) => {
          setDataComponent(data.dataListingResponse);
        })
        .catch((err) => console.log(err));
    } else {
      const body = {
        pageNo: 1,
        totalElement: 1000,
        componentLevel: ["RAW_PART"],
      };
      getComponents(authState?.token, body)
        .then(({ data }) => {
          setDataComponent(data.dataListingResponse);
        })
        .catch((err) => console.log(err));
    }
  }, [authState.token, item.item.componentLevel]);

  const handleChangeQuantity = (e, index) => {
    const tempArr = dataSUb.map((item, idx) =>
      idx === index ? { ...item, quantity: e.target.value } : item
    );
    setDataSUb([...tempArr]);
  };
  const handleRemoveItemTable = (index) => {
    const list = [...dataSUb];
    list.splice(index, 1);
    setDataSUb(list);
  };

  const getSubData = (name, manufacturerPartNumber) => {
    const body = {
      pageNo: 1,
      totalElement: 1,
      name: name,
      manufacturerPartNumber,
    };

    getComponents(authState?.token, body)
      .then(({ data }) => {
        let body = data.dataListingResponse.map((list) => {
          return {
            ...list,
            quantity: "0",
          };
        });
        console.log(body);
        setDataSUb([...dataSUb, ...body]);
      })
      .catch((err) => console.log(err));
  };

  const handleOk = () => {
    setLoading(true);
    let body = dataSUb.map((list) => {
      return {
        partComponentId: list.id,
        quantity: list.quantity,
        componentId: item.item.id,
      };
    });

    createSubComponent(authState?.token, body)
      .then(({ data }) => {
        setLoading(false);
        message.success(data.message);
        setIsShow(false);
        setDataSUb([]);
        dispatch(updateData());
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const isSameData = (currentData, dataComponent) =>
    currentData.componentId === dataComponent.id &&
    currentData.manufacturerPartNumber === dataComponent.manufacturerPartNumber;
  const onlyInLeft = (left, right, compareFunction) =>
    left?.filter(
      (leftValue) =>
        !right?.some((rightValue) => compareFunction(leftValue, rightValue))
    );

  const onlyInA = onlyInLeft(currentData, dataComponent, isSameData);
  const onlyInB = onlyInLeft(dataComponent, currentData, isSameData);
  const result1 = [...onlyInA, ...onlyInB];

  const isSameData2 = (result1, dataSUb) =>
    result1.id === dataSUb.id &&
    result1.manufacturerPartNumber === dataSUb.manufacturerPartNumber;
  const onlyInLeft2 = (left, right, compareFunction) =>
    left?.filter(
      (leftValue) =>
        !right?.some((rightValue) => compareFunction(leftValue, rightValue))
    );

  const onlyInA2 = onlyInLeft2(result1, dataSUb, isSameData2);
  const onlyInB2 = onlyInLeft2(dataSUb, result1, isSameData2);

  const result2 = [...onlyInA2, ...onlyInB2];

  // console.log(currentData);
  // console.log(dataComponent);

  return (
    <>
      <div className="flex justify-end my-7">
        <Button
          children={"+ Create sub part"}
          restClassName="w-36"
          onClick={() => {
            setIsShow(true);
            dispatch(clearStateUpdate());
          }}
        />
      </div>
      <Modal
        title={`${
          item.item.componentLevel === "TOP_LEVEL"
            ? "Add Main component / Sub items / Raw"
            : item.item?.componentLevel === "MAIN_COMPONENT"
            ? "Add Sub items / Raw"
            : "Add raw parts"
        }`}
        open={isShow}
        onCancel={() => {
          setIsShow(false);
          setDataSUb([]);
        }}
        footer={
          <div className="flex space-x-3 justify-end">
            <Button
              children={"Cancel"}
              restClassName="w-16"
              buttonStyle={"btn--secondary"}
              onClick={() => {
                setIsShow(false);
                setDataSUb([]);
              }}
            />
            <Button
              children={"Save"}
              loading={loading}
              spinStyle={{ marginTop: 10 }}
              restClassName="w-16"
              onClick={handleOk}
            />
          </div>
        }
        width="1000px"
        className="font-Inter"
      >
        <div className="w-full ">
          {dataSUb.length > 0 && (
            <DataTable
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              columns={columns}
              data={dataSUb}
              sortFunction={false}
            />
          )}
        </div>
        <input
          id={"namePart"}
          name={"namePart"}
          placeholder="Enter part number or name"
          className="border-2 border-gray-200 rounded p-3  my-5 "
          onFocus={() => {
            setIsFocus(true);
          }}
          onBlur={() => {
            if (!isHovered) {
              setIsFocus(false);
            }
          }}
          value={namePart}
          onChange={(e) => setNamePart(e.target.value)}
          ref={inputRef}
        />

        {isFocus && (
          <div
            className="shadow-lg  w-2/12 h-32 overflow-y-scroll"
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          >
            {result1 === result2
              ? result1.map((suggestion, idx) => {
                  const isMatchPartNum =
                    suggestion.manufacturerPartNumber
                      .toLowerCase()
                      .indexOf(namePart.toLowerCase()) > -1;
                  const isMatchName =
                    suggestion.name
                      .toLowerCase()
                      .indexOf(namePart.toLowerCase()) > -1;
                  return (
                    <div key={idx}>
                      {isMatchPartNum ? (
                        <div
                          className="p-3  hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            getSubData("", suggestion.manufacturerPartNumber);
                            setNamePart("");
                            inputRef.current.focus();
                          }}
                        >
                          {suggestion.manufacturerPartNumber}
                        </div>
                      ) : (
                        isMatchName && (
                          <div
                            className="p-3 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              getSubData(suggestion.name, "");
                              setNamePart("");
                              inputRef.current.focus();
                            }}
                          >
                            {suggestion.name}
                          </div>
                        )
                      )}
                    </div>
                  );
                })
              : result2.map((suggestion, idx) => {
                  const isMatchPartNum =
                    suggestion.manufacturerPartNumber
                      .toLowerCase()
                      .indexOf(namePart.toLowerCase()) > -1;
                  const isMatchName =
                    suggestion.name
                      .toLowerCase()
                      .indexOf(namePart.toLowerCase()) > -1;
                  return (
                    <div key={idx}>
                      {isMatchPartNum ? (
                        <div
                          className="p-3  hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            getSubData("", suggestion.manufacturerPartNumber);
                            setNamePart("");
                            inputRef.current.focus();
                          }}
                        >
                          {suggestion.manufacturerPartNumber}
                        </div>
                      ) : (
                        isMatchName && (
                          <div
                            className="p-3 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              getSubData(suggestion.name, "");
                              setNamePart("");
                              inputRef.current.focus();
                            }}
                          >
                            {suggestion.name}
                          </div>
                        )
                      )}
                    </div>
                  );
                })}
          </div>
        )}
      </Modal>
    </>
  );
}
