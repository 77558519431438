import React from "react";

export default function Layouts({ children }) {
  return (
    <div
      className="w-screen pl-48 xl:pt-8 xl:mr-16 mr-5 font-Inter overflow-hidden"
      style={{ minHeight: "105vh" }}
    >
      {children}
    </div>
  );
}
