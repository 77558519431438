import React, { useState } from "react";
import Layouts from "../../components/Layouts";
import Tab from "../../components/Tab";
import { LockClosedIcon, UserIcon } from "@heroicons/react/24/outline";
import TabAccountDetail from "./TabAccountDetail";
import TabChangePassword from "./TabChangePassword";

export default function Profile() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Layouts>
      <div className="flex  items-center bg-[#F9F9FB] -mr-16 -ml-5 xl:-mt-8">
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"Account Detail"}
          iconTab={<UserIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 1 && "bg-gray-200"}
          onClick={() => activeTab !== 1 && setActiveTab(1)}
        />
        <Tab
          optionStyle={"xl:w-2/12"}
          titleTab={"Security"}
          iconTab={<LockClosedIcon className="h-6 w-6 mr-2" />}
          activeTab={activeTab === 2 && "bg-gray-200"}
          onClick={() => {
            activeTab !== 2 && setActiveTab(2);
          }}
        />
      </div>
      {activeTab === 1 && <TabAccountDetail />}
      {activeTab === 2 && <TabChangePassword />}
    </Layouts>
  );
}
