import React, { useEffect, useRef, useState } from "react";
import { Modal, notification } from "antd";
import { createBOM } from "../../utils/https/Projects";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getComponents } from "../../utils/https/Components";
import InputBox from "../../components/Input/Input";
import {
  clearStateUpdate,
  updateData,
} from "../../redux/updateData/updateDataSlice";

export default function CreateBOM({
  handleCloseModalCreate,
  showModalCreate,
  item,
}) {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.user?.user?.data?.data);
  const [isFocus, setIsFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const inputRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataComponents, setDataComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [input, setInput] = useState({
    quantity: 0,
    designator: "",
    projectId: item.item.id,
    componentId: "",
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const body = {
      pageNo: 1,
      totalElement: 1000000,
    };
    getComponents(authState?.token, body)
      .then(({ data }) => {
        setDataComponents(data.dataListingResponse);
      })
      .catch((err) => console.log(err));
  }, [authState?.token]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Create Success!",
      description: "BOM successfully Created.",
    });
  };

  const handleOk = () => {
    setLoading(true);
    createBOM(authState?.token, input)
      .then(({ data }) => {
        setLoading(false);
        openNotificationWithIcon();
        setInput({
          quantity: 0,
          designator: "",
          projectId: item.item.id,
          componentId: "",
        });
        setComponentName("");
        dispatch(updateData(data.data));
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      <Button
        children={"+ Add Component to BOM"}
        onClick={() => {
          showModal();
          dispatch(clearStateUpdate());
        }}
        restClassName="w-48 h-10"
        buttonStyle={item.item.status === "SUBMITTED" && "btn--disabled"}
        disabled={item.item.status === "SUBMITTED" ? true : false}
      />
      <Modal
        title="Create BOM"
        open={isModalOpen}
        footer={
          <div className="flex space-x-3 justify-end">
            <Button
              children={"Cancel"}
              restClassName="w-16"
              buttonStyle={"btn--secondary"}
              onClick={handleCancel}
            />
            <Button
              children={"Save"}
              loading={loading}
              restClassName="w-16"
              onClick={handleOk}
            />
          </div>
        }
        width="800px"
        className="font-Inter"
        closable={false}
      >
        <div className="flex space-x-2">
          <div className="w-6/12 space-y-2">
            <div className="flex flex-col">
              <label htmlFor="componentLevel" className="font-semibold mb-2">
                Component name
              </label>
              <input
                id={"componentName"}
                name={"componentName"}
                placeholder="Enter component name"
                className="border border-gray-200 rounded p-1"
                onFocus={() => {
                  setIsFocus(true);
                }}
                onBlur={() => {
                  if (!isHovered) {
                    setIsFocus(false);
                  }
                }}
                value={componentName}
                onChange={(e) => setComponentName(e.target.value)}
                ref={inputRef}
              />
              {componentName !== "" && isFocus && (
                <div
                  className=" absolute mt-16 w-6/12 shadow-lg bg-[#FFFFFF] h-24 overflow-y-scroll "
                  onMouseEnter={() => {
                    setIsHovered(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false);
                  }}
                >
                  {dataComponents.map((suggestion, idx) => {
                    const isMatchName =
                      suggestion.name
                        .toLowerCase()
                        .indexOf(componentName.toLowerCase()) > -1;
                    return (
                      <div key={idx}>
                        {isMatchName && (
                          <div
                            className="p-3 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              setInput({
                                ...input,
                                componentId: suggestion.id,
                              });
                              setComponentName(suggestion.name);
                              inputRef.current.focus();
                            }}
                          >
                            {suggestion.name + ` (SGD ${suggestion.unitCost})`}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <InputBox
              id={"quantity"}
              name="quantity"
              type={"number"}
              children={"Quantity"}
              className="border border-gray-200 rounded p-1 mt-2"
              step="number"
              onChange={handleOnChange}
              value={input.quantity}
            />
          </div>
          <div className="w-6/12 space-y-2">
            <InputBox
              id={"designator"}
              name="designator"
              type={"text"}
              children={"Designator"}
              className="border border-gray-200 rounded p-1 mt-2"
              onChange={handleOnChange}
              value={input.designator}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
