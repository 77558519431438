import axios from "axios";
import { config } from "../hosts";

export const getComponents = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-component/`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const getSubComponents = (token, componentId) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/get-sub-component/${componentId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const createComponent = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/create-component/`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const uploadComponentExcel = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/process-import-component`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const createSubComponent = (token, body) => {
  return axios.post(
    `${config.url}mrp-test-service/api/general/create-sub-component/`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const deleteComponent = (token, componentId) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/delete-component/${componentId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
export const updateComponent = (token, componentId, body) => {
  return axios.patch(
    `${config.url}mrp-test-service/api/general/update-component/${componentId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ` + token,
      },
    }
  );
};
